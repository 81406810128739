/* src/History.js */
import {
  View,
  withAuthenticator,
  Table,
  TableBody,
  TableRow,
  TableCell,
  SelectField,
  Input,
  Button,
} from "@aws-amplify/ui-react";
import { MenuBar } from "./commonfn/MenuBar";
import "@aws-amplify/ui-react/styles.css";
import { API, graphqlOperation, I18n } from "aws-amplify";
import {
  createSetting,
  updateSetting,
  createPublicPersona,
  updatePublicPersona,
} from "./graphql/mutations";
import React, { useEffect, useState, useContext } from "react";
import { listSettings, listPublicPersonas } from "./graphql/queries";
import { LanguageContext } from "./commonfn/LanguageContext";
import { UILangCodes } from "./commonfn/CountryCodes";

const Settings = ({ signOut, user, changeLanguageCallback }) => {
  const nativeCode = useContext(LanguageContext);
  const [settings, setSettings] = useState(null);
  const [publicPersona, setPublicPersona] = useState(null);
  const [langCode, setLangCode] = useState("");
  const [screenName, setScreenName] = useState("");

  useEffect(() => {
    if (!settings) {
      setLangCode(nativeCode);
      fetchSettings();
      fetchPublicPersonas();
    }
  }, [settings]);

  async function fetchSettings() {
    try {
      const res = await API.graphql(graphqlOperation(listSettings, {}));
      console.log("res settings: " + JSON.stringify(res, null, 2));
      const settingsTemp = res.data.listSettings.items;
      if (settingsTemp && settingsTemp.length > 0) {
        setSettings(settingsTemp[0]);
        if (settingsTemp[0].nativeLangCode) {
          setLangCode(settingsTemp[0].nativeLangCode);
        }
      }
    } catch (err) {
      console.log("error fetching settings", err);
    }
  }

  async function fetchPublicPersonas() {
    try {
      const res = await API.graphql(graphqlOperation(listPublicPersonas, {}));
      console.log("res public personas: " + JSON.stringify(res, null, 2));
      const publicPersonasTemp = res.data.listPublicPersonas.items;
      if (publicPersonasTemp && publicPersonasTemp.length > 0) {
        setPublicPersona(publicPersonasTemp[0]);
        setScreenName(publicPersonasTemp[0].screenName);
      }
    } catch (err) {
      console.log("error fetching public personas", err);
    }
  }

  function getLangCodesJsx() {
    let options = [];
    for (let i = 0; i < UILangCodes.length; i++) {
      options.push(
        <option key={i} value={UILangCodes[i]}>
          {I18n.get(UILangCodes[i])}
        </option>
      );
    }
    return options;
  }

  async function changeLangCode(langCodeTemp) {
    changeLanguageCallback(langCodeTemp);
    if (!settings || settings.length === 0) {
      let result = await API.graphql(
        graphqlOperation(createSetting, {
          input: {
            nativeLangCode: langCodeTemp,
          },
        })
      );
    } else {
      let result = await API.graphql(
        graphqlOperation(updateSetting, {
          input: {
            id: settings.id,
            nativeLangCode: langCodeTemp,
          },
        })
      );
      setSettings(settings);
    }
  }

  async function changePublicPersona(screenName, langCodeTemp) {
    console.log(
      "changePublicPersona: " + JSON.stringify(publicPersona, null, 2)
    );
    if (!publicPersona) {
      let result = await API.graphql(
        graphqlOperation(createPublicPersona, {
          input: {
            ownerId: user.username,
            screenName: screenName,
            nativeLangCode: langCodeTemp,
          },
        })
      );
      console.log(
        "createPublicPersona result: " + JSON.stringify(result, null, 2)
      );
    } else {
      let result = await API.graphql(
        graphqlOperation(updatePublicPersona, {
          input: {
            id: publicPersona.id,
            screenName: screenName,
            nativeLangCode: langCodeTemp,
          },
        })
      );
      console.log(
        "updatePublicPersona result: " + JSON.stringify(result, null, 2)
      );
    }
    setScreenName(screenName);
  }

  async function saveSettings() {
    changeLangCode(langCode);
    I18n.setLanguage(langCode);
    changePublicPersona(screenName, langCode);
    //window.location.reload();
  }

  return (
    <>
      <MenuBar />
      <View style={styles.container} className="col-7 col-s-12">
        {I18n.get("settings")}
        <br />
        <button onClick={signOut}>{I18n.get("sign_out")}</button>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={styles.cell}>{I18n.get("language")}</TableCell>
              <TableCell style={styles.cell}>
                <SelectField
                  descriptiveText={I18n.get("pick_language")}
                  value={langCode}
                  onChange={(e) => setLangCode(e.target.value)}
                >
                  {getLangCodesJsx()}
                </SelectField>
              </TableCell>
            </TableRow>
            {/* <TableRow>
            <TableCell style={styles.cell}>{I18n.get("screenname")}</TableCell>
            <TableCell style={styles.cell}>
              <Input
                id="screenName"
                value={screenName}
                onChange={(e) => setScreenName(e.currentTarget.value)}
              />
            </TableCell>
          </TableRow> */}
            <TableRow>
              <TableCell style={styles.cell}></TableCell>
              <TableCell style={styles.cell}>
                <Button loadingText="" onClick={() => saveSettings()}>
                  {I18n.get("save")}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </View>
    </>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    marginLeft: 20,
  },

  card: {
    backgroundColor: "#FFF8DC",
    borderRadius: 10,
  },

  cell: {
    padding: 0,
  },
};

export default withAuthenticator(Settings);
