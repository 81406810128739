/* src/History.js */
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Button, TextField, View, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  API,
  Analytics,
  I18n,
  Storage,
  graphqlOperation
} from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineLike } from "react-icons/ai";
import { IoFlagOutline } from "react-icons/io5";
import {
  createFlag,
  createPublicComment,
  createPublicLike,
  updatePublicComment,
} from "../graphql/mutations";
import { ConversationLog } from "./ConversationLog";
import { LanguageMapShort, UILangCodes } from "./CountryCodes";
import { GeoView } from "../GeoView";
import { PersonaContext } from "./PersonaContext";
import { PlayAudio, StopAudio } from "./PlayAudio";
import { LanguageContext } from "./LanguageContext";
import { Translate, TranslateWithMeta } from "./Translate";

export function ConversationLoad({ conversation, level, backCallback }) {
  const nativeCode = useContext(LanguageContext);
  const persona = useContext(PersonaContext);

  const [targetLanguageLabel, setTargetLanguageLabel] = useState("");
  const [chatNative, setChatNative] = useState([]);
  const [chatNativeTranslate, setChatNativeTranslate] = useState([]);
  const [chitTarget, setChitTarget] = useState([]);
  const [chitTargetTranslate, setChitTargetTranslate] = useState([]);
  const [images, setImages] = useState([]);
  const [curConversation, setCurConversation] = useState("");
  const [liked, setLiked] = useState(false);
  const [numLikes, setNumLikes] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);

  if (conversation) {
    if (conversation.id !== curConversation.id) {
      console.log(
        "clickLoadConversation " + JSON.stringify(conversation, null, 2)
      );
      setCurConversation(conversation);
      //loadConversation();
    }
  }

  useEffect(() => {
    let playStatus = true;

    console.log("persona " + JSON.stringify(persona, null, 2));
    console.log("conversation " + JSON.stringify(conversation, null, 2));

    async function loadConversation() {
      let conversationId = conversation.id;
      if (level === "public") {
        conversationId = conversation.conversationId;
        console.log("conversationId " + conversationId);
        console.log("conversation.conversation.id " + conversation.id);
      }
      const targetLangCodeTemp = conversation.targetLangCode;
      let chatNativeTemp = [];
      setTargetLanguageLabel(I18n.get(LanguageMapShort[targetLangCodeTemp]));

      console.log(
        "nativeCode " +
          nativeCode +
          " setTargetLanguageLabel " +
          targetLangCodeTemp +
          " " +
          LanguageMapShort[targetLangCodeTemp] +
          " " +
          I18n.get("fr-FR")
      );

      try {
        chatNativeTemp = JSON.parse(
          await (
            await Storage.get(
              conversationId + "/chatNative_" + nativeCode + ".json",
              {
                level: level,
                download: true,
                contentType: "application/json",
              }
            )
          ).Body.text()
        ).data;
      } catch (err) {
        let conversationIdTemp = conversation.id;
        if (level === "public") {
          conversationIdTemp = conversation.conversationId;
        }
        chatNativeTemp = await translateNativeText(
          conversationIdTemp,
          conversation.nativeLangCode,
          nativeCode,
          "/chatNative_"
        );
        Storage.put(
          conversationIdTemp + "/chatNative_" + nativeCode + ".json",
          { data: chatNativeTemp },
          {
            level: level,
            contentType: "application/json",
          }
        );
      }
      console.log("chatNativeTemp3 " + chatNativeTemp);
      let chatNativeTranslateTemp = JSON.parse(
        await (
          await Storage.get(
            conversationId +
              "/chatNativeTranslate_" +
              targetLangCodeTemp +
              ".json",
            { level: level, download: true, contentType: "application/json" }
          )
        ).Body.text()
      ).data;
      console.log("chatNativeTranslateTemp3 " + chatNativeTranslateTemp);
      //setChatNativeTranslate(chatNativeTranslateTemp);
      let chitTargetTemp = [];
      try {
        chitTargetTemp = JSON.parse(
          await (
            await Storage.get(
              conversationId + "/chitTarget_" + nativeCode + ".json",
              {
                level: level,
                download: true,
                contentType: "application/json",
              }
            )
          ).Body.text()
        ).data;
      } catch (err) {
        let conversationIdTemp = conversation.id;
        if (level === "public") {
          conversationIdTemp = conversation.conversationId;
        }
        chitTargetTemp = await translateNativeText(
          conversationIdTemp,
          conversation.targetLangCode,
          nativeCode,
          "/chitTargetTranslate_"
        );
        Storage.put(
          conversationIdTemp + "/chitTarget_" + nativeCode + ".json",
          { data: chitTargetTemp },
          {
            level: level,
            contentType: "application/json",
          }
        );
      }
      //setChitTarget(chitTargetTemp);

      let chitTargetTranslateTemp = JSON.parse(
        await (
          await Storage.get(
            conversationId +
              "/chitTargetTranslate_" +
              targetLangCodeTemp +
              ".json",
            {
              level: level,
              download: true,
              contentType: "application/json",
            }
          )
        ).Body.text()
      ).data;
      //setChitTargetTranslate(chitTargetTranslateTemp);

      let chatNativeTemp2 = [];
      let chatNativeTranslateTemp2 = [];
      let chitTargetTemp2 = [];
      let chitTargetTranslateTemp2 = [];

      setChitTarget(chitTargetTemp.slice(0, 1));
      setChitTargetTranslate(chitTargetTranslateTemp.slice(0, 1));
      setChatNative(chatNativeTemp.slice(0, 1));
      setChatNativeTranslate(chatNativeTranslateTemp.slice(0, 1));

      console.log("chatNativeTemp.length " + chatNativeTemp.length);

      // let imagesTemp = [];
      // // load images
      // for (let index = 0; index < chatNativeTemp.length; index++) {
      //   let imageTemp = null;
      //   console.log("imageTemp 1 " + index);
      //   try {
      //     imageTemp = await (
      //       await Storage.get(conversationId + "/" + index + ".png", {
      //         level: level,
      //         download: true,
      //       })
      //     ).Body.text();
      //   } catch (err) {}
      //   //console.log("imageTemp 2 " + imageTemp);
      //   imagesTemp = [...imagesTemp, imageTemp];
      // }
      // setImages(imagesTemp);

      let soundCounter = chitTargetTemp2.length + chatNativeTemp2.length + 1;
      console.log("soundCounter " + soundCounter);
      let aiSound = await (
        await Storage.get(conversationId + "/" + soundCounter + ".mp4", {
          level: level,
          download: true,
        })
      ).Body.arrayBuffer();

      for (let index = 0; index < chatNativeTemp.length; index++) {
        if (chatNativeTemp2.length <= 0) {
          chatNativeTemp2.push(chatNativeTemp[index]);
          chatNativeTranslateTemp2.push(chatNativeTranslateTemp[index]);
        }
        console.log("chatNativeTemp2 " + chatNativeTemp2);
        chitTargetTemp2.push(chitTargetTemp[index]);
        chitTargetTranslateTemp2.push(chitTargetTranslateTemp[index]);
        setChitTarget(chitTargetTemp.slice(0, index + 2));
        setChitTargetTranslate(chitTargetTranslateTemp.slice(0, index + 2));
        let soundCounter = chitTargetTemp2.length + chatNativeTemp2.length;
        console.log("soundCounter " + soundCounter);
        try {
          let aiSound = await (
            await Storage.get(conversationId + "/" + soundCounter + ".mp4", {
              level: level,
              download: true,
            })
          ).Body.arrayBuffer();
          if (conversation && playStatus) {
            await PlayAudio(aiSound);
          } else {
            return;
          }
        } catch (err) {
          console.log(
            "error fetching " + conversationId + "/" + soundCounter + ".mp4",
            err
          );
        }

        if (chatNativeTemp[index + 1]) {
          chatNativeTemp2.push(chatNativeTemp[index + 1]);
          chatNativeTranslateTemp2.push(chatNativeTranslateTemp[index + 1]);
          soundCounter++;
          console.log("soundCounter " + soundCounter);
          setChatNative(chatNativeTemp.slice(0, index + 2));
          setChatNativeTranslate(chatNativeTranslateTemp.slice(0, index + 2));
          aiSound = await (
            await Storage.get(conversationId + "/" + soundCounter + ".mp4", {
              level: level,
              download: true,
            })
          ).Body.arrayBuffer();
          if (conversation && playStatus) {
            await PlayAudio(aiSound);
          } else {
            return;
          }
        }
      }
    }
    loadConversation();

    async function fetchLikes() {
      if (
        conversation.likes &&
        conversation.likes.items &&
        conversation.likes.items.length > 0
      ) {
        setNumLikes(conversation.likes.items.length);
      }
    }
    fetchLikes();

    async function fetchComments() {
      if (
        conversation.comments &&
        conversation.comments.items &&
        conversation.comments.items.length > 0
      ) {
        setComments(conversation.comments.items);
      }
    }
    fetchComments();

    return () => {
      console.log("conversation unload...");
      playStatus = false;
      StopAudio();
    };
  }, [curConversation]);

  async function translateNativeText(
    conversationId,
    nativeCodeTemp,
    targetLangCodeTemp,
    jsonFile
  ) {
    return new Promise(async (resolve) => {
      console.log(
        "translateNativeText " +
          conversationId +
          " " +
          nativeCodeTemp +
          " " +
          targetLangCodeTemp +
          " "
      );

      let chatNativeTemp = JSON.parse(
        await (
          await Storage.get(
            conversationId + jsonFile + nativeCodeTemp + ".json",
            {
              level: level,
              download: true,
              contentType: "application/json",
            }
          )
        ).Body.text()
      ).data;

      console.log(
        "chatNativeTemp " +
          chatNativeTemp +
          " " +
          nativeCodeTemp +
          " " +
          nativeCode
      );

      let chatNativeTemp2 = await Translate(
        JSON.stringify(chatNativeTemp),
        nativeCodeTemp,
        nativeCode
      );

      console.log("chatNativeTemp2 " + chatNativeTemp2);
      //chatNativeTemp2 = chatNativeTemp2.replaceAll("”", '"');
      //chatNativeTemp2 = chatNativeTemp2.replaceAll("“", '"');
      let chatNativeTemp2b =
        "[" +
        JSON.stringify(
          chatNativeTemp2.substring(1, chatNativeTemp2.lastIndexOf("]"))
        ) +
        "]";
      console.log("chatNativeTemp2b " + chatNativeTemp2b);

      resolve(JSON.parse(chatNativeTemp2b));
    });
  }

  async function like() {
    console.log("like");
    try {
      let result = await API.graphql({
        ...graphqlOperation(createPublicLike, {
          input: {
            publicConversationLikesId: conversation.id,
            publicPersonaLikesId: persona.id,
          },
        }),
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      // let result = await API.graphql(
      //   graphqlOperation(createPublicLike, {
      //     input: {
      //       publicConversationLikesId: conversation.id,
      //       publicPersonaLikesId: persona.id,
      //     },
      //   })
      // );
      Analytics.record({
        name: "likeConversation",
        attributes: {
          nativeLangCode: conversation.nativeLangCode,
          targetLangCode: conversation.targetLangCode,
        },
      });
    } catch (err) {
      console.log("error liking:", err);
    }
  }

  async function flag() {
    console.log("flag");
    try {
      let result = await API.graphql(
        graphqlOperation(createFlag, {
          input: {
            publicConversationId: conversation.id,
          },
        })
      );
      backCallback();
      Analytics.record({
        name: "flagConversation",
        attributes: {
          nativeLangCode: conversation.nativeLangCode,
          targetLangCode: conversation.targetLangCode,
        },
      });
    } catch (err) {
      console.log("error flagging:", err);
    }
  }

  async function comment() {
    console.log("comment");
    try {
      // let result = await API.graphql(
      //   graphqlOperation(createPublicComment, {
      //     input: {
      //       publicConversationCommentsId: conversation.id,
      //       publicPersonaCommentsId: persona.id,
      //       nativeLangCode: nativeCode,
      //       ["message_" + nativeCode.replace("-", "")]: commentText,
      //     },
      //   })
      // );
      let result = await API.graphql({
        ...graphqlOperation(createPublicComment, {
          input: {
            publicConversationCommentsId: conversation.id,
            publicPersonaCommentsId: persona.id,
            nativeLangCode: nativeCode,
            ["message_" + nativeCode.replace("-", "")]: commentText,
          },
        }),
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      translateMessages(result.data.createPublicComment);
      setCommentText("");
      Analytics.record({
        name: "commentConversation",
        attributes: {
          nativeLangCode: conversation.nativeLangCode,
          targetLangCode: conversation.targetLangCode,
        },
      });
    } catch (err) {
      console.log("error flagging:", err);
    }
  }

  function translateMessages(publicComment) {
    let nativeLangCode = conversation.nativeLangCode;
    let translatePromises = [];
    for (const element of UILangCodes) {
      let targetLangCode = element;
      if (targetLangCode === nativeLangCode) {
        continue;
      }
      translatePromises.push(
        TranslateWithMeta(commentText, nativeLangCode, targetLangCode)
      );
    }

    Promise.all(translatePromises).then(async (responses) => {
      let updateInput = {
        id: publicComment.id,
      };

      for (const element of responses) {
        let response = element;
        let targetLangCode = response.language;
        let message = response.text;
        updateInput["message_" + targetLangCode.replace("-", "")] = message;
      }
      // let result = API.graphql(
      //   graphqlOperation(updatePublicComment, {
      //     input: updateInput,
      //   })
      // );
      let result = await API.graphql({
        ...graphqlOperation(updatePublicComment, {
          input: updateInput,
        }),
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
    });
  }

  return (
    <Flex direction={{ base: "column", large: "row" }}>
      <View
        width="100%"
        style={styles.container}
        className="col-7 col-s-12"
      >
        <ConversationLog
          chatNative={chatNative}
          chatNativeTranslate={chatNativeTranslate}
          chitTarget={chitTarget}
          chitTargetTranslate={chitTargetTranslate}
          targetLanguageLabel={targetLanguageLabel}
          images={images}
          showTryThis={true}
          nativeCode={nativeCode}
          targetCode={conversation.targetLangCode}
        />
        {level == "public" ? (
          <View>
            {!liked ? (
              <Button
                style={styles.button}
                onClick={() => {
                  like();
                }}
              >
                <AiOutlineLike />
              </Button>
            ) : null}
            <Button
              style={styles.button}
              onClick={() => {
                flag();
              }}
            >
              <IoFlagOutline />
            </Button>
            {numLikes > 0 ? (
              <p>
                {numLikes} {I18n.get("numLikes")}
              </p>
            ) : null}
            <TextField
              placeholder={I18n.get("comment")}
              value={commentText}
              onChange={(e) => {
                setCommentText(e.currentTarget.value);
              }}
              outerEndComponent={
                <Button
                  onClick={() => {
                    comment();
                  }}
                >
                  {I18n.get("submit")}
                </Button>
              }
            />
            {comments.map((comment, index) => {
              return (
                <p key={index}>
                  {comment.persona.screenName} -{" "}
                  {comment["message_" + nativeCode.replace("-", "")]}
                </p>
              );
            })}
          </View>
        ) : null}
      </View>
      <View style={styles.map} width="80%">
        {conversation.placeId ? (
          <GeoView
            placeId={conversation.placeId}
          />
        ) : null}
      </View>
    </Flex>
  );
}

const styles = {
  container: {
    padding: 0,
  },

  card: {
    backgroundColor: "#FFF8DC",
    borderRadius: 10,
  },

  map: {
    marginLeft: 0,
    marginRight: 40,
  },
};
