import { getOpenAIMessages } from "./GetOpenAIMessages";
import { Translate } from "../commonfn/Translate";
import { PlayTextAudio } from "../commonfn/PlayAudio";
import { updateStorage } from "../commonfn/StorageLib";
import { API, graphqlOperation, I18n } from "aws-amplify";
import { chat } from "../graphql/queries";

export async function chatAI(
  nativeCode,
  chatNativeTemp,
  chatNativeTranslateTemp,
  chitTarget,
  chitTargetTranslate,
  dialectCodeTemp,
  targetLanguageTemp,
  conversationTemp,
  humanSound,
  storyMode,
  textLength,
  placeInfo,
  targetLanguageLabel
) {
  return new Promise(async (resolve) => {
    let chitTargetTemp = [];
    let chitTargetTranslateTemp = [];

    let systemMessage = chatNativeTemp[0];
    systemMessage +=
      ". Have the AI respond in the " +
      targetLanguageLabel +
      " language and have the AI speak first";
    if (placeInfo) {
      systemMessage +=
        ".  Here is some context about the place: " +
        getPlaceContext(placeInfo);
    }

    let messages = getOpenAIMessages(
      chatNativeTranslateTemp,
      chitTargetTranslate,
      systemMessage
    );

    let params = {
      //model: "gpt-3.5-turbo",
      //model: "gpt-4-turbo-2024-04-09",
      //model: "gpt-4-turbo",
      model: "gpt-4o",
      messages: messages,
      max_tokens: 50,
    };

    if (!storyMode || storyMode === "no") {
      //params.stop = ["\n"];
    }

    if (textLength) {
      if (textLength === "very short") {
        textLength = 10;
      } else if (textLength === "short") {
        textLength = 30;
      } else if (textLength === "medium") {
        textLength = 100;
      } else if (textLength === "long") {
        textLength = 300;
      } else if (textLength === "very long") {
        textLength = 600;
      }
      params.max_tokens = textLength;
    }

    console.log("chat ai params: " + JSON.stringify(params));
    let chatResponse = null;

    try {
      chatResponse = await API.graphql(
        graphqlOperation(chat, {
          chatParams: JSON.stringify(params),
        })
      );
    } catch (error) {
      alert(I18n.get("limit_reached") + "chat");
      return;
    }

    let openaiVal = chatResponse.data.chat;
    chitTargetTranslateTemp = [...chitTargetTranslate, openaiVal];

    console.log(
      "chat ai translate: " +
        openaiVal +
        " " +
        targetLanguageTemp +
        " " +
        nativeCode
    );
    let translateVal = await Translate(
      openaiVal,
      targetLanguageTemp,
      nativeCode
    );
    chitTargetTemp = [...chitTarget, translateVal];

    PlayTextAudio(openaiVal, dialectCodeTemp, true).then((aiSound) => {
      updateStorage(
        conversationTemp,
        chatNativeTemp,
        chatNativeTranslateTemp,
        chitTargetTemp,
        chitTargetTranslateTemp,
        humanSound,
        aiSound,
        nativeCode,
        targetLanguageTemp
      );
    });

    resolve({
      chitTargetTranslate: chitTargetTranslateTemp,
      chitTarget: chitTargetTemp,
    });
  });

  function getPlaceContext(placeInfo) {
    let placeData = "";
    if (placeInfo.name) {
      placeData += placeInfo.name;
    }
    if (placeInfo.formatted_address) {
      placeData += ".  " + placeInfo.formatted_address;
    }
    if (placeInfo.editorial_summary && placeInfo.editorial_summary.overview) {
      placeData += ".  " + placeInfo.editorial_summary.overview;
    }
    if (placeInfo.reviews) {
      for (let i = 0; i < placeInfo.reviews.length && i < 3; i++) {
        placeData += ".  " + placeInfo.reviews[i].text;
      }
    }

    if (placeData.length > 1000) {
      placeData = placeData.substring(0, 1000);
    }
    console.log("placeData: " + placeData);
    return placeData;
  }
}
