/* src/History.js */
import { Text, Image, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { LanguageContext } from "./commonfn/LanguageContext";
import { useNavigate } from "react-router-dom";

const Onboard = () => {
  const nativeCode = useContext(LanguageContext);
  const navigate = useNavigate();

  // Hooks are called before the return statement on mount on unmount.
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8589250628943710"
    );
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", "true");
    document.head.appendChild(script);

    // To delete the script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <View style={styles.container} width="100%">
      <Text style={styles.tutorial}>
        <button
          style={styles.signOut}
          onClick={() => {
            navigate("/login");
          }}
        >
          {I18n.get("sign_in")}
        </button>
        <br />
        <br />
        {I18n.get("tut_overview")}
      </Text>
      <Image src="/tutorial/explore.png" width="50%" alt="explore tutorial" />
      <br />
      <Text style={styles.tutorial}>{I18n.get("tut_explore")}</Text>
      <p />
      <Image src="/tutorial/cuecard.png" width="30%" alt="cue card tutorial" />
      <br />
      <Text style={styles.tutorial}>{I18n.get("tut_cuecard")}</Text>
      <p />
      <Image src="/tutorial/coach.png" width="50%" alt="coach tutorial" />
      <br />
      <Text style={styles.tutorial}>{I18n.get("tut_coach")}</Text>
      <p />
      <p />
      <button
        onClick={() => {
          navigate("/login");
        }}
      >
        {I18n.get("sign_up")}
      </button>
    </View>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    marginLeft: 20,
    //marginRight: 20,
  },
  tutorial: {
    width: "70%",
  },
  signOut: {
    float: "right",
    marginRight: 0,
  },
};

//export default withAuthenticator(Home);
export default Onboard;
