import {
  DialectCodeArray,
  VoiceIdMap,
  CountryCodeMap,
  LanguageMap,
} from "./CountryCodes";
import { Predictions } from "aws-amplify";

export async function PlayTextAudio(content, dialectCodeTemp, primarySpeakerBool) {
  return new Promise((resolve) => {
    let voiceId = VoiceIdMap[dialectCodeTemp][0];

    if ((!primarySpeakerBool) && (VoiceIdMap[dialectCodeTemp].length > 1)) {
      voiceId = VoiceIdMap[dialectCodeTemp][1];
    }
    Predictions.convert({
      textToSpeech: {
        source: {
          text: content,
        },
        voiceId: voiceId, // default configured on aws-exports.js
        // list of different options are here https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
        languageCode: dialectCodeTemp,
      },
    })
      .then(async (result) => {
        PlaySound(result.audioStream, resolve);
      })
      .catch((err) => console.log({ err }));
  });
}

export async function PlayAudio(blob) {
  return new Promise((resolve) => {
    PlaySound(blob, resolve);
  });
}

let source;
export function PlaySound(blob, resolve) {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  let audioContextTemp = new AudioContext();
  let blobCopy = blob.slice(0);
  source = audioContextTemp.createBufferSource();
  source.addEventListener("ended", () => {
    source = null;
    resolve(blobCopy);
  });
  audioContextTemp.decodeAudioData(
    blob,
    (buffer) => {
      source.buffer = buffer;
      source.connect(audioContextTemp.destination);
      source.start(0);
    },
    (err) => console.log({ err })
  );
}

export function StopAudio() {
  if (source) {
    source.stop();
  }
}
