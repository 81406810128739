import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import {
  API,
  Amplify,
  Analytics,
  Auth,
  I18n,
  graphqlOperation,
} from "aws-amplify";
import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import CueCard from "./CueCard";
import Delete from "./Delete";
import Explore from "./Explore";
import Coach from "./Coach";
import History from "./History";
import Home from "./Home";
import Onboard from "./Onboard";
import Login from "./Login";
import Settings from "./Settings";
import Transcribe from "./Transcribe";
import Translate from "./Translate";
import YouSpeak from "./YouSpeak";
import awsExports from "./aws-exports";
import { LanguageContext } from "./commonfn/LanguageContext";
import { languageTable } from "./commonfn/LanguageTable";
import { MenuBar } from "./commonfn/MenuBar";
import { PersonaContext } from "./commonfn/PersonaContext";
import { SettingContext } from "./commonfn/SettingContext";
import { createPublicPersona } from "./graphql/mutations";
import { listSettings, publicPersonasByOwnerId } from "./graphql/queries";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
Amplify.configure(awsExports);
//Amplify.addPluggable(new AmazonAIPredictionsProvider());

I18n.putVocabularies(languageTable);

Analytics.autoTrack("session", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  //attributes: {
  //  attr: "attr",
  //},

  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
});

Analytics.autoTrack("pageView", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: "pageView",
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  //attributes: {
  //  attr: "attr",
  //},

  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: "multiPageApp",
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

export default function Index() {
  const [settings, setSettings] = useState({});
  const settingsValue = useMemo(() => ({ settings, setSettings }), [settings]);
  const [nativeCode, setNativeCode] = useState("");
  const [publicPersona, setPublicPersona] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (nativeCode === "") {
      let browserLanguageCode = navigator.language;
      console.log("browser language 1: " + browserLanguageCode);
      console.log("settings lang" + settings.nativeLangCode);
      let nativeCodeTemp = browserLanguageCode;

      if (settings && settings.nativeLangCode) {
        I18n.setLanguage(settings.nativeLangCode);
        setNativeCode(settings.nativeLangCode);
      } else if (languageTable[browserLanguageCode] !== undefined) {
        I18n.setLanguage(browserLanguageCode);
        setNativeCode(browserLanguageCode);
      } else {
        let browserLanguageCodeShort = browserLanguageCode.substring(0, 2);
        if (languageTable[browserLanguageCodeShort] !== undefined) {
          I18n.setLanguage(browserLanguageCodeShort);
          setNativeCode(browserLanguageCodeShort);
          nativeCodeTemp = browserLanguageCodeShort;
        } else {
          I18n.setLanguage("en");
          setNativeCode("en");
          nativeCodeTemp = "en";
        }
      }
      Analytics.record({
        name: "sessionLanguageCode",
        attributes: { nativeCode: nativeCodeTemp },
      });
    }
  }, [nativeCode]);

  useEffect(() => {
    //console.log("settings: " + JSON.stringify(settings, null, 2));
    if (!settings.nativeLangCode) {
      Auth.currentUserInfo().then((user) => {
        if (user) {
          setLoggedIn(true);
          fetchSettings();
          fetchOrCreatePublicPersona(user);
        }
      });
    }
  }, [settings]);

  async function fetchSettings() {
    try {
      const res = await API.graphql(graphqlOperation(listSettings, {}));
      //console.log("res settings: " + JSON.stringify(res, null, 2));
      const settingsTemp = res.data.listSettings.items;
      if (settingsTemp && settingsTemp.length > 0) {
        I18n.setLanguage(settingsTemp[0].nativeLangCode);
        setNativeCode(settingsTemp[0].nativeLangCode);
        setSettings(settingsTemp[0]);
        console.log(
          "settingsTemp[0].nativeLangCode: " + settingsTemp[0].nativeLangCode
        );
        //navigate("/speak");
        //window.location.reload();
      }
    } catch (err) {
      console.log("error fetching settings", err);
    }
  }

  async function fetchOrCreatePublicPersona(user) {
    try {
      // const res = await API.graphql(graphqlOperation(listPublicPersonas, {}));
      // console.log("res public personas: " + JSON.stringify(res, null, 2));
      // const publicPersonasTemp = res.data.listPublicPersonas.items;
      // if (publicPersonasTemp && publicPersonasTemp.length > 0) {
      //   setPublicPersona(publicPersonasTemp[0]);

      Auth.currentUserInfo().then(async (user) => {
        if (user) {
          const res = await API.graphql({
            ...graphqlOperation(publicPersonasByOwnerId, {
              ownerId: user.username,
            }),
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          //console.log("res: " + JSON.stringify(res, null, 2));
          setPublicPersona(res.data.publicPersonasByOwnerId.items[0]);
        } else {
          let result = await API.graphql(
            graphqlOperation(createPublicPersona, {
              input: {
                ownerId: user.username,
                nativeLangCode: nativeCode,
              },
            })
          );
          console.log(
            "createPublicPersona: " + JSON.stringify(result, null, 2)
          );
          setPublicPersona(result.data.createPublicPersona);
        }
      });
    } catch (err) {
      console.log("error fetching public personas", err);
    }
  }

  const handleChangeLanguage = (langCode) => {
    I18n.setLanguage(langCode);
    setNativeCode(langCode);
    Analytics.record({
      name: "changeLanguage",
      attributes: { nativeLangCode: langCode },
    });
  };

  return (
    <PersonaContext.Provider value={publicPersona}>
      <LanguageContext.Provider value={nativeCode}>
        <SettingContext.Provider value={settingsValue}>
          {/* {useMemo(
            () => ( */}
          <BrowserRouter>
            <Routes>
              {loggedIn ? (
                <>
                  <Route index element={<Explore />} />
                  <Route path="login" element={<Explore />} />
                  <Route path="speak" element={<YouSpeak />} />
                  <Route path="cuecard" element={<CueCard />} />
                  <Route path="history" element={<History />} />
                  <Route path="explore" element={<Explore />} />
                  <Route path="coach" element={<Coach />} />
                  <Route path="delete" element={<Delete />} />
                  <Route path="translate" element={<Translate />} />
                  <Route path="transcribe" element={<Transcribe />} />
                  <Route
                    path="settings"
                    element={
                      <Settings changeLanguageCallback={handleChangeLanguage} />
                    }
                  />
                  <Route path="app" element={<App />} />
                </>
              ) : (
                <>
                  <Route index element={<Onboard />} />
                  <Route path="login" element={<Login />} />
                </>
              )}
            </Routes>
          </BrowserRouter>
          {/* ),
            []
          )} */}
        </SettingContext.Provider>
      </LanguageContext.Provider>
    </PersonaContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
