/* src/Coach.js */
import {
  Flex,
  Button,
  Text,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { MenuBar } from "./commonfn/MenuBar";
import "@aws-amplify/ui-react/styles.css";
import { useWhisper } from "@chengsokdara/use-whisper";
import {
  API,
  Analytics,
  Auth,
  I18n,
  Storage,
  graphqlOperation,
} from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { chat } from "./graphql/queries";
import { LanguageContext } from "./commonfn/LanguageContext";
import { speechToText } from "./graphql/mutations";
import { conversationsByDate, cueCardsByDate } from "./graphql/queries";
import { CreateCueCard } from "./commonfn/CreateCueCard";
import { useNavigate } from "react-router-dom";

/* src/Coach.js */
const Coach = ({ signOut, user }) => {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState();
  const nativeCode = useContext(LanguageContext);
  const [converseState, setConverseState] = useState("pause");
  const [converseNativeButtonLabel, setConverseNativeButtonLabel] = useState(
    I18n.get("speak_native")
  );
  const [identityId, setIdentityId] = useState("");
  const [cuecards, setCuecards] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [conversationAdvice, setConversationAdvice] = useState([]);
  const [cueCardAdvice, setCueCardAdvice] = useState([]);
  const [cueCardsJsx, setCueCardsJsx] = useState([]);
  const [cueCardCoaching, setCardCoaching] = useState([]);

  // Hooks are called before the return statement on mount on unmount.
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8589250628943710"
    );
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", "true");
    document.head.appendChild(script);

    // To delete the script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (cuecards.length === 0) {
      fetchCuecards();
    }
  }, [cuecards]);

  async function fetchCuecards() {
    try {
      const res = await API.graphql(
        graphqlOperation(cueCardsByDate, {
          active: "true",
          limit: 100,
          sortDirection: "DESC",
        })
      );
      console.log("con res: " + JSON.stringify(res, null, 2));
      let cuecardsTemp = res.data.cueCardsByDate.items;
      console.log("cuecardsTemp: " + JSON.stringify(cuecardsTemp, null, 2));
      if (cuecardsTemp && cuecardsTemp.length > 0) {
        setCuecards(cuecardsTemp);

        let cardsArray = [];
        for (let i = 0; i < cuecardsTemp.length && i < 20; i++) {
          cardsArray.push(
            <>
              <Text key={cuecardsTemp[i].id}>
                <Button
                  //style={styles.button}
                  size="small"
                  onClick={() =>
                    getCoaching(
                      cuecardsTemp[i].nativeLangCode,
                      cuecardsTemp[i].nativeText,
                      cuecardsTemp[i].targetDialectCode,
                      cuecardsTemp[i].targetText
                    )
                  }
                >
                  {I18n.get("ask_coach")}
                </Button>
                &nbsp;&nbsp;
                {cuecardsTemp[i].targetText +
                  " - " +
                  cuecardsTemp[i].nativeText}
              </Text>
            </>
          );
        }
        setCueCardsJsx(cardsArray);

        let titles = "";
        console.log("nativeCode: " + nativeCode);
        for (let i = 0; i < cuecardsTemp.length; i++) {
          titles +=
            '"' +
            cuecardsTemp[i].nativeLangCode +
            ":" +
            cuecardsTemp[i].nativeText +
            "|" +
            cuecardsTemp[i].targetDialectCode +
            ":" +
            cuecardsTemp[i].targetText +
            '", ';
        }
        // titles += '"Talk to a waiter in a restaurant", ';
        // titles += '"Talk to a bus driver", ';
        // titles += '"Talk to a local by barcelona cathedral", ';
        if (titles.length > 0) {
          titles = titles.substring(0, titles.length - 2);
        }
        let messages = [
          {
            role: "system",
            content:
              "Based on this list of cue cards, " +
              titles +
              " suggest a new list of ten cue cards that will help the user learn languages.  " +
              "Use the same format as the user's past cue cards.  ",
          },
        ];
        console.log("messages: " + JSON.stringify(messages, null, 2));

        let params = {
          //model: "gpt-3.5-turbo",
          //model: "gpt-4-turbo-2024-04-09",
          //model: "gpt-4-turbo",
          model: "gpt-4o",
          messages: messages,
          max_tokens: 500,
        };

        let chatResponse = await API.graphql(
          graphqlOperation(chat, {
            chatParams: JSON.stringify(params),
          })
        );
        console.log("chatResponse: " + JSON.stringify(chatResponse, null, 2));
        let cueCardResponse = chatResponse.data.chat;
        let cueCardJSX = [];
        let i = 0;
        if (cueCardResponse && cueCardResponse.indexOf("1.") >= 0) {
          let cardStart = cueCardResponse.indexOf("1.");
          let colonIndex = cueCardResponse.indexOf(":", cardStart + 1);
          while (colonIndex >= 0) {
            let cueCardNativeCode = cueCardResponse.substring(
              colonIndex - 2,
              colonIndex
            );
            let pipeIndex = cueCardResponse.indexOf("|", colonIndex + 1);
            let cueCardNativeText = cueCardResponse.substring(
              colonIndex + 1,
              pipeIndex
            );

            colonIndex = cueCardResponse.indexOf(":", colonIndex + 1);
            let cueCardTargetCode = cueCardResponse.substring(
              colonIndex - 5,
              colonIndex
            );
            let endIndex = cueCardResponse.indexOf("\n", colonIndex + 1);
            if (endIndex < 0) {
              endIndex = cueCardResponse.length;
            }
            let cueCardTargetText = cueCardResponse.substring(
              colonIndex + 1,
              endIndex
            );
            i++;
            cueCardJSX.push(
              <Text key={"cc" + i}>
                <Button
                  //style={styles.button}
                  size="small"
                  onClick={(e) => {
                    // CreateCueCard(
                    //   cueCardNativeCode,
                    //   cueCardTargetCode.substring(0, 2),
                    //   cueCardTargetCode,
                    //   cueCardTargetText
                    // );
                    e.target.style.display = "none";
                  }}
                >
                  {I18n.get("add_cue_card")}
                </Button>
                &nbsp;&nbsp;
                {cueCardTargetText} - {cueCardNativeText}
              </Text>
            );
            colonIndex = cueCardResponse.indexOf(":", colonIndex + 1);
          }
        }
        setCueCardAdvice(cueCardJSX);
      }
    } catch (err) {
      console.log("error fetching cue cards", err);
    }
  }

  async function getCoaching(
    nativeCodeTemp,
    nativeTextTemp,
    targetCodeTemp,
    targetTextTemp
  ) {
    try {
      let messages = [
        {
          role: "system",
          content:
            "The user is having problems memorizing this cue card with text " +
            nativeTextTemp +
            " and language code " +
            nativeCodeTemp +
            " and the translated text " +
            targetTextTemp +
            " with language code " +
            targetCodeTemp +
            ".  Explain the translation to the user and provide some examples of how and when it is used.  If possible, suggest some ideas on how the user can better memorize the translation.",
        },
      ];

      let params = {
        //model: "gpt-3.5-turbo",
        //model: "gpt-4-turbo-2024-04-09",
        //model: "gpt-4-turbo",
        model: "gpt-4o",
        messages: messages,
        max_tokens: 500,
      };

      let chatResponse = await API.graphql(
        graphqlOperation(chat, {
          chatParams: JSON.stringify(params),
        })
      );
      console.log("chatResponse: " + JSON.stringify(chatResponse, null, 2));
      const chatResponseJSX = [];
      chatResponse.data.chat.split("\n").forEach((line, index) => {
        chatResponseJSX.push(<Text key={"ccr" + index}>{line}</Text>);
      });
      setCardCoaching(chatResponseJSX);
    } catch (err) {
      console.log("error getting coaching", err);
    }
  }

  useEffect(() => {
    if (conversations.length === 0 && nativeCode) {
      fetchConversations();
    }
  }, [nativeCode, conversations]);

  async function fetchConversations() {
    try {
      const res = await API.graphql(
        graphqlOperation(conversationsByDate, {
          active: "true",
          limit: 30,
          sortDirection: "DESC",
        })
      );
      console.log("con res: " + JSON.stringify(res, null, 2));
      const conversationsTemp = res.data.conversationsByDate.items;
      console.log(
        "conversationsTemp: " + JSON.stringify(conversationsTemp, null, 2)
      );
      if (conversationsTemp && conversationsTemp.length > 0) {
        setConversations(conversationsTemp);
        let titles = "";
        console.log("nativeCode: " + nativeCode);
        for (let i = 0; i < conversationsTemp.length; i++) {
          let title = "title_" + nativeCode.replace("-", "");
          titles += '"' + conversationsTemp[i][title] + '", ';
        }
        // titles += '"Talk to a waiter in a restaurant", ';
        // titles += '"Talk to a bus driver", ';
        // titles += '"Talk to a local by barcelona cathedral", ';
        if (titles.length > 0) {
          titles = titles.substring(0, titles.length - 2);
        }
        let messages = [
          {
            role: "system",
            content:
              "Based on this list of ChatGPT conversation prompts the user has had with AI in the past, " +
              titles +
              " suggest a new list of ten ChatGPT conversation prompts that will help the user learn a language.  " +
              "Try to include a type of person and type of business or tourist attraction.  " +
              "Try to keep the locations in the same cities as the user's past conversations.  " +
              "Answer in " +
              nativeCode +
              ".",
          },
        ];
        console.log("messages: " + JSON.stringify(messages, null, 2));

        let params = {
          //model: "gpt-3.5-turbo",
          //model: "gpt-4-turbo-2024-04-09",
          //model: "gpt-4-turbo",
          model: "gpt-4o",
          messages: messages,
          max_tokens: 500,
        };

        let chatResponse = await API.graphql(
          graphqlOperation(chat, {
            chatParams: JSON.stringify(params),
          })
        );
        console.log("chatResponse: " + JSON.stringify(chatResponse, null, 2));

        let conversationResponse = chatResponse.data.chat;
        let conversationJSX = [];
        let i = 0;
        if (conversationResponse && conversationResponse.indexOf("1.") >= 0) {
          let listStart = conversationResponse.indexOf("1.");
          let periodIndex = conversationResponse.indexOf(".", listStart + 1);
          while (periodIndex >= 0) {
            let conversationText = "";
            let endIndex = conversationResponse.indexOf("\n", periodIndex + 1);
            if (endIndex < 0) {
              conversationText = conversationResponse.substring(
                periodIndex + 1,
                conversationResponse.length
              );
            } else {
              conversationText = conversationResponse.substring(
                periodIndex + 1,
                endIndex
              );
            }
            conversationText = conversationText.replaceAll('"', "").trim();
            if (conversationText.length > 5) {
              conversationJSX.push(
                <Text key={"cn" + i++}>
                  <Button
                    //style={styles.button}
                    size="small"
                    onClick={() =>
                      navigate("/speak?prompt=" + conversationText)
                    }
                  >
                    {I18n.get("try_this")}
                  </Button>
                  &nbsp;&nbsp;
                  {conversationText}
                </Text>
              );
            }
            periodIndex = conversationResponse.indexOf(".", periodIndex + 1);
          }
        }
        setConversationAdvice(conversationJSX);
      }
    } catch (err) {
      console.log("error fetching conversations", err);
    }
  }

  useEffect(() => {
    getIdentityId();
  }, [identityId]);

  async function getIdentityId() {
    const info = await Auth.currentCredentials();
    setIdentityId(info.identityId);
  }

  function recordVoice() {
    console.log("recordVoice: " + converseState);
    if (converseState === "pause") {
      startRecording();
      setConverseNativeButtonLabel(I18n.get("stop_recording"));
      setConverseState("recording");
    } else if (converseState === "recording") {
      stopRecording();
      setConverseNativeButtonLabel(I18n.get("processing"));
      Analytics.record({
        name: "coach",
      });
      setConverseState("processing");
    }
  }

  const onTranscribe = async (blob) => {
    try {
      // const base64 = await new Promise((resolve) => {
      //   const reader = new FileReader();
      //   reader.onloadend = () => resolve(reader.result);
      //   reader.readAsDataURL(blob);
      // });
      const result2 = await Storage.put("audioprompt.mp3", blob, {
        level: "private",
      });
      console.log("result2: " + JSON.stringify(result2, null, 2));
      //const body = JSON.stringify({ file: base64, model: "whisper-1" });
      let t = Date.now();
      let result = await API.graphql(
        graphqlOperation(speechToText, {
          language: nativeCode.substr(0, 2),
          //soundFile: base64,
          identityId: identityId,
        })
      );
      console.log("transcribe time: " + (Date.now() - t));
      let textResult = result.data.speechToText;
      console.log("textResult: " + textResult);

      setPrompt(textResult);
    } catch (error) {
      alert(I18n.get("limit_reached") + "st");
      return;
    }
    setConverseNativeButtonLabel(I18n.get("speak_native"));
    setConverseState("pause");
  };

  const { startRecording, stopRecording } = useWhisper({
    whisperConfig: {
      language: nativeCode.substr(0, 2),
      removeSilence: true,
    },
    onTranscribe,
  });

  return (
    <>
      <MenuBar />
      <Flex direction={{ base: "column", large: "row" }}>
        <View width="100%" style={styles.container} className="col-7 col-s-12">
          {cuecards.length <= 0 && conversations.length <= 0 ? (
            <Text>{I18n.get("coach_help")}</Text>
          ) : null}
          {/* <Button
          style={styles.button}
          size="large"
          onClick={() => recordVoice()}
        >
          <CiVolumeHigh size={30} /> {converseNativeButtonLabel}
        </Button>
        <p /> */}
          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={400}
            fontSize="1.5em"
            fontStyle="bold"
            textDecoration="none"
            width="30vw"
          >
            {I18n.get("coach_header")}
          </Text>
          {cueCardCoaching}
          <p />
          {cueCardsJsx}
          <p />
          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={400}
            fontSize="1.5em"
            fontStyle="bold"
            textDecoration="none"
            width="30vw"
          >
            {I18n.get("cue_card_suggestion")}
          </Text>
          {cueCardAdvice}
          <p />
          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={400}
            fontSize="1.5em"
            fontStyle="bold"
            textDecoration="none"
            width="30vw"
          >
            {I18n.get("conversation_suggestion")}
          </Text>
          {conversationAdvice}
          <p />
        </View>
        <View width="50%"></View>
      </Flex>
    </>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    padding: 10,
  },

  map: {
    padding: 20,
  },

  button: {
    backgroundColor: "#D3D3D3",
    width: "170px",
    margin: "3px",
  },
};

export default withAuthenticator(Coach);
