import React, { useEffect, useRef } from "react";

export function BigMap({ center, zoom, onPlaceClick, onMapClick }) {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: center,
      zoom: zoom
    });

    map.addListener("click", (e) => {
      console.log("map click", e);
      if (e.placeId) {
        onPlaceClick({
          placeId: e.placeId,
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
      } else {
        onMapClick({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
      }
    });
  });

  //return <div ref={ref} id="map" />;
  return <div id="map" ref={ref} style={{ width: "100%", height: "400px" }} />;
}

export function StreetMap({ center }) {
  const ref = useRef();

  useEffect(() => {
    // const fenway = { lat: 42.345573, lng: -71.098326 };
    // const map = new google.maps.Map(document.getElementById("map"), {
    //   center: fenway,
    //   zoom: 14,
    // });
    // const panorama = new google.maps.StreetViewPanorama(
    //   document.getElementById("pano"),
    //   {
    //     position: fenway,
    //     pov: {
    //       heading: 34,
    //       pitch: 10,
    //     },
    //   },
    // );

    // map.setStreetView(panorama);
    const map = new window.google.maps.StreetViewPanorama(ref.current, {
      position: center,
      pov: {
        // heading: 34,
        // pitch: 10,
        heading: 0,
        pitch: 0,
      },
    });

    map.addListener("click", (e) => {
      console.log("street map click", e);

      // if (e.placeId) {
      //   onPlaceClick({
      //     placeId: e.placeId,
      //     lat: e.latLng.lat(),
      //     lng: e.latLng.lng(),
      //   });
      // } else {
      //   onMapClick({
      //     lat: e.latLng.lat(),
      //     lng: e.latLng.lng(),
      //   });
      // }

    });
  });

  //return <div ref={ref} id="map" />;
  return <div id="map" ref={ref} style={{ width: "100%", height: "400px" }} />;
}
