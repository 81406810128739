import {
  I18n
} from "aws-amplify";

export const DialectCodeArray = [
    "arb",
    "cmn-CN",
    "da-DK",
    "nl-NL",
    "en-AU",
    "en-GB",
    "en-IN",
    "en-US",
    "en-GB-WLS",
    "fr-FR",
    "fr-CA",
    "de-DE",
    "hi-IN",
    "is-IS",
    "it-IT",
    "ja-JP",
    "ko-KR",
    "nb-NO",
    "pl-PL",
    "pt-BR",
    "pt-PT",
    "ro-RO",
    "ru-RU",
    "es-ES",
    "es-MX",
    "es-US",
    "sv-SE",
    "tr-TR",
    "cy-GB",
  ];

// export const UILangCodes = [
//   'nl',
//   'en',
//   'fr',
//   'zh', // Simplified Chinese
//   'zh-TW', // Traditional Chinese
//   'es',
// ];

export const UILangCodes = [
    'en',
    'es',
    'es-MX',
    'fr',
    'fr-CA',
    'de',
    'zh', // Simplified Chinese
    'zh-TW', // Traditional Chinese
    'ar',
    'da',
    'nl',
    'bn',
    'hi',
    'is',
    'it',
    'ja',
    'ko',
    'pl',
    'pt',
    'pt-PT',
    'ro',
    'ru',
    'sv',
    'tr',
    'cy',
  ];

export const TextCodeArray = [
    'af',
    'sq',
    'am',
    'ar',
    'hy',
    'az',
    'bn',
    'bs',
    'bg',
    'ca',
    'zh-Simplified', // Simplified Chinese
    'zh-Traditional', // Traditional Chinese
    'hr',
    'cs',
    'da',
    'fa-AF',
    'nl',
    'en',
    'et',
    'fa',
    'tl',
    'fi',
    'fr',
    'fr-CA',
    'ka',
    'de',
    'el',
    'gu',
    'ht',
    'ha',
    'he',
    'hi',
    'hu',
    'is',
    'id',
    'ga',
    'it',
    'ja',
    'kn',
    'kk',
    'ko',
    'lv',
    'lt',
    'mk',
    'ms',
    'ml',
    'mt',
    'mr',
    'mn',
    'no',
    'ps',
    'pl',
    'pt',
    'pt-PT',
    'pa',
    'ro',
    'ru',
    'sr',
    'si',
    'sk',
    'sl',
    'so',
    'es',
    'es-MX',
    'sw',
    'sv',
    'ta',
    'te',
    'th',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'cy',
  ];

export const VoiceIdMap = {
    arb: ["Zeina"],
    "cmn-CN": ["Zhiyu"],
    "da-DK": ["Naja", "Mads"],
    "nl-NL": ["Lotte", "Ruben"],
    "en-AU": ["Nicole", "Russell"],
    "en-GB": ["Amy", "Brian"],
    "en-IN": ["Aditi", "Raveena"],
    "en-US": ["Joanna", "Joey"],
    "en-GB-WLS": ["Geraint"],
    "fr-FR": ["Celine", "Mathieu"],
    "fr-CA": ["Chantal"],
    "de-DE": ["Marlene", "Hans"],
    "hi-IN": ["Aditi"],
    "is-IS": ["Dora", "Karl"],
    "it-IT": ["Carla", "Giorgio"],
    "ja-JP": ["Mizuki", "Takumi"],
    "ko-KR": ["Seoyeon"],
    "nb-NO": ["Liv"],
    "pl-PL": ["Ewa", "Jacek"],
    "pt-BR": ["Camila", "Ricardo"],
    "pt-PT": ["Ines", "Cristiano"],
    "ro-RO": ["Carmen"],
    "ru-RU": ["Tatyana", "Maxim"],
    "es-ES": ["Conchita", "Enrique"],
    "es-MX": ["Mia"],
    "es-US": ["Lupe", "Miguel"],
    "sv-SE": ["Astrid"],
    "tr-TR": ["Filiz"],
    "cy-GB": ["Gwyneth"],
  };

// export function CountryCodeMap() {
//   return {
//     arb: "ar",
//     "cmn-CN": "zh",
//     "da-DK": "da",
//     "nl-NL": "nl",
//     "en-AU": "en",
//     "en-GB": "en",
//     "en-IN": "en",
//     "en-US": "en",
//     "en-GB-WLS": "en",
//     "fr-FR": "fr",
//     "fr-CA": "fr",
//     "de-DE": "de",
//     "hi-IN": "hi",
//     "is-IS": "is",
//     "it-IT": "it",
//     "ja-JP": "ja",
//     "ko-KR": "ko",
//     "nb-NO": "nb",
//     "pl-PL": "pl",
//     "pt-BR": "pt",
//     "pt-PT": "pt",
//     "ro-RO": "ro",
//     "ru-RU": "ru",
//     "es-ES": "es",
//     "es-MX": "es",
//     "es-US": "es",
//     "sv-SE": "sv",
//     "tr-TR": "tr",
//     "cy-GB": "cy",
//   };
// }

export const LanguageMap = {
    arb: I18n.get("arb"),
    "cmn-CN": I18n.get("cmn-CN"),
    "da-DK": I18n.get("da-DK"),
    "nl-NL": I18n.get("nl-NL"),
    "en-AU": I18n.get("en-AU"),
    "en-GB": I18n.get("en-GB"),
    "en-IN": I18n.get("en-IN"),
    "en-US": I18n.get("en-US"),
    "en-GB-WLS": I18n.get("en-GB-WLS"),
    "fr-FR": I18n.get("fr-FR"),
    "fr-CA": I18n.get("fr-CA"),
    "de-DE": I18n.get("de-DE"),
    "hi-IN": I18n.get("hi-IN"),
    "is-IS": I18n.get("is-IS"),
    "it-IT": I18n.get("it-IT"),
    "ja-JP": I18n.get("ja-JP"),
    "ko-KR": I18n.get("ko-KR"),
    "nb-NO": I18n.get("nb-NO"),
    "pl-PL": I18n.get("pl-PL"),
    "pt-BR": I18n.get("pt-BR"),
    "pt-PT": I18n.get("pt-PT"),
    "ro-RO": I18n.get("ro-RO"),
    "ru-RU": I18n.get("ru-RU"),
    "es-ES": I18n.get("es-ES"),
    "es-MX": I18n.get("es-MX"),
    "es-US": I18n.get("es-US"),
    "sv-SE": I18n.get("sv-SE"),
    "tr-TR": I18n.get("tr-TR"),
    "cy-GB": I18n.get("cy-GB"),
  };

  export const LanguageMapShort = {
    ar: I18n.get("arb"),
    "zh": I18n.get("cmn-CN"),
    "da": I18n.get("da-DK"),
    "nl": I18n.get("nl-NL"),
    "en": I18n.get("en-US"),
    "fr": I18n.get("fr-FR"),
    "de": I18n.get("de-DE"),
    "hi": I18n.get("hi-IN"),
    "is": I18n.get("is-IS"),
    "it": I18n.get("it-IT"),
    "ja": I18n.get("ja-JP"),
    "ko": I18n.get("ko-KR"),
    "nb": I18n.get("nb-NO"),
    "pl": I18n.get("pl-PL"),
    "pt": I18n.get("pt-BR"),
    "ro": I18n.get("ro-RO"),
    "ru": I18n.get("ru-RU"),
    "es": I18n.get("es-ES"),
    "sv": I18n.get("sv-SE"),
    "tr": I18n.get("tr-TR"),
    "cy": I18n.get("cy-GB"),
  };