import { createConversation, updateConversation } from "../graphql/mutations";
import { Translate, TranslateWithMeta } from "../commonfn/Translate";
import { API, graphqlOperation } from "aws-amplify";
import { UILangCodes } from "../commonfn/CountryCodes";

function getTitle(chatVal) {
  let title = "";
  if (chatVal.length > 100) {
    title = chatVal.substring(0, 97) + "...";
  } else {
    title = chatVal;
  }
  return title;
}

export async function chatHuman(
  chatNative,
  chatNativeTranslate,
  conversation,
  chatter,
  langCode,
  nativeCode,
  dialectCodeTemp,
  targetCode,
  humanSound
) {
  return new Promise(async (resolve) => {
    let soundFile = humanSound;
    let chatNativeTemp = [];
    let chatNativeTranslateTemp = [];

    let conversationTemp = conversation;
    console.log(
      "chatHuman: " +
        chatter +
        " 1 " +
        langCode +
        " 2 " +
        nativeCode +
        " 3 " +
        targetCode +
        " 4 " +
        dialectCodeTemp
    );

    if (chatNative.length <= 0) {
      // create conversation to store in history
      try {
        // title 100 characters max
        // description 5000 characters max
        let title = "title_" + nativeCode.replace("-", "");
        console.log("title1: " + title);
        let result = await API.graphql(
          graphqlOperation(createConversation, {
            input: {
              [title]: getTitle(chatter),
              targetLangCode: targetCode,
              nativeLangCode: nativeCode,
              conversationId: "0",
              active: "true",
            },
          })
        );
        conversationTemp = result.data.createConversation;
        translateTitles(conversationTemp);
      } catch (err) {
        console.log("error creating conversation:", err);
      }
    }

    console.log("chatHuman: " + chatter + " 5 " + langCode + " 6 " + nativeCode);
    if (langCode === nativeCode.substring(0, 2)) {
      chatNativeTemp = [...chatNative, chatter];
      let translateVal = await Translate(chatter, langCode, targetCode);
      chatNativeTranslateTemp = [...chatNativeTranslate, translateVal];
    } else {
      chatNativeTranslateTemp = [...chatNativeTranslate, chatter];
      let translateVal = await Translate(chatter, langCode, nativeCode);
      chatNativeTemp = [...chatNative, translateVal];
    }
    resolve({
      conversation: conversationTemp,
      chatNative: chatNativeTemp,
      chatNativeTranslate: chatNativeTranslateTemp,
      soundFile: soundFile,
    });
  });
}

function translateTitles(conversation) {
  let nativeLangCode = conversation.nativeLangCode;
  let titleKey = "title_" + nativeLangCode.replace("-", "");
  let title = conversation[titleKey];
  console.log("translateTitles: " + title);

  let translatePromises = [];
  for (let i = 0; i < UILangCodes.length; i++) {
    let targetLangCode = UILangCodes[i];
    if (targetLangCode === nativeLangCode) {
      continue;
    }
    translatePromises.push(
      TranslateWithMeta(title, nativeLangCode, targetLangCode)
    );
  }

  Promise.all(translatePromises).then((responses) => {
    let updateInput = {
      id: conversation.id,
    };

    for (const element of responses) {
      let response = element;
      let targetLangCode = response.language;
      let title = response.text;
      if (targetLangCode) {
        let titleKey = "title_" + targetLangCode.replace("-", "");
        //console.log("titleKey: " + titleKey);
        updateInput[titleKey] = title;
        //console.log("updateInput: " + JSON.stringify(updateInput, null, 2));
      } else {
        console.log("error translating " + JSON.stringify(response, null, 2));
      }
    }

    let result = API.graphql(
      graphqlOperation(updateConversation, {
        input: updateInput,
      })
    );
  });
}
