import { API, graphqlOperation, I18n } from "aws-amplify";
import { getPlace } from "../graphql/queries";

export async function getLocationByPrompt(prompt) {
  return new Promise(async (resolve) => {
    const promiseGetPlace = await API.graphql(
      graphqlOperation(getPlace, {
        prompt: prompt,
      })
    );
    let locationResponse = {};
    Promise.all([promiseGetPlace]).then(async (responses) => {
      try {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].data.getPlace) {
            const getPlaceFn = JSON.parse(responses[i].data.getPlace);
            const key = Object.keys(getPlaceFn)[0];
            locationResponse["getPlace"] = getPlaceFn;
          }
        }
      } catch (error) {
        alert(I18n.get("limit_reached") + "gl");
        return;
      }
      resolve(locationResponse);
    });
  });
}

export async function getLocationByPlaceId(placeId) {
  return new Promise(async (resolve) => {
    const promiseGetPlace = await API.graphql(
      graphqlOperation(getPlace, {
        placeId: placeId,
      })
    );
    let locationResponse = {};
    Promise.all([promiseGetPlace]).then(async (responses) => {
      //console.log("getLocationByPlaceId: " + JSON.stringify(responses, null, 2));
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].data.getPlace) {
          const getPlaceFn = JSON.parse(responses[i].data.getPlace);
          const key = Object.keys(getPlaceFn)[0];
          locationResponse["getPlace"] = getPlaceFn;
        }
      }
      resolve(locationResponse);
    });
  });
}
