/* src/CueCard.js */
import {
  Button,
  Card,
  Text,
  View,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { MenuBar } from "./commonfn/MenuBar";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import "@aws-amplify/ui-react/styles.css";
import { API, Analytics, I18n, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
  CiCircleAlert,
  CiCircleCheck,
  CiCircleChevRight,
  CiCircleMinus,
  CiRedo,
  CiStar,
} from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { updateCueCard } from "./graphql/mutations";
import { cueCardsByDate } from "./graphql/queries";

const CueCard = ({ signOut, user }) => {
  const [cuecards, setCuecards] = useState();
  const [cuecardIndex, setCuecardIndex] = useState(0);
  const [frontFacing, setFrontFacing] = useState(true);
  const [showPractice, setShowPractice] = useState(true);
  const navigate = useNavigate();

  // Hooks are called before the return statement on mount on unmount.
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8589250628943710"
    );
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", "true");
    document.head.appendChild(script);

    // To delete the script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!cuecards) {
      fetchCuecards();
    }
  }, [cuecards]);

  async function fetchCuecards() {
    try {
      const res = await API.graphql(
        graphqlOperation(cueCardsByDate, {
          active: "true",
          limit: 5000,
          sortDirection: "DESC",
        })
      );
      // console.log("res: " + JSON.stringify(res, null, 2));
      let cuecardsTemp = res.data.cueCardsByDate.items;
      cuecardsTemp = rankCuecards(cuecardsTemp);
      console.log("cuecards: " + JSON.stringify(cuecardsTemp, null, 2));
      setCuecards(cuecardsTemp);
    } catch (err) {
      console.log("error fetching cuecards", err);
    }
  }

  function rankCuecards(cuecardsTemp) {
    for (let i = 0; i < cuecardsTemp.length; i++) {
      let cuecard = cuecardsTemp[i];
      // Add a score to each cuecard
      let curTimestamp = Math.floor(Date.now() / 1000 / 60);

      let correctPercentage = 0.0;
      let correctTimestamps = [];
      let incorrectTimestamps = [];
      let lastSeenTimestamp = 0;

      if (cuecard.correctTimestamps) {
        correctTimestamps = cuecard.correctTimestamps;
        lastSeenTimestamp = correctTimestamps[0];
      }
      if (cuecard.incorrectTimestamps) {
        incorrectTimestamps = cuecard.incorrectTimestamps;
        if (incorrectTimestamps[0] > lastSeenTimestamp) {
          lastSeenTimestamp = incorrectTimestamps[0];
        }
      }

      let totalTimestamps =
        correctTimestamps.length + incorrectTimestamps.length;
      if (totalTimestamps > 0) {
        correctPercentage = correctTimestamps.length / totalTimestamps;
      }

      let favorited = 0;
      if (cuecard.bookmark) {
        favorited = 1;
      }

      let toughNutFactor = 0.0;
      if (
        cuecard.incorrectTimestamps &&
        cuecard.incorrectTimestamps.length > 3
      ) {
        let correctLength = 0;
        if (cuecard.correctTimestamps) {
          correctLength = cuecard.correctTimestamps.length;
        }
        toughNutFactor =
          (cuecard.incorrectTimestamps.length - correctLength) / 10;
      }

      console.log("correctPercentage: " + correctPercentage);
      console.log(
        "lastSeenPercentage: " + getLastSeenFactor(lastSeenTimestamp)
      );
      console.log("toughNutFactor: " + toughNutFactor);

      cuecard.score =
        favorited * 25 +
        (1 - correctPercentage) * 25 +
        getLastSeenFactor(lastSeenTimestamp) * 25 +
        toughNutFactor * 25;
    }
    // Sort by score
    cuecardsTemp.sort((a, b) => compareScores(a.score, b.score));
    return cuecardsTemp;
  }

  function compareScores(a, b) {
    return b - a;
  }

  function getLastSeenFactor(lastSeenTimestamp) {
    let curTimestamp = Math.floor(Date.now() / 1000 / 60);

    let lastSeenFactor = 0.0;

    // Cards seen in the last 12 hours get a major bump down
    if (curTimestamp - lastSeenTimestamp < 12 * 60 * 60) {
      lastSeenFactor = -(
        1 -
        (curTimestamp - lastSeenTimestamp) / (12 * 60 * 60)
      );
      if (lastSeenFactor < -1) {
        lastSeenFactor = -1;
      }
      return lastSeenFactor;
    }

    // Cards that haven't been seen the longest get a bump up
    lastSeenFactor = (curTimestamp - lastSeenTimestamp) / (30 * 24 * 60 * 60);
    if (lastSeenFactor > 1) {
      lastSeenFactor = 1;
    }
    return lastSeenFactor;
  }

  function answerCard(correctBool) {
    let cueCard = cuecards[cuecardIndex];
    let timestamps = cueCard.correctTimestamps;
    if (!correctBool) {
      timestamps = cueCard.incorrectTimestamps;
      Analytics.record({
        name: "cueCardAnsweredIncorrect",
        attributes: {
          nativeLangCode: cueCard.nativeLangCode,
          targetLangCode: cueCard.targetLangCode,
        },
      });
    } else {
      Analytics.record({
        name: "cueCardAnsweredCorrect",
        attributes: {
          nativeLangCode: cueCard.nativeLangCode,
          targetLangCode: cueCard.targetLangCode,
        },
      });
    }
    if (!timestamps) {
      timestamps = [];
    }
    // get timestamp to nearest minute
    let timestamp = Math.floor(Date.now() / 1000 / 60);
    timestamps = [timestamp, ...timestamps];
    console.log("timestamps: " + JSON.stringify(timestamps, null, 2));
    let cueInput = {
      id: cueCard.id,
    };
    if (correctBool) {
      cueInput.correctTimestamps = timestamps;
    } else {
      cueInput.incorrectTimestamps = timestamps;
    }
    let result = API.graphql(
      graphqlOperation(updateCueCard, {
        input: cueInput,
      })
    );
    next();
  }

  function next() {
    if (cuecardIndex < cuecards.length - 1) {
      let cuecardIndexTemp = cuecardIndex + 1;
      setCuecardIndex(cuecardIndexTemp);
    } else {
      setCuecardIndex(0);
    }
    setFrontFacing(true);
    Analytics.record({
      name: "cueCardNext",
    });
  }

  function archiveCard() {
    let cueCard = cuecards[cuecardIndex];
    let cueInput = {
      id: cueCard.id,
      active: "false",
    };
    let result = API.graphql(
      graphqlOperation(updateCueCard, {
        input: cueInput,
      })
    );
    next();
    Analytics.record({
      name: "cueCardArchive",
    });
  }

  function bookmarkCard() {
    let cueCard = cuecards[cuecardIndex];
    let cueInput = {
      id: cueCard.id,
      bookmark: !cueCard.bookmark,
    };
    let result = API.graphql(
      graphqlOperation(updateCueCard, {
        input: cueInput,
      })
    );
    next();
    Analytics.record({
      name: "cueCardBookmark",
    });
  }

  function getPracticeView() {
    return (
      <>
        {!cuecards || cuecards.length <= 0 ? (
          <View>{I18n.get("save_cue_card")}</View>
        ) : null}
        {cuecards && cuecards.length > 0 && frontFacing ? (
          <View className="col-6 col-s-10">
            <Card variation="outlined" style={styles.card}>
              <p>{cuecards[cuecardIndex].nativeText}</p>
              <Text style={styles.text}>
                ({I18n.get(cuecards[cuecardIndex].targetLangCode)})
              </Text>
              <StorageImage
                imgKey={cuecards[cuecardIndex].imageUrl}
                accessLevel="private"
              />
              <br />
              <Button
                style={styles.button}
                loadingText=""
                onClick={() => {
                  setFrontFacing(false);
                }}
              >
                <CiRedo size={30} /> {I18n.get("flip")}
              </Button>
              <Button
                style={styles.button}
                loadingText=""
                onClick={() => {
                  if (cuecardIndex < cuecards.length - 1) {
                    let cuecardIndexTemp = cuecardIndex + 1;
                    setCuecardIndex(cuecardIndexTemp);
                  } else {
                    setCuecardIndex(0);
                  }
                  setFrontFacing(true);
                }}
              >
                <CiCircleChevRight size={30} /> {I18n.get("next")}
              </Button>
              <Button
                style={styles.button}
                onClick={() => {
                  bookmarkCard();
                }}
              >
                <CiStar size={30} /> {I18n.get("favorite")}
              </Button>
              <Button
                style={styles.button}
                onClick={() => {
                  archiveCard();
                }}
              >
                <CiCircleMinus size={30} /> {I18n.get("archive")}
              </Button>
            </Card>
          </View>
        ) : // <View>
        //   {cuecards.map((cuecard, index) => (
        //     <div key={cuecard.id ? cuecard.id : index}>
        //       <a href="#" onClick={() => clickLoadCuecard(cuecard)}>
        //         {" "}
        //         {cuecard.id}{" "}
        //       </a>
        //     </div>
        //   ))}
        // </View>
        null}
        {cuecards && cuecards.length > 0 && !frontFacing ? (
          <View className="col-6 col-s-8">
            <Card variation="outlined" style={styles.card}>
              <p>{cuecards[cuecardIndex].targetText}</p>
              <Text>({I18n.get(cuecards[cuecardIndex].nativeLangCode)})</Text>
              <StorageImage
                imgKey={cuecards[cuecardIndex].imageUrl}
                accessLevel="private"
              />
              <br />
              <br />
              <Button
                style={styles.button}
                loadingText=""
                onClick={() => {
                  setFrontFacing(true);
                }}
              >
                <CiRedo size={30} /> {I18n.get("flip")}
              </Button>
              <Button
                style={styles.button}
                loadingText=""
                onClick={() => {
                  if (cuecardIndex < cuecards.length - 1) {
                    let cuecardIndexTemp = cuecardIndex + 1;
                    setCuecardIndex(cuecardIndexTemp);
                  } else {
                    setCuecardIndex(0);
                  }
                  setFrontFacing(true);
                }}
              >
                <CiCircleChevRight size={30} /> {I18n.get("next")}
              </Button>
              <Button
                style={styles.button}
                loadingText=""
                onClick={() => answerCard(true)}
              >
                <CiCircleCheck size={30} /> {I18n.get("correct")}
              </Button>
              <Button
                style={styles.button}
                loadingText=""
                onClick={() => answerCard(false)}
              >
                <CiCircleAlert size={30} /> {I18n.get("incorrect")}
              </Button>
            </Card>
          </View>
        ) : // <View>
        //   {cuecards.map((cuecard, index) => (
        //     <div key={cuecard.id ? cuecard.id : index}>
        //       <a href="#" onClick={() => clickLoadCuecard(cuecard)}>
        //         {" "}
        //         {cuecard.id}{" "}
        //       </a>
        //     </div>
        //   ))}
        // </View>
        null}
      </>
    );
  }

  function getListView() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell as="th">{I18n.get("native_language")} </TableCell>
            <TableCell as="th">{I18n.get("foreign_language")}</TableCell>
            <TableCell as="th">{I18n.get("language_code")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cuecards.map((cuecard) => (
            <TableRow key={cuecard.id}>
              <TableCell>{cuecard.nativeText}</TableCell>
              <TableCell>{cuecard.targetText}</TableCell>
              <TableCell>{cuecard.targetLangCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    // <View style={styles.container} className="col-7 col-s-12">
    <>
      <MenuBar />
      <View style={styles.container}>
        <Button
          isDisabled={showPractice}
          onClick={() => {
            setShowPractice(true);
          }}
        >
          {I18n.get("practice")}
        </Button>
        <Button
          isDisabled={!showPractice}
          onClick={() => {
            setShowPractice(false);
          }}
        >
          {I18n.get("list")}
        </Button>
        <p />
        {showPractice ? getPracticeView() : getListView()}
      </View>
    </>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    padding: 20,
  },

  card: {
    backgroundColor: "#FFF8DC",
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 10,
  },

  text: {
    justifyContent: "center",
  },

  button: {
    backgroundColor: "#D3D3D3",
    width: "45%",
    margin: "3px",
  },
};

export default withAuthenticator(CueCard);
