import { useState, useEffect } from "react";

function getOrientation() {
  return window.screen?.orientation?.type;
}

export function useScreenOrientation() {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = (event) => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return orientation;
}

//export default useScreenOrientation
