import { API, graphqlOperation, Storage, Auth, I18n } from "aws-amplify";
import { generateImage } from "../graphql/queries";

export async function CreateImage(
  model,
  quality,
  imagePrompt,
  imageUrl,
  imageResolution,
  privacyLevel
) {
  return new Promise(async (resolve) => {
    try {
      console.log("CreateImage1: " + imagePrompt);

      let identityIdTemp = "";
      if (privacyLevel === "private") {
        const info = await Auth.currentCredentials();
        console.log(
          "Auth.currentCredentials(): " + JSON.stringify(info, null, 2)
        );
        identityIdTemp = info.identityId;
      }

      if (imagePrompt.length > 500) {
        imagePrompt = imagePrompt.substring(0, 500);
      }

      try {
        const result = await API.graphql(
          graphqlOperation(generateImage, {
            prompt: JSON.stringify({
              model: model,
              prompt: imagePrompt,
              quality: quality,
              n: 1,
              size: imageResolution,
            }),
            identityId: identityIdTemp,
            key: imageUrl,
          })
        );
      } catch (error) {
        alert(I18n.get("limit_reached") + "ci");
        return;
      }

      console.log("CreateImage2: " + imageUrl);
      // const imageData = result.data.generateImage;
      // Storage.put(imageUrl, base64toBlob(imageData, "image/png"), {
      //   contentType: "image/png",
      //   level: privacyLevel,
      // });

      resolve("");
    } catch (err) {
      resolve("");
    }
  });

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
