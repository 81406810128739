/* src/Delete.js */
import { View, withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { API, Storage, graphqlOperation } from "aws-amplify";
import React, { useEffect } from "react";
import { listConversations, listPublicConversations, listCueCards } from "./graphql/queries";
import { deleteConversation, deletePublicConversation, deleteCueCard } from "./graphql/mutations";
import { UILangCodes } from "./commonfn/CountryCodes";

const Delete = ({ signOut, user }) => {
  useEffect(() => {
    deleteConversationHelper();
  }, []);

  async function deleteConversationHelper() {
    try {
      const res = await API.graphql(graphqlOperation(listConversations));
      console.log("res: " + JSON.stringify(res, null, 2));
      const conversationsTemp = res.data.listConversations.items;
      conversationsTemp.map(async (conversationTemp) => {
        API.graphql(
          graphqlOperation(deleteConversation, {
            input: {
              id: conversationTemp.id,
            },
          })
        );
      });

      const res2 = await API.graphql(graphqlOperation(listCueCards));
      const cueCards = res2.data.listCueCards.items;
      cueCards.map(async (cueCard) => {
        API.graphql(
          graphqlOperation(deleteCueCard, {
            input: {
              id: cueCard.id,
            },
          })
        );
      });
    } catch (err) {
      console.log("error fetching ", err);
    }

    const res3 = await API.graphql(graphqlOperation(listPublicConversations));
    console.log("res: " + JSON.stringify(res3, null, 2));
    const conversationsTemp2 = res3.data.listPublicConversations.items;
    conversationsTemp2.map(async (conversationTemp2) => {
      API.graphql(
        graphqlOperation(deletePublicConversation, {
          input: {
            id: conversationTemp2.id,
          },
        })
      );
    });

    const res4 = await Storage.list('', {
      level: "private",
    });
    console.log("private res: " + JSON.stringify(res4, null, 2));
    for (let i = 0; i < res4.results.length; i++) {
      console.log("res[i].key: " + res4.results[i].key);
      await Storage.remove(res4.results[i].key, {
        level: "private",
      });
    }

    const res5 = await Storage.list('', {
      level: "public",
    });
    //console.log("res: " + JSON.stringify(res, null, 2));
    for (let i = 0; i < res5.results.length; i++) {
      console.log("res[i].key: " + res5.results[i].key);
      await Storage.remove(res5.results[i].key, {
        level: "public",
      });
    }
  }

  async function deleteConversationStorage(conversationId, targetLanguageTemp) {
    try {
      const res = await Storage.list(conversationId + "/", {
        level: "private",
      });
      //console.log("res: " + JSON.stringify(res, null, 2));
      for (let i = 0; i < res.results.length; i++) {
        console.log("res[i].key: " + res.results[i].key);
        await Storage.remove(res.results[i].key, {
          level: "private",
        });
      }

    //   UILangCodes.map(async (nativeLanguageCode) => {
    //     try {
    //       await Storage.remove(
    //         conversationId + "/chatNative_" + nativeLanguageCode + ".json",
    //         {
    //           level: "private",
    //         }
    //       );

    //       await Storage.remove(
    //         conversationId +
    //           "/chitTarget_" +
    //           nativeLanguageCode +
    //           ".json",
    //         {
    //           level: "private",
    //         }
    //       );
    //     } catch (err) {
    //       //console.log("error deleting conversation: " + err);
    //     }
    //   });

    //   await Storage.remove(
    //     conversationId + "/chatNativeTranslate_" + targetLanguageTemp + ".json",
    //     {
    //       level: "private",
    //     }
    //   );

    //   await Storage.remove(
    //     conversationId + "/chitTargetTranslate_" + targetLanguageTemp + ".json",
    //     {
    //       level: "private",
    //     }
    //   );
    // } catch (err) {
    //   console.log("error deleting conversation: " + err);
    // }

    // try {
    //   let soundCounter = 1;
    //   while (true) {
    //     await Storage.remove(conversationId + "/" + soundCounter + ".mp4", {
    //       level: "private",
    //     });
    //     soundCounter++;
    //   }
    // } catch (err) {
    //   console.log("error deleting sound: " + err);
    // }

    // for (let imageCounter = 0; imageCounter < ch; imageCounter++)
    // try {
    //     await Storage.remove(conversationId + "/" + imageCounter + ".jpg", {
    //       level: "private",
    //     });
    } catch (err) {
      console.log("error deleting conversation: " + err);
    }
  }

  async function deleteCueCardStorage(imageUrl) {
    try {
      await Storage.remove(imageUrl, {
        level: "private",
      });
    } catch (err) {
      console.log("error deleting cue card: " + err);
    }
  }

  return <View>Done</View>;
};
export default withAuthenticator(Delete);