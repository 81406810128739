/* src/YouSpeak.js */
import {
  Button,
  Flex,
  View,
  withAuthenticator,
  Text,
} from "@aws-amplify/ui-react";
import { MenuBar } from "./commonfn/MenuBar";
import "@aws-amplify/ui-react/styles.css";
import { useWhisper } from "@chengsokdara/use-whisper";
import {
  API,
  Analytics,
  I18n,
  graphqlOperation,
  Auth,
  Storage,
} from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { CiRedo, CiVolumeHigh } from "react-icons/ci";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GeoView } from "./GeoView";
import { chatAI } from "./chat/ChatAI";
import { chatHuman } from "./chat/ChatHuman";
import { chatInit } from "./chat/ChatInit";
import { chatIter } from "./chat/ChatIter";
import { ConversationLog } from "./commonfn/ConversationLog";
import { LanguageContext } from "./commonfn/LanguageContext";
import { PlayTextAudio, StopAudio } from "./commonfn/PlayAudio";
import { publishConversation } from "./commonfn/Publish";
import { useScreenOrientation } from "./commonfn/useScreenOrientation";
import { useWindowDimensions } from "./commonfn/useWindowDimensions";
import { speechToText } from "./graphql/mutations";

/* src/YouSpeak.js */
const YouSpeak = ({ signOut, user }) => {
  const [queryParameters] = useSearchParams();
  const nativeCode = useContext(LanguageContext);
  const [converseLang, setConverseLang] = useState("");
  const [converseState, setConverseState] = useState("pause");
  const [targetCode, setTargetCode] = useState("");
  const [placeInfo, setPlaceInfo] = useState();
  const [placeId, setPlaceId] = useState("");
  const [targetLanguageLabel, setTargetLanguageLabel] = useState("");

  const [dialectCode, setDialectCode] = useState("");
  const [textLength, setTextLength] = useState("");
  const [storyMode, setStoryMode] = useState("");

  const [identityId, setIdentityId] = useState("");

  const [promptVal, setPromptVal] = useState([]);
  const [converseNativeButtonLabel, setConverseNativeButtonLabel] = useState(
    I18n.get("speak_native")
  );
  const [converseTargetButtonLabel, setConverseTargetButtonLabel] =
    useState("");

  const [chatNative, setChatNative] = useState([]);
  const [chatNativeTranslate, setChatNativeTranslate] = useState([]);
  const [chitTarget, setChitTarget] = useState([]);
  const [chitTargetTranslate, setChitTargetTranslate] = useState([]);

  const [conversation, setConversation] = useState("");
  const [metadataInit, setMetadataInit] = useState("");
  const [metadataIter, setMetadataIter] = useState("");

  const [images, setImages] = useState([]);
  const [imageStyle, setImageStyle] = useState("vector artwork");

  const { height, width } = useWindowDimensions();
  const screenOrientation = useScreenOrientation();
  const navigate = useNavigate();

  // Hooks are called before the return statement on mount on unmount.
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8589250628943710"
    );
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", "true");
    document.head.appendChild(script);

    // To delete the script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    console.log("native code changed..." + nativeCode);
    setConverseNativeButtonLabel(I18n.get("speak_native"));
    setConverseLang(nativeCode);
  }, [nativeCode]);

  useEffect(() => {
    console.log("converseLang changed..." + nativeCode + " | " + converseLang);
    if (converseLang === "") {
      setConverseNativeButtonLabel(I18n.get("speak_native"));
      setConverseLang(nativeCode);
    }
  }, [converseLang]);

  useEffect(() => {
    return () => {
      console.log("youspeak unload...");
      StopAudio();
    };
  }, [conversation]);

  useEffect(() => {
    getIdentityId();
  }, [identityId]);

  async function getIdentityId() {
    const info = await Auth.currentCredentials();
    setIdentityId(info.identityId);
  }

  useEffect(() => {
    if (
      queryParameters.get("placeId") &&
      queryParameters.get("placeId") !== ""
    ) {
      setPlaceId(queryParameters.get("placeId"));
    }
    if (queryParameters.get("prompt") && queryParameters.get("prompt") !== "") {
      setChatNative([]);
      setChatNativeTranslate([]);
      setChitTarget([]);
      setChitTargetTranslate([]);
      chatHelper(queryParameters.get("prompt"), converseLang, []);
    }
  }, [queryParameters]);

  function recordVoice(langCode) {
    console.log("recordVoice: " + converseState);
    setConverseLang(removeDash(langCode));
    if (converseState === "pause") {
      //setConverseNativeButtonLabel(I18n.get("initialize"));
      startRecording();
      if (langCode === nativeCode) {
        setConverseNativeButtonLabel(I18n.get("stop_recording"));
      } else {
        setConverseTargetButtonLabel(I18n.get("stop_recording"));
      }
      setConverseState("recording");
    } else if (converseState === "recording") {
      stopRecording();
      if (langCode === nativeCode) {
        setConverseNativeButtonLabel(I18n.get("processing"));
        Analytics.record({
          name: "speak",
          attributes: { nativeLangCode: nativeCode },
        });
      } else {
        setConverseTargetButtonLabel(I18n.get("processing"));
        Analytics.record({
          name: "speak",
          attributes: { targetLangCode: targetCode },
        });
      }
      setConverseState("processing");
    }
  }

  const onTranscribe = async (blob) => {
    try {
      // const base64 = await new Promise((resolve) => {
      //   const reader = new FileReader();
      //   reader.onloadend = () => resolve(reader.result);
      //   reader.readAsDataURL(blob);
      // });
      //const body = JSON.stringify({ file: base64, model: "whisper-1" });
      const result2 = await Storage.put("audioprompt.mp3", blob, {
        level: "private",
      });
      console.log("result2: " + JSON.stringify(result2, null, 2));
      console.log("identityId: " + identityId);
      let t = Date.now();
      let result = await API.graphql(
        graphqlOperation(speechToText, {
          language: converseLang,
          //soundFile: base64,
          identityId: identityId,
        })
      );
      console.log("transcribe time: " + (Date.now() - t));
      let textResult = result.data.speechToText;
      console.log("textResult: " + textResult);

      chatHelper(textResult, converseLang, blob);
    } catch (error) {
      alert(I18n.get("limit_reached") + "st");
      return;
    }

    setConverseNativeButtonLabel(I18n.get("speak_native"));
    setConverseTargetButtonLabel(I18n.get(dialectCode));
    setConverseState("pause");
  };

  const { startRecording, stopRecording } = useWhisper({
    whisperConfig: {
      language: converseLang,
      removeSilence: true,
    },
    onTranscribe,
  });

  function removeDash(text) {
    if (text.indexOf("-") > 0) {
      return text.substring(0, text.indexOf("-"));
    }
    return text;
  }

  function changeTargetLanguage(dialectCodeTemp, textCodeTemp) {
    setTargetCode(textCodeTemp);
    setConverseLang(removeDash(textCodeTemp));
    setTargetLanguageLabel(I18n.get(dialectCodeTemp));
    setConverseTargetButtonLabel(I18n.get(dialectCodeTemp));
  }

  async function publishConversationHelper() {
    let imagePrompt =
      "Create an image of the conversation in vector artwork style. ";
    for (let i = 0; i < chitTargetTranslate.length; i++) {
      imagePrompt += chatNativeTranslate[i] + ". ";
      imagePrompt += chitTargetTranslate[i] + ". ";
    }
    await publishConversation(conversation, imagePrompt, placeInfo);
    navigate("/");
  }

  async function chatHelper(chatter, langCode, humanSound) {
    let dialectCodeTemp = dialectCode;
    let textCodeTemp = targetCode;
    let storyModeTemp = storyMode;
    let textLengthTemp = textLength;

    Analytics.record({
      name: "chat",
      attributes: {},
      metrics: { numChat: chatNative.length + 1 },
    });

    if (chatNative.length <= 0) {
      let responsesInit = await chatInit(chatter, langCode);
      console.log("responsesInit: " + JSON.stringify(responsesInit, null, 2));
      setMetadataInit(JSON.stringify(responsesInit, null, 2));
      dialectCodeTemp = responsesInit.dialectCode;
      textCodeTemp = responsesInit.textCode;
      if (responsesInit.wants_story) {
        storyModeTemp = responsesInit.wants_story;
        setStoryMode(responsesInit.wants_story);
      }
      if (responsesInit.response_length) {
        textLengthTemp = responsesInit.response_length;
        setTextLength(responsesInit.response_length);
      }
      if (dialectCodeTemp === "" || textCodeTemp === "") {
        setChatNative([chatter]);
        setChitTarget([I18n.get("no_language_found")]);
        return;
      }

      console.log("dialectCodeTemp: " + dialectCodeTemp);
      console.log("textCodeTemp: " + textCodeTemp);
      changeTargetLanguage(dialectCodeTemp, textCodeTemp);
      setDialectCode(dialectCodeTemp);
    }

    let chatHumanResponse = await chat2(
      chatter,
      langCode,
      dialectCodeTemp,
      textCodeTemp,
      humanSound
    );

    chat3(
      chatHumanResponse.chatNative,
      chatHumanResponse.chatNativeTranslate,
      dialectCodeTemp,
      textCodeTemp,
      chatHumanResponse.conversation,
      chatHumanResponse.soundFile,
      storyModeTemp,
      textLengthTemp
    );
  }

  async function chat2(
    chatter,
    langCode,
    dialectCodeTemp,
    targetLanguageTemp,
    humanSound
  ) {
    return new Promise(async (resolve) => {
      let chatHumanResponse = await chatHuman(
        chatNative,
        chatNativeTranslate,
        conversation,
        chatter,
        langCode,
        nativeCode,
        dialectCodeTemp,
        targetLanguageTemp,
        humanSound
      );

      setConversation(chatHumanResponse.conversation);
      setChatNative(chatHumanResponse.chatNative);
      setChatNativeTranslate(chatHumanResponse.chatNativeTranslate);

      if (
        langCode === nativeCode &&
        chatHumanResponse.chatNativeTranslate.length > 1
      ) {
        let aiSound = await PlayTextAudio(
          chatHumanResponse.chatNativeTranslate[
            chatHumanResponse.chatNativeTranslate.length - 1
          ],
          dialectCodeTemp,
          false
        );
        chatHumanResponse.soundFile = aiSound;
      }

      resolve(chatHumanResponse);
    });
  }

  async function chat3(
    chatNativeTemp,
    chatNativeTranslateTemp,
    dialectCodeTemp,
    targetLanguageTemp,
    conversationTemp,
    humanSound,
    storyMode,
    textLength
  ) {
    let shortCode = "en";
    if (dialectCodeTemp.indexOf("-") > 0) {
      shortCode = dialectCodeTemp.substring(0, dialectCodeTemp.indexOf("-"));
    }
    let speakLanguageLabel = I18n.get(shortCode);

    console.log(
      "chat3: " +
        dialectCodeTemp +
        " " +
        targetLanguageTemp +
        " " +
        speakLanguageLabel
    );
    let chatAIResponse = await chatAI(
      nativeCode,
      chatNativeTemp,
      chatNativeTranslateTemp,
      chitTarget,
      chitTargetTranslate,
      dialectCodeTemp,
      targetLanguageTemp,
      conversationTemp,
      humanSound,
      storyMode,
      textLength,
      placeInfo,
      speakLanguageLabel
    );

    setChitTarget(chatAIResponse.chitTarget);
    setChitTargetTranslate(chatAIResponse.chitTargetTranslate);

    let imageIndex = images.length;
    setImages([...images, null]);
    let responsesIter = await chatIter(
      chatNativeTranslateTemp,
      chitTargetTranslate,
      chatNativeTemp[0]
    );
    // if (responsesIter.genImageUrl) {
    //   let imageUrl = responsesIter.genImageUrl;
    //   images[imageIndex] = imageUrl;
    //   setImages(images);
    //   Storage.put(conversationTemp.id + "/" + imageIndex + ".png", imageUrl, {
    //     level: "private",
    //   });
    // }
    setMetadataIter(JSON.stringify(responsesIter, null, 2));
    console.log("responsesIter: " + JSON.stringify(responsesIter, null, 2));
  }

  // <Text>{metadataInit}</Text>
  // <Text>{metadataIter}</Text>
  // <Text>{metadataPlace}</Text>
  return (
    <>
      <MenuBar />
      <Flex direction={{ base: "column", large: "row" }}>
        <View width="100%" style={styles.container} className="col-7 col-s-12">
          {chatNative.length <= 0 ? (
            <Text>{I18n.get("speak_help")}</Text>
          ) : <button type="button" onClick={ () => window.location.reload() }><CiRedo/></button>}
          {/* <View>{location}</View> */}
           
          <ConversationLog
            chatNative={chatNative}
            chatNativeTranslate={chatNativeTranslate}
            chitTarget={chitTarget}
            chitTargetTranslate={chitTargetTranslate}
            targetLanguageLabel={targetLanguageLabel}
            images={images}
            showTryThis={false}
            nativeCode={nativeCode}
            targetCode={targetCode}
            dialectCode={dialectCode}
          />
          <View>
            <p />
            <Button
              style={styles.button}
              size="large"
              onClick={() => recordVoice(nativeCode)}
            >
              <CiVolumeHigh size={30} /> {converseNativeButtonLabel}
            </Button>
            {chatNativeTranslate.length > 0 ? (
              <Button
                style={styles.button}
                size="large"
                onClick={() => recordVoice(targetCode)}
              >
                <CiVolumeHigh size={30} /> {converseTargetButtonLabel}
              </Button>
            ) : null}
            {/* <p />
            {chatNativeTranslate.length > 0 ? (
              <Button
                style={styles.button}
                size="large"
                onClick={() => publishConversationHelper()}
              >
                <CiSaveUp2 size={30} /> {I18n.get("publish")}
              </Button>
            ) : null} */}
          </View>
        </View>
        <View style={styles.map} width="100%">
          {chatNative.length > 0 ? (
            <GeoView
              placeId={placeId}
              prompt={chatNative[0]}
              onPlaceInfo={(placeInfoTemp) => {
                setPlaceInfo(placeInfoTemp);
              }}
            />
          ) : null}
        </View>
      </Flex>
    </>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    padding: 10,
  },

  map: {
    padding: 20,
  },

  button: {
    backgroundColor: "#D3D3D3",
    width: "170px",
    margin: "3px",
  },
};

export default withAuthenticator(YouSpeak);
