/* src/Explore.js */
import {
  Button,
  Flex,
  Text,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { MenuBar } from "./commonfn/MenuBar";
import "@aws-amplify/ui-react/styles.css";
import { useWhisper } from "@chengsokdara/use-whisper";
import {
  API,
  Analytics,
  Auth,
  I18n,
  Storage,
  graphqlOperation,
} from "aws-amplify";
import React, { useContext, useState, useEffect } from "react";
import { CiVolumeHigh } from "react-icons/ci";
import { GeoView } from "./GeoView";
import { LanguageContext } from "./commonfn/LanguageContext";
import { speechToText } from "./graphql/mutations";

/* src/Explore.js */
const Explore = ({ signOut, user }) => {
  const [placeLatLng, setPlaceLatLng] = useState({
    lat: 48.87157630000001,
    lng: 2.3005334,
  });
  const [prompt, setPrompt] = useState();
  const nativeCode = useContext(LanguageContext);
  const [converseState, setConverseState] = useState("pause");
  const [converseNativeButtonLabel, setConverseNativeButtonLabel] = useState(
    I18n.get("speak_native")
  );
  const [identityId, setIdentityId] = useState("");

    // Hooks are called before the return statement on mount on unmount.
    useEffect(() => {
      const script = document.createElement('script')
      script.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8589250628943710");
      script.setAttribute("crossorigin", "anonymous");
      script.setAttribute("async", "true");
      document.head.appendChild(script)
  
      // To delete the script on unmount
      return () => {
        document.head.removeChild(script)
      }
    }, [])

  useEffect(() => {
    getIdentityId();
  }, [identityId]);

  async function getIdentityId() {
    const info = await Auth.currentCredentials();
    setIdentityId(info.identityId);
  }

  useEffect(() => {
    console.log("native code changed..." + nativeCode);
    setConverseNativeButtonLabel(I18n.get("speak_native"));
  }, [nativeCode]);

  function recordVoice() {
    console.log("recordVoice: " + converseState);
    if (converseState === "pause") {
      startRecording();
      setConverseNativeButtonLabel(I18n.get("stop_recording"));
      setConverseState("recording");
    } else if (converseState === "recording") {
      stopRecording();
      setConverseNativeButtonLabel(I18n.get("processing"));
      Analytics.record({
        name: "explore",
      });
      setConverseState("processing");
    }
  }

  const onTranscribe = async (blob) => {
    try {
      // const base64 = await new Promise((resolve) => {
      //   const reader = new FileReader();
      //   reader.onloadend = () => resolve(reader.result);
      //   reader.readAsDataURL(blob);
      // });
      const result2 = await Storage.put("audioprompt.mp3", blob, {
        level: "private",
      });
      console.log("result2: " + JSON.stringify(result2, null, 2));
      //const body = JSON.stringify({ file: base64, model: "whisper-1" });
      let t = Date.now();
      let result = await API.graphql(
        graphqlOperation(speechToText, {
          language: nativeCode.substr(0, 2),
          //soundFile: base64,
          identityId: identityId,
        })
      );
      console.log("transcribe time: " + (Date.now() - t));
      let textResult = result.data.speechToText;
      console.log("textResult: " + textResult);

      setPrompt(textResult);
    } catch (error) {
      alert(I18n.get("limit_reached") + 'st');
      return;
    }
    setConverseNativeButtonLabel(I18n.get("speak_native"));
    setConverseState("pause");
  };

  const { startRecording, stopRecording } = useWhisper({
    whisperConfig: {
      language: nativeCode.substr(0, 2),
      removeSilence: true,
    },
    onTranscribe,
  });

  return (
    <>
      <MenuBar />
      <Flex direction={{ base: "column", large: "row" }}>
        <View width="100%" style={styles.container} className="col-7 col-s-12">
          <Text>{I18n.get("explore_help")}</Text>
          <br />
          <Button
            style={styles.button}
            size="large"
            onClick={() => recordVoice()}
          >
            <CiVolumeHigh size={30} /> {converseNativeButtonLabel}
          </Button>
          <p />
          <GeoView prompt={prompt} displayTryThis={true} />
        </View>
        <View width="50%"></View>
      </Flex>
    </>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    padding: 10,
  },

  map: {
    padding: 20,
  },

  button: {
    backgroundColor: "#D3D3D3",
    width: "170px",
    margin: "3px",
  },
};

export default withAuthenticator(Explore);
