/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "97a31feaa1f3400b81f35d194b581786",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "97a31feaa1f3400b81f35d194b581786",
            "region": "us-east-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://zgt2cv37enf7vl5ss67daff5zm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-whnt6xxk65e3npa66477z7ewwa",
    "aws_cognito_identity_pool_id": "us-east-2:c0f4144f-673d-48d4-819f-b05a2e2fddb4",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_TMslwSfDv",
    "aws_user_pools_web_client_id": "o2lmck1bpfprcere6qd47bq6g",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "reactamplifiedd974b-christiana",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "us-east-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Salli",
                    "LanguageCode": "en-US"
                }
            },
            "transcription": {
                "region": "us-east-2",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            },
            "translateText": {
                "region": "us-east-2",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "fr",
                    "targetLanguage": "en"
                }
            }
        }
    }
};


export default awsmobile;
