import { Storage } from "aws-amplify";

export function updateStorage(
  conversation,
  chatNativeTemp,
  chatNativeTranslateTemp,
  chitTargetTemp,
  chitTargetTranslateTemp,
  chatSound,
  chitSound,
  nativeCode,
  targetLanguageTemp
) {
    let conversationTemp = conversation;
    let soundCounter =
      chatNativeTranslateTemp.length + chitTargetTranslateTemp.length - 1;

    Storage.put(conversationTemp.id + "/" + soundCounter++ + ".mp4", chatSound, {
      level: "private",
    });

    Storage.put(conversationTemp.id + "/" + soundCounter++ + ".mp4", chitSound, {
      level: "private",
    });

    Storage.put(
      conversationTemp.id + "/chatNative_" + nativeCode + ".json",
      { data: chatNativeTemp },
      {
        level: "private",
        contentType: "application/json",
      }
    );

    if (targetLanguageTemp) {
      Storage.put(
        conversationTemp.id +
          "/chatNativeTranslate_" +
          targetLanguageTemp +
          ".json",
        { data: chatNativeTranslateTemp },
        {
          level: "private",
        }
      );

      Storage.put(
        conversationTemp.id + "/chitTarget_" + nativeCode + ".json",
        { data: chitTargetTemp },
        {
          level: "private",
          contentType: "application/json",
        }
      );
    }

    Storage.put(
      conversationTemp.id + "/chitTargetTranslate_" + targetLanguageTemp + ".json",
      { data: chitTargetTranslateTemp },
      {
        level: "private",
      }
    );
}
