// context.js
import { createContext } from 'react';

//export const SettingContext = createContext({});

// export const SettingContext = React.useState(() =>
//     sourceData.map((item, rid) => ({
//       ...item,
//       rid,
//       date: new Date(Date.parse(item.date)),
//       canceled: !!item.canceled,
//       balance: 0
//     }))
//   );

export const SettingContext = createContext({
    settings: {},
    setSettings: () => {},
  });