export function getOpenAIMessages(chatNativeTranslate, chitTargetTranslate, systemMessage) {
    let messages = [];
    messages.push({ role: "system", content: systemMessage });
    for (let i = 0; i < chitTargetTranslate.length; i++) {
      messages.push({ role: "assistant", content: chitTargetTranslate[i] });
      if (i + 1 < chatNativeTranslate.length) {
        messages.push({ role: "user", content: chatNativeTranslate[i + 1] });
      }
    }
    return messages;
  }