/* src/History.js */
import { Text, Image, View, withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify";
import React, { useContext } from "react";
import { LanguageContext } from "./commonfn/LanguageContext";
import { useNavigate } from "react-router-dom";
import { SettingContext } from "./commonfn/SettingContext";

const Login = ({ signOut, user }) => {
  const nativeCode = useContext(LanguageContext);
  const navigate = useNavigate();
  const { settings, setSettings } = useContext(SettingContext);

  return (
    <View style={styles.container} width="100%">
      <Text style={styles.tutorial}>
        <button
          onClick={() => {
            setSettings({
              ...settings,
              loggedIn: true,
            });
            navigate("/explore");
          }}
        >
          {I18n.get("explore")}
        </button>
      </Text>
    </View>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    marginLeft: 20,
    //marginRight: 20,
  },
  tutorial: {
    padding: 50,
    width: "50%",
  },
  signOut: {
    float: "right",
    marginRight: 0,
  },
};

export default withAuthenticator(Login);
