import { Predictions } from "aws-amplify";

export async function Translate(prompt, srcLang, targetLang) {
  return Predictions.convert({
    translateText: {
      source: {
        text: prompt,
        language: srcLang, // defaults configured on aws-exports.js
        // supported languages https://docs.aws.amazon.com/translate/latest/dg/how-it-works.html#how-it-works-language-codes
      },
      targetLanguage: targetLang,
    },
  })
    .then((result) => {
      return result.text;
    })
    .catch((err) => {
      console.log("translate error translating: " + err);
      return JSON.stringify(err, null, 2);
    });
}

export async function TranslateWithMeta(prompt, srcLang, targetLang) {
  return Predictions.convert({
    translateText: {
      source: {
        text: prompt,
        language: srcLang, // defaults configured on aws-exports.js
        // supported languages https://docs.aws.amazon.com/translate/latest/dg/how-it-works.html#how-it-works-language-codes
      },
      targetLanguage: targetLang,
    },
  })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("translate error translating: " + err);
      return JSON.stringify(err, null, 2);
    });
}
