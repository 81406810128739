import {
  Button,
  Divider,
  Image,
  Text,
  View,
  Card,
} from "@aws-amplify/ui-react";
import { Analytics, I18n } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { CiPlay1, CiTextAlignJustify } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { CreateCueCard } from "./CreateCueCard";

export function ConversationLog({
  chatNative,
  chatNativeTranslate,
  chitTarget,
  chitTargetTranslate,
  targetLanguageLabel,
  images,
  showTryThis = false,
  nativeCode,
  targetCode,
  dialectCode,
}) {
  const navigate = useNavigate();
  const [textNativeState, setTextNativeState] = useState(true);
  const [textNativeButtonLabel, setTextNativeButtonLabel] = useState("");
  const [textTargetState, setTextTargetState] = useState(true);
  const [textTargetButtonLabel, setTextTargetButtonLabel] = useState("");
  const [cueCard, setCueCard] = useState();

  useEffect(() => {
    setTextNativeButtonLabel(I18n.get("hide_native_text"));
    setTextTargetButtonLabel(targetLanguageLabel);
    //}, [targetLanguageLabel, textNativeState, textTargetState, chatNative, chatNativeTranslate, chitTarget, chitTargetTranslate, textNativeState, textTargetState]);
  }, [targetLanguageLabel]);

  const conversationEndRef = useRef(null);

  const scrollToBottom = () => {
    conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (chitTargetTranslate && chitTargetTranslate.length > 0) {
      setTimeout(scrollToBottom, 100);
    }
  }, [chitTargetTranslate, images]);

  function clickNativeText() {
    if (textNativeState) {
      setTextNativeState(false);
      setTextNativeButtonLabel(I18n.get("show_native_text"));
      Analytics.record({
        name: "showNativeText",
      });
    } else {
      setTextNativeState(true);
      setTextNativeButtonLabel(I18n.get("hide_native_text"));
      Analytics.record({
        name: "hideNativeText",
      });
    }
  }

  function clickTargetText() {
    if (textTargetState) {
      setTextTargetState(false);
      setTextTargetButtonLabel(targetLanguageLabel);
      Analytics.record({
        name: "showTargetText",
      });
    } else {
      setTextTargetState(true);
      setTextTargetButtonLabel(targetLanguageLabel);
      Analytics.record({
        name: "hideTargetText",
      });
    }
  }

  function showCueCard(cueCardText) {
    if (cueCardText && cueCardText !== "") {
      setCueCard(
        <View className="col-6 col-s-10">
          <Card variation="outlined" style={styles.card}>
            <p>{cueCardText}</p>
            <Button
              loadingText=""
              onClick={async () => {
                CreateCueCard(nativeCode, targetCode, dialectCode, cueCardText);
                dismissCueCard();
                Analytics.record({
                  name: "cueCardCreate",
                  attributes: {
                    nativeLangCode: nativeCode,
                    targetLangCode: targetCode,
                  },
                });
              }}
            >
              {I18n.get("add_cue_card")}
            </Button>
            <Button loadingText="" onClick={() => dismissCueCard()}>
              {I18n.get("dismiss")}
            </Button>
          </Card>
        </View>
      );
    }
  }

  function dismissCueCard() {
    setCueCard();
    var sel = window.getSelection ? window.getSelection() : document.selection;
    if (sel) {
      if (sel.removeAllRanges) {
        sel.removeAllRanges();
      } else if (sel.empty) {
        sel.empty();
      }
    }
  }

  function handleMouseUp() {
    showCueCard(window.getSelection().toString());
  }

  async function updateSubtitleSettings(langCodeTemp) {
    // changeLanguageCallback(langCodeTemp);
    // if (!settings || settings.length === 0) {
    //   let result = await API.graphql(
    //     graphqlOperation(createSetting, {
    //       input: {
    //         nativeLangCode: langCodeTemp,
    //       }
    //     })
    //   );
    // } else {
    //   let result = await API.graphql(
    //     graphqlOperation(updateSetting, {
    //       input: {
    //         id: settings.id,
    //         nativeLangCode: langCodeTemp,
    //       }
    //     })
    //   );
    //   setSettings(settings);
    //}
  }

  function getSections(tagName, text) {
    let sectionView = [];
    let sections = text.split("\n");
    for (let i = 0; i < sections.length; i++) {
      sections[i] = sections[i];
      sectionView.push(<Text key={tagName + "sec" + i}>{sections[i]}</Text>);
      if (i > 0) {
        sectionView.push(<br key={tagName + "b1" + i} />);
        //sectionView.push(<br key={tagName + 'b2' + i} />);
      }
    }
    return sectionView;
  }

  let chatArr = [];

  let keyIndex = 0;
  if (chatNative) {
    for (let i = 0; i < chatNative.length; i++) {
      if (i >= chatNativeTranslate.length) {
        chatArr.push(<div ref={conversationEndRef} />);
      }
      if (chatNativeTranslate[i]) {
        if (i > 0 && textTargetState) {
          chatArr.push(
            <Text key={keyIndex++} fontStyle="bold">
              {chatNativeTranslate[i]}
            </Text>
          );
        }
      }
      if (chatNative[i]) {
        if (i === 0) {
          chatArr.push(<Text key={keyIndex++}>{chatNative[i]}</Text>);
        } else if (textNativeState) {
          chatArr.push(<Divider key={keyIndex++} orientation="horizontal" />);
          chatArr.push(
            <Text
              // style={{ borderTop: "1px solid", width: "500px" }}
              key={keyIndex++}
            >
              {chatNative[i]}
            </Text>
          );
        }
      }
      chatArr.push(<p key={keyIndex++} />);
      if (chitTargetTranslate[i]) {
        if (textTargetState) {
          chatArr.push(
            <Text as="span" key={keyIndex++} fontStyle="bold">
              {getSections("chitTargetTranslate", chitTargetTranslate[i])}
            </Text>
          );
        }
      }
      if (chitTarget[i]) {
        if (textNativeState) {
          chatArr.push(<Divider orientation="horizontal" key={keyIndex++} />);
          chatArr.push(
            <Text as="span" key={keyIndex++}>
              {getSections("chitTarget", chitTarget[i])}
            </Text>
          );
        }
      }
      chatArr.push(<p key={keyIndex++} />);

      if (images[i]) {
        chatArr.push(
          <View key={keyIndex++} className="col-7 col-s-8">
            <Image
              key={keyIndex++}
              src={`data:image/png;base64,${images[i]}`}
            />
          </View>
        );
      }
    }
  }

  return (
    <View
      style={styles.container}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
    >
      {showTryThis ? (
        <Button
          style={styles.button}
          size="small"
          onClick={() => navigate("/speak?prompt=" + chatNative[0])}
        >
          <CiPlay1 size={30} /> {I18n.get("try_this")}
        </Button>
      ) : null}
      {chatArr && chatArr.length > 0 ? (
        <View>
          {chatArr}
          <p />
        </View>
      ) : null}
      {cueCard ? <p /> : null}
      {cueCard}
      {chatNativeTranslate && chatNativeTranslate.length > 0 ? (
        <Button
          style={styles.button}
          size="small"
          onClick={() => clickNativeText()}
        >
          <CiTextAlignJustify size={30} /> {textNativeButtonLabel}
        </Button>
      ) : null}
      {chatNativeTranslate && chatNativeTranslate.length > 0 ? (
        <Button
          style={styles.button}
          size="small"
          onClick={() => clickTargetText()}
        >
          <CiTextAlignJustify size={30} /> {textTargetButtonLabel}
        </Button>
      ) : null}
    </View>
  );
}

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    padding: "0px",
  },

  card: {
    backgroundColor: "#FFF8DC",
    textAlign: "center",
    borderRadius: 10,
  },

  button: {
    backgroundColor: "#D3D3D3",
    width: "170px",
    margin: "3px",
  },
};
