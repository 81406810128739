export const languageTable = {
  en: {
    lang_not_found: "Could not locate what language to converse in",
    initialize: "Initializing...",
    stop_recording: "Stop Recording",
    processing: "Processing...",
    speak_native: "Speak English",
    speak: "Speak",
    show_native_text: "Show English",
    hide_native_text: "Hide English",
    hide: "Hide",
    text: "Text",
    show: "Show",
    no_language_found: "Could not locate what language to converse in",
    arb: "Arabic",
    "cmn-CN": "Chinese",
    "da-DK": "Danish",
    "nl-NL": "Dutch",
    "en-AU": "English Australia",
    "en-GB": "English UK",
    "en-IN": "English India",
    "en-US": "English US",
    "en-GB-WLS": "English UK Welsh",
    "fr-FR": "French France",
    "fr-CA": "French Canada",
    "de-DE": "German",
    "hi-IN": "Hindi",
    "is-IS": "Icelandic",
    "it-IT": "Italian",
    "ja-JP": "Japanese",
    "ko-KR": "Korean",
    "nb-NO": "Norwegian",
    "pl-PL": "Polish",
    "pt-BR": "Portuguese Brazil",
    "pt-PT": "Portuguese Portugal",
    "ro-RO": "Romanian",
    "ru-RU": "Russian",
    "es-ES": "Spanish Spain",
    "es-MX": "Spanish Mexico",
    "es-US": "Spanish US",
    "sv-SE": "Swedish",
    "tr-TR": "Turkish",
    "cy-GB": "Welsh",
    zh: "Chinese",
    "zh-TW": "Traditional Chinese",
    ar: "Arabic",
    bn: "Bengali",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    fr: "French",
    de: "German",
    hi: "Hindi",
    is: "Icelandic",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    nb: "Norwegian",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    es: "Spanish",
    sv: "Swedish",
    tr: "Turkish",
    cy: "Welsh",
    add_cue_card: "Add Cue Card",
    dismiss: "Dismiss",
    flip: "Flip",
    next: "Next",
    correct: "Correct",
    incorrect: "Incorrect",
    favorite: "Favorite",
    archive: "Archive",
    home: "Home",
    play: "Play",
    cue_card: "Card",
    history: "History",
    search: "Search",
    publish: "Publish",
    comment: "Comment",
    submit: "Submit",
    numLikes: "Likes",
    settings: "Settings",
    language: "Language",
    pick_language: "Select display language",
    try_this: "Try This",
    sign_out: "Sign Out",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    username: "Username",
    screenname: "Screen Name",
    save: "Save",
    save_cue_card:
      "Add Cue Card by highlighting foreign text in a conversation",
    explore: "Explore",
    have_conversation: "Have a conversation with someone at",
    coach: "Coach",
    explore_help: "Say 'St. Peter's Basilica in Vatican City'",
    coach_help:
      "Go to the Speak tab and have some conversations and come back here for some coaching",
    speak_help: "Say 'talk to a local by the Eiffel Tower in Paris, France'",
    home_help: "Listen to published conversations by other users",
    ask_coach: "Ask Coach",
    coach_header: "Cue Card Coach",
    cue_card_suggestion: "Cue Card Suggestions",
    conversation_suggestion: "Conversation Suggestions",
    tut_overview:
      "YouSpeak.ai wants to connect the world through language.  Learn a language by exploring places you want to see one day.  Have simulated conversations with foreigners.  Make cue cards by highlighting words you don't know.  Have a coach to get you through stumbling blocks and challenge you to reach further.",
    tut_explore:
      "Explore places you want to visit.  Scroll around the map and click on places.  Press 'Try This' to have a simulated conversation at that place.",
    tut_speak:
      "Have a conversation with anyone around the world.  Speak in your native tongue or practice speaking in a foreign tongue.  Show or hide translated text. Highlight words or phrases to create cue card.",
    tut_coach:
      "Ask the coach for tips on memorizing a hard idiom.  Try adding some suggested cue cards.  Try speaking some suggested conversations.",
    tut_cuecard: "Use cue cards to help you memorize words and phrases.",
    list: "list",
    native_language: "Native Language",
    foreign_language: "Foreign Language",
    language_code: "Language Code",
    limit_reached:
      "Limit reached.  Please come back after 24 hours to resume learning.  Email upgrade@youspeak.ai for more information.",
  },
  ar: {
    lang_not_found: "لا يمكن العثور على لغة للتحدث بها",
    initialize: "جارٍ التهيئة...",
    stop_recording: "إيقاف التسجيل",
    processing: "جارٍ المعالجة...",
    speak_native: "تحدث باللغة العربية",
    speak: "تحدث",
    show_native_text: "اعرض النص العربي",
    hide_native_text: "اخف النص العربي",
    hide: "اخفاء",
    text: "نص",
    show: "عرض",
    no_language_found: "لا يمكن العثور على لغة للتحدث بها",
    arb: "عربية",
    "cmn-CN": "صينية",
    "da-DK": "دنماركية",
    "nl-NL": "هولندية",
    "en-AU": "إنجليزية أسترالية",
    "en-GB": "إنجليزية بريطانية",
    "en-IN": "إنجليزية هندية",
    "en-US": "إنجليزية أمريكية",
    "en-GB-WLS": "إنجليزية بريطانية ويلزية",
    "fr-FR": "فرنسية فرنسية",
    "fr-CA": "فرنسية كندية",
    "de-DE": "ألمانية",
    "hi-IN": "هندية",
    "is-IS": "آيسلندية",
    "it-IT": "إيطالية",
    "ja-JP": "يابانية",
    "ko-KR": "كورية",
    "nb-NO": "نرويجية",
    "pl-PL": "بولندية",
    "pt-BR": "برتغالية برازيلية",
    "pt-PT": "برتغالية برتغالية",
    "ro-RO": "رومانية",
    "ru-RU": "روسية",
    "es-ES": "إسبانية إسبانية",
    "es-MX": "إسبانية مكسيكية",
    "es-US": "إسبانية أمريكية",
    "sv-SE": "سويدية",
    "tr-TR": "تركية",
    "cy-GB": "ويلزية",
    zh: "صينية",
    "zh-TW": "صينية تقليدية",
    ar: "عربية",
    bn: "بنجالية",
    da: "دنماركية",
    nl: "هولندية",
    en: "إنجليزية",
    fr: "فرنسية",
    de: "ألمانية",
    hi: "هندية",
    is: "آيسلندية",
    it: "إيطالية",
    ja: "يابانية",
    ko: "كورية",
    nb: "نرويجية",
    pl: "بولندية",
    pt: "برتغالية",
    ro: "رومانية",
    ru: "روسية",
    es: "إسبانية",
    sv: "سويدية",
    tr: "تركية",
    cy: "ويلزية",
    add_cue_card: "أضف بطاقة تلميح",
    dismiss: "اغلق",
    flip: "اقلب",
    next: "التالي",
    correct: "صحيح",
    incorrect: "غير صحيح",
    favorite: "مفضل",
    archive: "أرشيف",
    home: "الصفحة الرئيسية",
    play: "شغل",
    cue_card: "بطاقة",
    history: "السجل",
    search: "بحث",
    publish: "انشر",
    comment: "تعليق",
    submit: "ارسال",
    numLikes: "الإعجابات",
    settings: "الإعدادات",
    language: "اللغة",
    pick_language: "اختر لغة العرض",
    try_this: "جرب هذا",
    sign_out: "تسجيل الخروج",
    sign_in: "تسجيل الدخول",
    sign_up: "إنشاء حساب",
    username: "اسم المستخدم",
    screenname: "الاسم الظاهر",
    save: "حفظ",
    save_cue_card: "أضف بطاقة تلميح بتحديد النص الأجنبي في المحادثة",
    explore: "استكشف",
    have_conversation: "أجر محادثة مع أحد في",
    coach: "مدرب",
    explore_help: "قل 'كنيسة القديس بطرس في مدينة الفاتيكان'",
    coach_help:
      "اذهب إلى علامة التبويب 'تحدث' وأجر بعض المحادثات ثم عد إلى هنا للحصول على بعض التدريب",
    speak_help: "قل 'تحدث إلى أحد السكان بالقرب من برج إيفل في باريس، فرنسا'",
    home_help: "استمع إلى المحادثات المنشورة من قبل المستخدمين الآخرين",
    ask_coach: "اسأل المدرب",
    coach_header: "مدرب بطاقة التلميح",
    cue_card_suggestion: "اقتراحات بطاقات التلميح",
    conversation_suggestion: "اقتراحات المحادثات",
    tut_overview:
      "YouSpeak.ai يريد توصيل العالم من خلال اللغة. تعلم لغة من خلال استكشاف الأماكن التي تريد زيارتها يومًا ما. أجر محادثات محاكاة مع أجانب. أنشئ بطاقات تلميح بتحديد الكلمات التي لا تعرفها. احصل على مدرب ليساعدك في تجاوز العقبات وتحديك للوصول إلى أبعد.",
    tut_explore:
      "استكشف الأماكن التي تريد زيارتها. تجول على الخريطة وانقر على الأماكن. اضغط على 'جرب هذا' لإجراء محادثة محاكاة في ذلك المكان.",
    tut_speak:
      "تحدث مع أي شخص حول العالم. تحدث بلغتك الأم أو تمرن على التحدث بلغة أجنبية. اعرض أو اخف النص المترجم. حدد الكلمات أو العبارات لإنشاء بطاقة تلميح.",
    tut_coach:
      "اطلب النصيحة من المدرب بشأن تذكر موضوع صعب. جرب إضافة بعض بطاقات التلميح المقترحة. جرب التحدث في بعض المحادثات المقترحة.",
    tut_cuecard: "استخدم بطاقات التلميح لمساعدتك على تذكر الكلمات والعبارات.",
    list: "قائمة",
    native_language: "اللغة الأم",
    foreign_language: "اللغة الأجنبية",
    language_code: "رمز اللغة",
    limit_reached:
      "تم الوصول إلى الحد الأقصى. يرجى العودة بعد 24 ساعة لاستئناف التعلم. أرسل بريد إلكتروني إلى upgrade@youspeak.ai لمزيد من المعلومات.",
  },
  da: {
    lang_not_found: "Kunne ikke finde hvilket sprog der skal tales",
    initialize: "Initialiserer...",
    stop_recording: "Stop Optagelse",
    processing: "Behandler...",
    speak_native: "Tal Engelsk",
    speak: "Tal",
    show_native_text: "Vis Engelsk",
    hide_native_text: "Skjul Engelsk",
    hide: "Skjul",
    text: "Tekst",
    show: "Vis",
    no_language_found: "Kunne ikke finde hvilket sprog der skal tales",
    arb: "Arabisk",
    "cmn-CN": "Kinesisk",
    "da-DK": "Dansk",
    "nl-NL": "Hollandsk",
    "en-AU": "Engelsk Australien",
    "en-GB": "Engelsk UK",
    "en-IN": "Engelsk Indien",
    "en-US": "Engelsk USA",
    "en-GB-WLS": "Engelsk UK Welsh",
    "fr-FR": "Fransk Frankrig",
    "fr-CA": "Fransk Canada",
    "de-DE": "Tysk",
    "hi-IN": "Hindi",
    "is-IS": "Islandsk",
    "it-IT": "Italiensk",
    "ja-JP": "Japansk",
    "ko-KR": "Koreansk",
    "nb-NO": "Norsk",
    "pl-PL": "Polsk",
    "pt-BR": "Portugisisk Brasilien",
    "pt-PT": "Portugisisk Portugal",
    "ro-RO": "Rumænsk",
    "ru-RU": "Russisk",
    "es-ES": "Spansk Spanien",
    "es-MX": "Spansk Mexico",
    "es-US": "Spansk USA",
    "sv-SE": "Svensk",
    "tr-TR": "Tyrkisk",
    "cy-GB": "Walisisk",
    zh: "Kinesisk",
    "zh-TW": "Traditionel Kinesisk",
    ar: "Arabisk",
    bn: "Bengali",
    da: "Dansk",
    nl: "Hollandsk",
    en: "Engelsk",
    fr: "Fransk",
    de: "Tysk",
    hi: "Hindi",
    is: "Islandsk",
    it: "Italiensk",
    ja: "Japansk",
    ko: "Koreansk",
    nb: "Norsk",
    pl: "Polsk",
    pt: "Portugisisk",
    ro: "Rumænsk",
    ru: "Russisk",
    es: "Spansk",
    sv: "Svensk",
    tr: "Tyrkisk",
    cy: "Walisisk",
    add_cue_card: "Tilføj Kort",
    dismiss: "Afvis",
    flip: "Vend",
    next: "Næste",
    correct: "Korrekt",
    incorrect: "Forkert",
    favorite: "Favorit",
    archive: "Arkiv",
    home: "Hjem",
    play: "Afspil",
    cue_card: "Kort",
    history: "Historie",
    search: "Søg",
    publish: "Udgiv",
    comment: "Kommentar",
    submit: "Indsend",
    numLikes: "Likes",
    settings: "Indstillinger",
    language: "Sprog",
    pick_language: "Vælg visningssprog",
    try_this: "Prøv Dette",
    sign_out: "Log Ud",
    sign_in: "Log Ind",
    sign_up: "Tilmeld",
    username: "Brugernavn",
    screenname: "Skærmnavn",
    save: "Gem",
    save_cue_card: "Tilføj Kort ved at fremhæve fremmed tekst i samtale",
    explore: "Udforsk",
    have_conversation: "Hav en samtale med nogen på",
    coach: "Træner",
    explore_help: "Sig 'Peterskirken i Vatikanstaten'",
    coach_help:
      "Gå til Tal-fanen og hav nogle samtaler, og kom tilbage her for at få nogle råd",
    speak_help: "Sig 'tal med en lokal ved Eiffeltårnet i Paris, Frankrig'",
    home_help: "Lyt til udgivne samtaler fra andre brugere",
    ask_coach: "Spørg Træner",
    coach_header: "Kort Træner",
    cue_card_suggestion: "Kort Forslag",
    conversation_suggestion: "Samtale Forslag",
    tut_overview:
      "YouSpeak.ai vil forbinde verden gennem sprog. Lær et sprog ved at udforske steder, du gerne vil se en dag. Hav simulerede samtaler med udlændinge. Lav kort ved at fremhæve ord, du ikke kender. Få en træner til at hjælpe dig gennem vanskeligheder og udfordre dig til at nå længere.",
    tut_explore:
      "Udforsk steder, du vil besøge. Rul rundt på kortet, og klik på steder. Tryk på 'Prøv Dette' for at have en simuleret samtale på det sted.",
    tut_speak:
      "Hav en samtale med nogen rundt om i verden. Tal på dit modersmål, eller øv dig i at tale på et fremmedsprog. Vis eller skjul oversat tekst Fremhæv ord eller sætninger for at oprette kort.",
    tut_coach:
      "Spørg træneren om tips til at huske et svært udtryk. Prøv at tilføje nogle foreslåede kort. Prøv at tale nogle foreslåede samtaler.",
    tut_cuecard: "Brug kort til at hjælpe dig med at huske ord og sætninger.",
    list: "liste",
    native_language: "Modersmål",
    foreign_language: "Udenlandsk Sprog",
    language_code: "Sprogkode",
    limit_reached:
      "Grænse nået. Kom venligst tilbage efter 24 timer for at fortsætte med at lære. E-mail upgrade@youspeak.ai for mere information.",
  },
  nl: {
    lang_not_found: "Kon niet vinden in welke taal te praten",
    initialize: "Initialiseren...",
    stop_recording: "Stop met opnemen",
    processing: "Bezig met verwerken...",
    speak_native: "Spreek Nederlands",
    speak: "Spreek",
    show_native_text: "Toon Nederlands",
    hide_native_text: "Verberg Nederlands",
    hide: "Verberg",
    text: "Tekst",
    show: "Toon",
    no_language_found: "Kon niet vinden in welke taal te praten",
    arb: "Arabisch",
    "cmn-CN": "Chinees",
    "da-DK": "Deens",
    "nl-NL": "Nederlands",
    "en-AU": "Engels Australië",
    "en-GB": "Engels VK",
    "en-IN": "Engels India",
    "en-US": "Engels VS",
    "en-GB-WLS": "Engels VK Welsh",
    "fr-FR": "Frans Frankrijk",
    "fr-CA": "Frans Canada",
    "de-DE": "Duits",
    "hi-IN": "Hindi",
    "is-IS": "IJslands",
    "it-IT": "Italiaans",
    "ja-JP": "Japans",
    "ko-KR": "Koreaans",
    "nb-NO": "Noors",
    "pl-PL": "Pools",
    "pt-BR": "Portugees Brazilië",
    "pt-PT": "Portugees Portugal",
    "ro-RO": "Roemeens",
    "ru-RU": "Russisch",
    "es-ES": "Spaans Spanje",
    "es-MX": "Spaans Mexico",
    "es-US": "Spaans VS",
    "sv-SE": "Zweeds",
    "tr-TR": "Turks",
    "cy-GB": "Welsh",
    zh: "Chinees",
    "zh-TW": "Traditioneel Chinees",
    ar: "Arabisch",
    bn: "Bengaals",
    da: "Deens",
    nl: "Nederlands",
    en: "Engels",
    fr: "Frans",
    de: "Duits",
    hi: "Hindi",
    is: "IJslands",
    it: "Italiaans",
    ja: "Japans",
    ko: "Koreaans",
    nb: "Noors",
    pl: "Pools",
    pt: "Portugees",
    ro: "Roemeens",
    ru: "Russisch",
    es: "Spaans",
    sv: "Zweeds",
    tr: "Turks",
    cy: "Welsh",
    add_cue_card: "Voeg kaartje toe",
    dismiss: "Sluiten",
    flip: "Draai om",
    next: "Volgende",
    correct: "Correct",
    incorrect: "Onjuist",
    favorite: "Favoriet",
    archive: "Archiveren",
    home: "Home",
    play: "Afspelen",
    cue_card: "Kaartje",
    history: "Geschiedenis",
    search: "Zoeken",
    publish: "Publiceren",
    comment: "Opmerking",
    submit: "Indienen",
    numLikes: "Likes",
    settings: "Instellingen",
    language: "Taal",
    pick_language: "Selecteer weergavetaal",
    try_this: "Probeer dit",
    sign_out: "Uitloggen",
    sign_in: "Inloggen",
    sign_up: "Aanmelden",
    username: "Gebruikersnaam",
    screenname: "Schermnaam",
    save: "Opslaan",
    save_cue_card:
      "Voeg kaartje toe door buitenlandse tekst in een gesprek te markeren",
    explore: "Verkennen",
    have_conversation: "Heb een gesprek met iemand bij",
    coach: "Coach",
    explore_help: "Zeg 'Sint-Pietersbasiliek in Vaticaanstad'",
    coach_help:
      "Ga naar het tabblad Spreken, voer een aantal gesprekken en kom hier terug voor coaching",
    speak_help:
      "Zeg 'praat met een local bij de Eiffeltoren in Parijs, Frankrijk'",
    home_help: "Luister naar gepubliceerde gesprekken van andere gebruikers",
    ask_coach: "Vraag Coach",
    coach_header: "Kaarthulp Coach",
    cue_card_suggestion: "Suggesties voor kaartjes",
    conversation_suggestion: "Gesprekssuggesties",
    tut_overview:
      "YouSpeak.ai wil de wereld verbinden door taal. Leer een taal door plaatsen te verkennen die je ooit wilt zien. Voer gesimuleerde gesprekken met buitenlanders. Maak kaartjes door woorden die je niet kent te markeren. Heb een coach om je door struikelblokken heen te helpen en je uit te dagen verder te reiken.",
    tut_explore:
      "Verken plaatsen die u wilt bezoeken. Scroll over de kaart en klik op plaatsen. Druk op 'Probeer dit' om daar een gesimuleerd gesprek te voeren.",
    tut_speak:
      "Voer een gesprek met iedereen over de hele wereld. Spreek in uw moedertaal of oefen het spreken in een vreemde taal. Toon of verberg de vertaalde tekst. Markeer woorden of zinnen om een kaartje te maken.",
    tut_coach:
      "Vraag de coach om tips voor het onthouden van een moeilijk idioom. Voeg enkele voorgestelde kaartjes toe. Probeer enkele voorgestelde gesprekken te voeren.",
    tut_cuecard: "Gebruik kaartjes om woorden en zinnen te onthouden.",
    list: "lijst",
    native_language: "Moedertaal",
    foreign_language: "Vreemde taal",
    language_code: "Taalcode",
    limit_reached:
      "Limiet bereikt. Kom na 24 uur terug om verder te leren. Email upgrade@youspeak.ai voor meer informatie.",
  },
  bn: {
    lang_not_found: "কোন ভাষায় কথা বলতে হবে তা খুঁজে পাওয়া যায়নি",
    initialize: "চালু হচ্ছে...",
    stop_recording: "রেকর্ডিং বন্ধ করুন",
    processing: "প্রসেস হচ্ছে...",
    speak_native: "বাংলায় কথা বলুন",
    speak: "কথা বলুন",
    show_native_text: "বাংলা দেখান",
    hide_native_text: "বাংলা লুকান",
    hide: "লুকান",
    text: "টেক্সট",
    show: "দেখান",
    no_language_found: "কোন ভাষায় কথা বলতে হবে তা খুঁজে পাওয়া যায়নি",
    arb: "আরবি",
    "cmn-CN": "চাইনিজ",
    "da-DK": "ডেনিশ",
    "nl-NL": "ডাচ",
    "en-AU": "ইংরেজি অস্ট্রেলিয়া",
    "en-GB": "ইংরেজি ব্রিটেন",
    "en-IN": "ইংরেজি ভারত",
    "en-US": "ইংরেজি আমেরিকা",
    "en-GB-WLS": "ইংরেজি ব্রিটেন ওয়েলশ",
    "fr-FR": "ফরাসি ফ্রান্স",
    "fr-CA": "ফরাসি কানাডা",
    "de-DE": "জার্মান",
    "hi-IN": "হিন্দি",
    "is-IS": "আইসল্যান্ডিক",
    "it-IT": "ইটালিয়ান",
    "ja-JP": "জাপানিজ",
    "ko-KR": "কোরিয়ান",
    "nb-NO": "নরওয়েজিয়ান",
    "pl-PL": "পোলিশ",
    "pt-BR": "পর্তুগিজ ব্রাজিল",
    "pt-PT": "পর্তুগিজ পর্তুগাল",
    "ro-RO": "রোমানিয়ান",
    "ru-RU": "রাশিয়ান",
    "es-ES": "স্প্যানিশ স্পেন",
    "es-MX": "স্প্যানিশ মেক্সিকো",
    "es-US": "স্প্যানিশ আমেরিকা",
    "sv-SE": "সুইডিশ",
    "tr-TR": "তুর্কি",
    "cy-GB": "ওয়েলশ",
    zh: "চাইনিজ",
    "zh-TW": "প্রচলিত চাইনিজ",
    ar: "আরবি",
    bn: "বাংলা",
    da: "ডেনিশ",
    nl: "ডাচ",
    en: "ইংরেজি",
    fr: "ফরাসি",
    de: "জার্মান",
    hi: "হিন্দি",
    is: "আইসল্যান্ডিক",
    it: "ইটালিয়ান",
    ja: "জাপানিজ",
    ko: "কোরিয়ান",
    nb: "নরওয়েজিয়ান",
    pl: "পোলিশ",
    pt: "পর্তুগিজ",
    ro: "রোমানিয়ান",
    ru: "রাশিয়ান",
    es: "স্প্যানিশ",
    sv: "সুইডিশ",
    tr: "তুর্কি",
    cy: "ওয়েলশ",
    add_cue_card: "কিউ কার্ড যুক্ত করুন",
    dismiss: "বাদ দিন",
    flip: "পাল্টান",
    next: "পরবর্তী",
    correct: "সঠিক",
    incorrect: "ভুল",
    favorite: "প্রিয়",
    archive: "আর্কাইভ",
    home: "প্রধান পাতা",
    play: "খেলুন",
    cue_card: "কার্ড",
    history: "ইতিহাস",
    search: "অনুসন্ধান করুন",
    publish: "প্রকাশ করুন",
    comment: "মন্তব্য করুন",
    submit: "জমা দিন",
    numLikes: "লাইকস",
    settings: "সেটিংস",
    language: "ভাষা",
    pick_language: "প্রদর্শন ভাষা নির্বাচন করুন",
    try_this: "এটি চেষ্টা করুন",
    sign_out: "সাইন আউট",
    sign_in: "সাইন ইন",
    sign_up: "সাইন আপ করুন",
    username: "ইউজারনেম",
    screenname: "স্ক্রিন নাম",
    save: "সংরক্ষণ করুন",
    save_cue_card: "কথোপকথনে বিদেশি টেক্সট হাইলাইট করে কিউ কার্ড যুক্ত করুন",
    explore: "অন্বেষণ করুন",
    have_conversation: "কেউ সাথে কথোপকথন করুন",
    coach: "কোচ",
    explore_help: "বলুন 'ভ্যাটিকান সিটির সেন্ট পিটারস বাসিলিকা'",
    coach_help:
      "স্পিক ট্যাবে যান এবং কিছু কথা বলুন এবং এখানে কিছু কোচিং-এর জন্য ফিরে আসুন",
    speak_help:
      "বলুন 'ফ্রান্সের প্যারিসে আইফেল টাওয়ারের পাশে একজন লোকাল সাথে কথা বলুন'",
    home_help: "অন্যান্য ব্যবহারকারীদের দ্বারা প্রকাশিত কথোপকথন শুনুন",
    ask_coach: "কোচকে জিজ্ঞাসা করুন",
    coach_header: "কিউ কার্ড কোচ",
    cue_card_suggestion: "কিউ কার্ড পরামর্শ",
    conversation_suggestion: "কথোপকথন পরামর্শ",
    tut_overview:
      "YouSpeak.ai সারা বিশ্বকে ভাষায় সংযুক্ত করতে চায়। ঘুরে দেখা স্থানে ঘুরে ভাষা শিখুন। বিদেশীদের সাথে কথোপকথনের অনুকরণ করুন। আপনি যা জানেন না এমন শব্দগুলি হাইলাইট করে কিউ কার্ড তৈরি করুন। একটি কোচ পান যে আপনাকে পথ দেখাবে এবং আপনাকে আরও দূরে পৌঁছাতে চ্যালেঞ্জ করবে।",
    tut_explore:
      "আপনি ভিজিট করতে চান এমন স্থানগুলি অন্বেষণ করুন। মানচিত্রের চারপাশে স্ক্রল করুন এবং স্থানগুলিতে ক্লিক করুন। সিমুলেটেড কথোপকথন করার জন্য 'এটি চেষ্টা করুন' চাপুন।",
    tut_speak:
      "বিশ্বজুড়ে যে কারো সাথে কথোপকথন করুন। আপনার মাতৃভাষায় কথা বলুন অথবা বিদেশী ভাষায় কথা বলার অভ্যাস করুন। অনুবাদিত টেক্সট দেখান বা লুকান। কিউ কার্ড তৈরি করার জন্য শব্দ বা বাক্যাংশগুলি হাইলাইট করুন।",
    tut_coach:
      "কঠিন ইডিয়ম মুখস্থ করার জন্য পরামর্শের জন্য কোচকে জিজ্ঞাসা করুন। কিছু পরামর্শিত কিউ কার্ড যুক্ত করার চেষ্টা করুন। কিছু পরামর্শিত কথোপকথন বলার চেষ্টা করুন।",
    tut_cuecard: "শব্দ ও বাক্যাংশ মুখস্থ করতে কিউ কার্ড ব্যবহার করুন।",
    list: "তালিকা",
    native_language: "মাতৃভাষা",
    foreign_language: "বিদেশী ভাষা",
    language_code: "ভাষা কোড",
    limit_reached:
      "সীমা পৌঁছেছে। শিখতে পুনরায় চালু করতে ২৪ ঘন্টা পরে ফিরে আসুন। আরও তথ্যের জন্য upgrade@youspeak.ai এ ইমেইল করুন।",
  },
  fr: {
    lang_not_found:
      "Impossible de déterminer la langue dans laquelle converser",
    initialize: "Initialisation...",
    stop_recording: "Arrêter l'enregistrement",
    processing: "Traitement...",
    speak_native: "Parlez français",
    speak: "Parlez",
    show_native_text: "Afficher le français",
    hide_native_text: "Masquer le français",
    hide: "Masquer",
    text: "Texte",
    show: "Afficher",
    no_language_found:
      "Impossible de déterminer la langue dans laquelle converser",
    arb: "Arabe",
    "cmn-CN": "Chinois",
    "da-DK": "Danois",
    "nl-NL": "Néerlandais",
    "en-AU": "Anglais Australie",
    "en-GB": "Anglais Royaume-Uni",
    "en-IN": "Anglais Inde",
    "en-US": "Anglais États-Unis",
    "en-GB-WLS": "Anglais Pays de Galles",
    "fr-FR": "Français France",
    "fr-CA": "Français Canada",
    "de-DE": "Allemand",
    "hi-IN": "Hindi",
    "is-IS": "Islandais",
    "it-IT": "Italien",
    "ja-JP": "Japonais",
    "ko-KR": "Coréen",
    "nb-NO": "Norvégien",
    "pl-PL": "Polonais",
    "pt-BR": "Portugais Brésil",
    "pt-PT": "Portugais Portugal",
    "ro-RO": "Roumain",
    "ru-RU": "Russe",
    "es-ES": "Espagnol Espagne",
    "es-MX": "Espagnol Mexique",
    "es-US": "Espagnol États-Unis",
    "sv-SE": "Suédois",
    "tr-TR": "Turc",
    "cy-GB": "Gallois",
    zh: "Chinois",
    "zh-TW": "Chinois traditionnel",
    ar: "Arabe",
    bn: "Bengali",
    da: "Danois",
    nl: "Néerlandais",
    en: "Anglais",
    fr: "Français",
    de: "Allemand",
    hi: "Hindi",
    is: "Islandais",
    it: "Italien",
    ja: "Japonais",
    ko: "Coréen",
    nb: "Norvégien",
    pl: "Polonais",
    pt: "Portugais",
    ro: "Roumain",
    ru: "Russe",
    es: "Espagnol",
    sv: "Suédois",
    tr: "Turc",
    cy: "Gallois",
    add_cue_card: "Ajouter une carte mémoire",
    dismiss: "Fermer",
    flip: "Retourner",
    next: "Suivant",
    correct: "Correct",
    incorrect: "Incorrect",
    favorite: "Favori",
    archive: "Archiver",
    home: "Accueil",
    play: "Lire",
    cue_card: "Carte",
    history: "Historique",
    search: "Rechercher",
    publish: "Publier",
    comment: "Commentaire",
    submit: "Soumettre",
    numLikes: "J'aime",
    settings: "Paramètres",
    language: "Langue",
    pick_language: "Sélectionner la langue d'affichage",
    try_this: "Essayez ceci",
    sign_out: "Déconnexion",
    sign_in: "Connexion",
    sign_up: "Inscription",
    username: "Nom d'utilisateur",
    screenname: "Pseudo",
    save: "Enregistrer",
    save_cue_card:
      "Ajoutez une carte mémoire en surlignant le texte étranger dans une conversation",
    explore: "Explorer",
    have_conversation: "Avoir une conversation avec quelqu'un à",
    coach: "Coach",
    explore_help: "Dites 'Basilique Saint-Pierre à la Cité du Vatican'",
    coach_help:
      "Allez dans l'onglet Parler et ayez des conversations, puis revenez ici pour des conseils",
    speak_help:
      "Dites 'parler à un local près de la Tour Eiffel à Paris, France'",
    home_help: "Écoutez les conversations publiées par d'autres utilisateurs",
    ask_coach: "Demander au Coach",
    coach_header: "Coach de carte de mémoire",
    cue_card_suggestion: "Suggestions de cartes de mémoire",
    conversation_suggestion: "Suggestions de conversation",
    tut_overview:
      "YouSpeak.ai veut connecter le monde par la langue. Apprenez une langue en explorant les endroits que vous voulez voir un jour. Ayez des conversations simulées avec des étrangers. Faites des cartes de mémoire en surlignant les mots que vous ne connaissez pas. Ayez un coach pour vous aider à surmonter les obstacles et vous pousser à aller plus loin.",
    tut_explore:
      "Explorez les endroits que vous voulez visiter. Faites glisser la carte et cliquez sur les endroits. Appuyez sur 'Essayez ceci' pour avoir une conversation simulée à cet endroit.",
    tut_speak:
      "Ayez une conversation avec n'importe qui dans le monde. Parlez dans votre langue maternelle ou pratiquez en parlant dans une langue étrangère. Affichez ou masquez le texte traduit. Surlignez les mots ou les phrases pour créer une carte de mémoire.",
    tut_coach:
      "Demandez au coach des astuces pour mémoriser une expression difficile. Essayez d'ajouter quelques cartes mémoire suggérées. Essayez de parler de quelques conversations suggérées.",
    tut_cuecard:
      "Utilisez des cartes de mémoire pour vous aider à mémoriser des mots et des phrases.",
    list: "liste",
    native_language: "Langue maternelle",
    foreign_language: "Langue étrangère",
    language_code: "Code langue",
    limit_reached:
      "Limite atteinte. Veuillez revenir après 24 heures pour reprendre l'apprentissage. Envoyez un e-mail à upgrade@youspeak.ai pour plus d'informations.",
  },
  "fr-CA": {
    lang_not_found: "Impossible de déterminer la langue de conversation",
    initialize: "Initialisation...",
    stop_recording: "Arrêter l'enregistrement",
    processing: "Traitement...",
    speak_native: "Parlez français canadien",
    speak: "Parlez",
    show_native_text: "Afficher le texte en français canadien",
    hide_native_text: "Masquer le texte en français canadien",
    hide: "Masquer",
    text: "Texte",
    show: "Afficher",
    no_language_found: "Impossible de déterminer la langue de conversation",
    arb: "Arabe",
    "cmn-CN": "Chinois",
    "da-DK": "Danois",
    "nl-NL": "Néerlandais",
    "en-AU": "Anglais Australien",
    "en-GB": "Anglais Britannique",
    "en-IN": "Anglais Inde",
    "en-US": "Anglais Américain",
    "en-GB-WLS": "Anglais Britannique Gallois",
    "fr-FR": "Français France",
    "fr-CA": "Français Canada",
    "de-DE": "Allemand",
    "hi-IN": "Hindi",
    "is-IS": "Islandais",
    "it-IT": "Italien",
    "ja-JP": "Japonais",
    "ko-KR": "Coréen",
    "nb-NO": "Norvégien",
    "pl-PL": "Polonais",
    "pt-BR": "Portugais Brésil",
    "pt-PT": "Portugais Portugal",
    "ro-RO": "Roumain",
    "ru-RU": "Russe",
    "es-ES": "Espagnol Espagne",
    "es-MX": "Espagnol Mexique",
    "es-US": "Espagnol États-Unis",
    "sv-SE": "Suédois",
    "tr-TR": "Turc",
    "cy-GB": "Gallois",
    zh: "Chinois",
    "zh-TW": "Chinois traditionnel",
    ar: "Arabe",
    bn: "Bengali",
    da: "Danois",
    nl: "Néerlandais",
    en: "Anglais",
    fr: "Français",
    de: "Allemand",
    hi: "Hindi",
    is: "Islandais",
    it: "Italien",
    ja: "Japonais",
    ko: "Coréen",
    nb: "Norvégien",
    pl: "Polonais",
    pt: "Portugais",
    ro: "Roumain",
    ru: "Russe",
    es: "Espagnol",
    sv: "Suédois",
    tr: "Turc",
    cy: "Gallois",
    add_cue_card: "Ajouter une carte aide-mémoire",
    dismiss: "Ignorer",
    flip: "Retourner",
    next: "Suivant",
    correct: "Correct",
    incorrect: "Incorrect",
    favorite: "Favori",
    archive: "Archiver",
    home: "Accueil",
    play: "Jouer",
    cue_card: "Carte",
    history: "Historique",
    search: "Rechercher",
    publish: "Publier",
    comment: "Commenter",
    submit: "Soumettre",
    numLikes: "Aime",
    settings: "Paramètres",
    language: "Langue",
    pick_language: "Sélectionner la langue d'affichage",
    try_this: "Essayez ceci",
    sign_out: "Déconnexion",
    sign_in: "Connexion",
    sign_up: "Inscription",
    username: "Nom d'utilisateur",
    screenname: "Pseudo",
    save: "Sauvegarder",
    save_cue_card:
      "Ajouter une carte aide-mémoire en surlignant le texte étranger dans une conversation",
    explore: "Explorer",
    have_conversation: "Avoir une conversation avec quelqu'un à",
    coach: "Entraîneur",
    explore_help: "Dites 'Basilique Saint Pierre à la Cité du Vatican'",
    coach_help:
      "Allez à l'onglet Parler, ayez quelques conversations et revenez ici pour un coaching",
    speak_help:
      "Dites 'parlez à un local près de la Tour Eiffel à Paris, France'",
    home_help: "Écoutez les conversations publiées par d'autres utilisateurs",
    ask_coach: "Demandez à l'entraîneur",
    coach_header: "Entraîneur de cartes aide-mémoire",
    cue_card_suggestion: "Suggestions de cartes aide-mémoire",
    conversation_suggestion: "Suggestions de conversation",
    tut_overview:
      "YouSpeak.ai souhaite connecter le monde à travers les langues. Apprenez une langue en explorant des endroits que vous voulez voir un jour. Ayez des conversations simulées avec des étrangers. Créez des cartes aide-mémoire en surlignant les mots que vous ne connaissez pas. Ayez un entraîneur pour vous aider à franchir les obstacles et vous défier d'aller plus loin.",
    tut_explore:
      "Explorez des endroits que vous souhaitez visiter. Déplacez-vous sur la carte et cliquez sur des lieux. Appuyez sur 'Essayez ceci' pour avoir une conversation simulée à cet endroit.",
    tut_speak:
      "Ayez une conversation avec n'importe qui dans le monde. Parlez dans votre langue maternelle ou pratiquez une langue étrangère. Affichez ou masquez le texte traduit.\nSurlignez des mots ou expressions pour créer une carte aide-mémoire.",
    tut_coach:
      "Demandez à l'entraîneur des conseils pour mémoriser une expression difficile. Essayez d'ajouter quelques cartes aide-mémoire suggérées. Essayez d'avoir quelques conversations suggérées.",
    tut_cuecard:
      "Utilisez les cartes aide-mémoire pour vous aider à mémoriser des mots et des expressions.",
    list: "liste",
    native_language: "Langue maternelle",
    foreign_language: "Langue étrangère",
    language_code: "Code de la langue",
    limit_reached:
      "Limite atteinte. Revenez après 24 heures pour continuer à apprendre. Envoyez un courriel à upgrade@youspeak.ai pour plus d'informations.",
  },
  de: {
    lang_not_found: "Konnte die Konversationssprache nicht finden",
    initialize: "Initialisierung...",
    stop_recording: "Aufnahme stoppen",
    processing: "Verarbeitung...",
    speak_native: "Sprich Deutsch",
    speak: "Sprechen",
    show_native_text: "Deutsch anzeigen",
    hide_native_text: "Deutsch ausblenden",
    hide: "Verbergen",
    text: "Text",
    show: "Anzeigen",
    no_language_found: "Konnte die Konversationssprache nicht finden",
    arb: "Arabisch",
    "cmn-CN": "Chinesisch",
    "da-DK": "Dänisch",
    "nl-NL": "Niederländisch",
    "en-AU": "Englisch Australien",
    "en-GB": "Englisch UK",
    "en-IN": "Englisch Indien",
    "en-US": "Englisch US",
    "en-GB-WLS": "Englisch UK Walisisch",
    "fr-FR": "Französisch Frankreich",
    "fr-CA": "Französisch Kanada",
    "de-DE": "Deutsch",
    "hi-IN": "Hindi",
    "is-IS": "Isländisch",
    "it-IT": "Italienisch",
    "ja-JP": "Japanisch",
    "ko-KR": "Koreanisch",
    "nb-NO": "Norwegisch",
    "pl-PL": "Polnisch",
    "pt-BR": "Portugiesisch Brasilien",
    "pt-PT": "Portugiesisch Portugal",
    "ro-RO": "Rumänisch",
    "ru-RU": "Russisch",
    "es-ES": "Spanisch Spanien",
    "es-MX": "Spanisch Mexiko",
    "es-US": "Spanisch US",
    "sv-SE": "Schwedisch",
    "tr-TR": "Türkisch",
    "cy-GB": "Walisisch",
    zh: "Chinesisch",
    "zh-TW": "Traditionelles Chinesisch",
    ar: "Arabisch",
    bn: "Bengalisch",
    da: "Dänisch",
    nl: "Niederländisch",
    en: "Englisch",
    fr: "Französisch",
    de: "Deutsch",
    hi: "Hindi",
    is: "Isländisch",
    it: "Italienisch",
    ja: "Japanisch",
    ko: "Koreanisch",
    nb: "Norwegisch",
    pl: "Polnisch",
    pt: "Portugiesisch",
    ro: "Rumänisch",
    ru: "Russisch",
    es: "Spanisch",
    sv: "Schwedisch",
    tr: "Türkisch",
    cy: "Walisisch",
    add_cue_card: "Lernkarte hinzufügen",
    dismiss: "Verwerfen",
    flip: "Umdrehen",
    next: "Weiter",
    correct: "Richtig",
    incorrect: "Falsch",
    favorite: "Favorit",
    archive: "Archivieren",
    home: "Startseite",
    play: "Abspielen",
    cue_card: "Karte",
    history: "Verlauf",
    search: "Suche",
    publish: "Veröffentlichen",
    comment: "Kommentieren",
    submit: "Einreichen",
    numLikes: "Gefällt",
    settings: "Einstellungen",
    language: "Sprache",
    pick_language: "Anzeigesprache wählen",
    try_this: "Versuch das",
    sign_out: "Abmelden",
    sign_in: "Anmelden",
    sign_up: "Registrieren",
    username: "Benutzername",
    screenname: "Anzeigename",
    save: "Speichern",
    save_cue_card:
      "Lernkarte hinzufügen, indem Sie Text in einer Unterhaltung markieren",
    explore: "Erkunden",
    have_conversation: "Führen Sie ein Gespräch mit jemandem bei",
    coach: "Coach",
    explore_help: "Sagen Sie 'Petersdom im Vatikanstaat'",
    coach_help:
      "Gehen Sie zum Sprech-Tab und führen Sie einige Gespräche und kehren Sie hierher zurück für Coaching",
    speak_help:
      "Sagen Sie 'sprechen Sie mit einem Einheimischen bei dem Eiffelturm in Paris, Frankreich'",
    home_help: "Hören Sie veröffentlichte Gespräche von anderen Benutzern",
    ask_coach: "Fragen Sie den Coach",
    coach_header: "Cue Card Coach",
    cue_card_suggestion: "Lernkarten-Vorschläge",
    conversation_suggestion: "Gesprächsvorschläge",
    tut_overview:
      "YouSpeak.ai möchte die Welt durch Sprache verbinden.  Lernen Sie eine Sprache, indem Sie Orte erkunden, die Sie eines Tages sehen möchten.  Führen Sie simulierte Gespräche mit Ausländern.  Erstellen Sie Lernkarten, indem Sie Wörter markieren, die Sie nicht kennen.  Haben Sie einen Coach, um Stolpersteine zu überwinden und herausgefordert zu werden, weiter zu kommen.",
    tut_explore:
      "Erkunden Sie Orte, die Sie besuchen möchten.  Scrollen Sie über die Karte und klicken Sie auf Orte.  Drücken Sie 'Versuch das', um ein simuliertes Gespräch an diesem Ort zu führen.",
    tut_speak:
      "Führen Sie ein Gespräch mit jemandem auf der ganzen Welt.  Sprechen Sie in Ihrer Muttersprache oder üben Sie das Sprechen in einer Fremdsprache.  Zeigen oder verbergen Sie übersetzten Text.  Markieren Sie Wörter oder Sätze, um eine Lernkarte zu erstellen.",
    tut_coach:
      "Fragen Sie den Coach nach Tipps zum Merken einer schwierigen Redewendung.  Versuchen Sie, einige vorgeschlagene Lernkarten hinzuzufügen.  Versuchen Sie, einige vorgeschlagene Gespräche zu sprechen.",
    tut_cuecard:
      "Verwenden Sie Lernkarten, um Ihnen beim Merken von Wörtern und Sätzen zu helfen.",
    list: "Liste",
    native_language: "Muttersprache",
    foreign_language: "Fremdsprache",
    language_code: "Sprachcode",
    limit_reached:
      "Limit erreicht.  Bitte kommen Sie nach 24 Stunden zurück, um mit dem Lernen fortzufahren.  E-Mail upgrade@youspeak.ai für weitere Informationen.",
  },
  zh: {
    lang_not_found: "无法找到对话的语言",
    initialize: "初始化...",
    stop_recording: "停止录音",
    processing: "处理中...",
    speak_native: "说中文",
    speak: "说",
    show_native_text: "显示中文",
    hide_native_text: "隐藏中文",
    hide: "隐藏",
    text: "文本",
    show: "显示",
    no_language_found: "无法找到对话的语言",
    arb: "阿拉伯语",
    "cmn-CN": "中文",
    "da-DK": "丹麦语",
    "nl-NL": "荷兰语",
    "en-AU": "澳大利亚英语",
    "en-GB": "英国英语",
    "en-IN": "印度英语",
    "en-US": "美国英语",
    "en-GB-WLS": "威尔士英语",
    "fr-FR": "法国法语",
    "fr-CA": "加拿大法语",
    "de-DE": "德语",
    "hi-IN": "印地语",
    "is-IS": "冰岛语",
    "it-IT": "意大利语",
    "ja-JP": "日语",
    "ko-KR": "韩语",
    "nb-NO": "挪威语",
    "pl-PL": "波兰语",
    "pt-BR": "巴西葡萄牙语",
    "pt-PT": "葡萄牙葡萄牙语",
    "ro-RO": "罗马尼亚语",
    "ru-RU": "俄语",
    "es-ES": "西班牙语（西班牙）",
    "es-MX": "西班牙语（墨西哥）",
    "es-US": "西班牙语（美国）",
    "sv-SE": "瑞典语",
    "tr-TR": "土耳其语",
    "cy-GB": "威尔士语",
    zh: "中文",
    "zh-TW": "繁体中文",
    ar: "阿拉伯语",
    bn: "孟加拉语",
    da: "丹麦语",
    nl: "荷兰语",
    en: "英语",
    fr: "法语",
    de: "德语",
    hi: "印地语",
    is: "冰岛语",
    it: "意大利语",
    ja: "日语",
    ko: "韩语",
    nb: "挪威语",
    pl: "波兰语",
    pt: "葡萄牙语",
    ro: "罗马尼亚语",
    ru: "俄语",
    es: "西班牙语",
    sv: "瑞典语",
    tr: "土耳其语",
    cy: "威尔士语",
    add_cue_card: "添加提示卡",
    dismiss: "解散",
    flip: "翻转",
    next: "下一个",
    correct: "正确",
    incorrect: "不正确",
    favorite: "收藏",
    archive: "档案",
    home: "主页",
    play: "播放",
    cue_card: "提示卡",
    history: "历史",
    search: "搜索",
    publish: "发布",
    comment: "评论",
    submit: "提交",
    numLikes: "喜欢",
    settings: "设置",
    language: "语言",
    pick_language: "选择显示语言",
    try_this: "试试这个",
    sign_out: "登出",
    sign_in: "登录",
    sign_up: "注册",
    username: "用户名",
    screenname: "屏幕名称",
    save: "保存",
    save_cue_card: "通过在对话中突出显示外国文本来添加提示卡",
    explore: "探索",
    have_conversation: "在以下位置与某人对话",
    coach: "教练",
    explore_help: "说“梵蒂冈圣彼得大教堂”",
    coach_help: "转到“说”选项卡进行一些对话，然后回来这里获取一些指导",
    speak_help: "说“在法国巴黎埃菲尔铁塔旁与当地人交谈”",
    home_help: "听其他用户发布的对话",
    ask_coach: "问教练",
    coach_header: "提示卡教练",
    cue_card_suggestion: "提示卡建议",
    conversation_suggestion: "对话建议",
    tut_overview:
      "YouSpeak.ai 想通过语言连接世界。 通过探索你想参观的地方来学习语言。与外国人进行模拟对话。通过突出显示你不懂的单词来制作提示卡。请教练帮助你克服障碍并挑战自己。",
    tut_explore:
      "探索你想参观的地方。 在地图上滚动并点击位置。 按“试试这个”进行该地点的模拟对话。",
    tut_speak:
      "与世界各地的人进行对话。 用你的母语或练习说外语。显示或隐藏翻译的文本\n突出显示单词或短语以创建提示卡。",
    tut_coach:
      "请教练提供记住硬习语的技巧。尝试添加一些建议的提示卡。尝试说一些建议的对话。",
    tut_cuecard: "使用提示卡帮助你记住单词和短语。",
    list: "列表",
    native_language: "母语",
    foreign_language: "外语",
    language_code: "语言代码",
    limit_reached:
      "达到限制。 请在 24 小时后回来继续学习。 电子邮件 upgrade@youspeak.ai 获取更多信息。",
  },
  "zh-TW": {
    lang_not_found: "無法定位要交流的語言",
    initialize: "初始化...",
    stop_recording: "停止錄音",
    processing: "處理中...",
    speak_native: "說中文",
    speak: "說",
    show_native_text: "顯示中文",
    hide_native_text: "隱藏中文",
    hide: "隱藏",
    text: "文字",
    show: "顯示",
    no_language_found: "無法定位要交流的語言",
    arb: "阿拉伯語",
    "cmn-CN": "中文",
    "da-DK": "丹麥語",
    "nl-NL": "荷蘭語",
    "en-AU": "澳洲英語",
    "en-GB": "英國英語",
    "en-IN": "印度英語",
    "en-US": "美國英語",
    "en-GB-WLS": "威爾士英語",
    "fr-FR": "法國法語",
    "fr-CA": "加拿大法語",
    "de-DE": "德語",
    "hi-IN": "印地語",
    "is-IS": "冰島語",
    "it-IT": "義大利語",
    "ja-JP": "日語",
    "ko-KR": "韓語",
    "nb-NO": "挪威語",
    "pl-PL": "波蘭語",
    "pt-BR": "巴西葡萄牙語",
    "pt-PT": "葡萄牙語",
    "ro-RO": "羅馬尼亞語",
    "ru-RU": "俄語",
    "es-ES": "西班牙語",
    "es-MX": "墨西哥西班牙語",
    "es-US": "美國西班牙語",
    "sv-SE": "瑞典語",
    "tr-TR": "土耳其語",
    "cy-GB": "威爾士語",
    zh: "中文",
    "zh-TW": "繁體中文",
    ar: "阿拉伯語",
    bn: "孟加拉語",
    da: "丹麥語",
    nl: "荷蘭語",
    en: "英語",
    fr: "法語",
    de: "德語",
    hi: "印地語",
    is: "冰島語",
    it: "義大利語",
    ja: "日語",
    ko: "韓語",
    nb: "挪威語",
    pl: "波蘭語",
    pt: "葡萄牙語",
    ro: "羅馬尼亞語",
    ru: "俄語",
    es: "西班牙語",
    sv: "瑞典語",
    tr: "土耳其語",
    cy: "威爾士語",
    add_cue_card: "新增提示卡",
    dismiss: "關閉",
    flip: "翻轉",
    next: "下一步",
    correct: "正確",
    incorrect: "錯誤",
    favorite: "喜愛",
    archive: "存檔",
    home: "主頁",
    play: "播放",
    cue_card: "卡片",
    history: "歷史",
    search: "搜索",
    publish: "發布",
    comment: "評論",
    submit: "提交",
    numLikes: "喜歡",
    settings: "設置",
    language: "語言",
    pick_language: "選擇顯示語言",
    try_this: "試試這個",
    sign_out: "登出",
    sign_in: "登入",
    sign_up: "註冊",
    username: "用戶名",
    screenname: "顯示名稱",
    save: "保存",
    save_cue_card: "在對話中突出顯示外語文本以添加提示卡",
    explore: "探索",
    have_conversation: "與某人在",
    coach: "教練",
    explore_help: "說 '梵蒂岡聖彼得大教堂'",
    coach_help: "前往說話選項卡，開始對話，然後回到這裡接受教練指導",
    speak_help: "說 '在巴黎艾菲爾鐵塔附近與當地人交談'",
    home_help: "收聽其他用戶發布的對話",
    ask_coach: "詢問教練",
    coach_header: "提示卡教練",
    cue_card_suggestion: "提示卡建議",
    conversation_suggestion: "對話建議",
    tut_overview:
      "YouSpeak.ai希望通過語言連接世界。通過探索你想拜訪的地方來學習一種語言。與外國人進行模擬對話。通過突出顯示你不懂的單詞來製作提示卡。請教練來幫助你克服障礙並挑戰自己。",
    tut_explore:
      "探索你想訪問的地方。滾動地圖並點擊地點。按“試試這個”來在那個地方進行模擬對話。",
    tut_speak:
      "與世界各地的任何人對話。用你的母語講話或練習講外語。顯示或隱藏翻譯文本突出顯示單詞或短語以創建提示卡。",
    tut_coach:
      "請教練提供記住難懂成語的技巧。嘗試添加一些推薦的提示卡。試著說一些推薦的對話。",
    tut_cuecard: "使用提示卡來幫助你記住單詞和短語。",
    list: "列表",
    native_language: "母語",
    foreign_language: "外語",
    language_code: "語言代碼",
    limit_reached:
      "達到限制。請在24小時後再回來繼續學習。郵件聯系 upgrade@youspeak.ai 獲取更多信息。",
  },
  hi: {
    lang_not_found: "किस भाषा में बातचीत करनी है उसे ढूंढ नहीं सके",
    initialize: "प्रारंभ करना...",
    stop_recording: "रिकॉर्डिंग बंद करें",
    processing: "प्रसंस्करण...",
    speak_native: "अंग्रेजी बोलें",
    speak: "बोलें",
    show_native_text: "अंग्रेजी टेक्स्ट दिखाएं",
    hide_native_text: "अंग्रेजी टेक्स्ट छिपाएं",
    hide: "छिपाएं",
    text: "टेक्स्ट",
    show: "दिखाएं",
    no_language_found: "किस भाषा में बातचीत करनी है उसे ढूंढ नहीं सके",
    arb: "अरबी",
    "cmn-CN": "चीनी",
    "da-DK": "डेनिश",
    "nl-NL": "डच",
    "en-AU": "अंग्रेजी ऑस्ट्रेलिया",
    "en-GB": "अंग्रेजी यूके",
    "en-IN": "अंग्रेजी इंडिया",
    "en-US": "अंग्रेजी यूएस",
    "en-GB-WLS": "अंग्रेजी यूके वेल्श",
    "fr-FR": "फ्रेंच फ्रांस",
    "fr-CA": "फ्रेंच कनाडा",
    "de-DE": "जर्मन",
    "hi-IN": "हिंदी",
    "is-IS": "आइसलैंडिक",
    "it-IT": "इतालवी",
    "ja-JP": "जापानी",
    "ko-KR": "कोरियाई",
    "nb-NO": "नॉर्वेजियन",
    "pl-PL": "पोलिश",
    "pt-BR": "पुर्तगाली ब्राजील",
    "pt-PT": "पुर्तगाली पुर्तगाल",
    "ro-RO": "रूमानी",
    "ru-RU": "रूसी",
    "es-ES": "स्पेनिश स्पेन",
    "es-MX": "स्पेनिश मैक्सिको",
    "es-US": "स्पेनिश यूएस",
    "sv-SE": "स्वीडिश",
    "tr-TR": "तुर्की",
    "cy-GB": "वेल्श",
    zh: "चीनी",
    "zh-TW": "पारंपरिक चीनी",
    ar: "अरबी",
    bn: "बंगाली",
    da: "डेनिश",
    nl: "डच",
    en: "अंग्रेजी",
    fr: "फ्रेंच",
    de: "जर्मन",
    hi: "हिंदी",
    is: "आइसलैंडिक",
    it: "इतालवी",
    ja: "जापानी",
    ko: "कोरियाई",
    nb: "नॉर्वेजियन",
    pl: "पोलिश",
    pt: "पुर्तगाली",
    ro: "रूमानी",
    ru: "रूसी",
    es: "स्पेनिश",
    sv: "स्वीडिश",
    tr: "तुर्की",
    cy: "वेल्श",
    add_cue_card: "क्यू कार्ड जोड़ें",
    dismiss: "खारिज कर दें",
    flip: "पलटें",
    next: "अगला",
    correct: "सही",
    incorrect: "गलत",
    favorite: "पसंदीदा",
    archive: "पुरालेख",
    home: "मुख्यपृष्ठ",
    play: "चलाएं",
    cue_card: "कार्ड",
    history: "इतिहास",
    search: "खोजें",
    publish: "प्रकाशित करें",
    comment: "टिप्पणी",
    submit: "प्रस्तुत करें",
    numLikes: "पसंद",
    settings: "सेटिंग्स",
    language: "भाषा",
    pick_language: "प्रदर्शन भाषा चुनें",
    try_this: "इसे आज़माएं",
    sign_out: "साइन आउट करें",
    sign_in: "साइन इन करें",
    sign_up: "साइन अप करें",
    username: "उपयोगकर्ता नाम",
    screenname: "स्क्रीन नाम",
    save: "सहेजें",
    save_cue_card:
      "बातचीत में विदेशी टेक्स्ट को हाईलाइट करके क्यू कार्ड जोड़ें",
    explore: "जांचें",
    have_conversation: "किसी से बातचीत करें",
    coach: "कोच",
    explore_help: "कहें 'वेटिकन सिटी में सेंट पीटर की बेसिलिका'",
    coach_help:
      "बोलें टैब पर जाएं और कुछ वार्तालाप करें और यहां कोचिंग के लिए वापस आएं",
    speak_help:
      "कहें 'पेरिस, फ्रांस में एफिल टॉवर के पास एक स्थानीय से बात करें'",
    home_help: "अन्य उपयोगकर्ताओं द्वारा प्रकाशित वार्तालाप को सुनें",
    ask_coach: "कोच से पूछें",
    coach_header: "क्यू कार्ड कोच",
    cue_card_suggestion: "क्यू कार्ड सुझाव",
    conversation_suggestion: "वार्तालाप सुझाव",
    tut_overview:
      "YouSpeak.ai दुनिया को भाषा के माध्यम से जोड़ना चाहता है। एक दिन आप जिन स्थानों को देखना चाहते हैं, उन स्थानों की जाँच करके एक भाषा सीखें। विदेशियों के साथ अनुकरणीय वार्तालाप करें। आप जिन शब्दों को नहीं जानते हैं उन्हें हाईलाइट करके क्यू कार्ड बनाएं। बाधाओं के बीच में मदद के लिए और आपको आगे बढ़ाने के लिए एक कोच बनाएं।",
    tut_explore:
      "आप जिन स्थानों को देखना चाहते हैं उनकी जांच करें। नक्शे के चारों ओर स्क्रॉल करें और स्थानों पर क्लिक करें। उस स्थान पर अनुकरणीय वार्तालाप करने के लिए 'इसे आज़माएं' पर दबाएं।",
    tut_speak:
      "दुनिया भर में किसी से भी बातचीत करें। अपनी मातृभाषा में बोलें या विदेशी भाषा में बोलने का अभ्यास करें। अनुवादित टेक्स्ट को दिखाएं या छिपाएं \nशब्द या वाक्यांशों को हाईलाइट करके क्यू कार्ड बनाएं।",
    tut_coach:
      "कठिन अनुकरण को याद करने के लिए कोच से टिप्स मांगें। कुछ सुझाए गए क्यू कार्ड जोड़ने का प्रयास करें। कुछ सुझाए गए वार्तालाप बोलें।",
    tut_cuecard:
      "शब्दों और वाक्यांशों को याद करने के लिए क्यूकार्ड्स का उपयोग करें।",
    list: "सूची",
    native_language: "मातृभाषा",
    foreign_language: "विदेशी भाषा",
    language_code: "भाषा कोड",
    limit_reached:
      "सीमा समाप्त हो गई। कृपया 24 घंटे बाद दोबारा सीखने के लिए आएं। अधिक जानकारी के लिए upgrade@youspeak.ai पर ईमेल करें।",
  },
  is: {
    lang_not_found: "Gat ekki fundið tungumálið til að tala á",
    initialize: "Ræsir...",
    stop_recording: "Stöðva upptöku",
    processing: "Vinnur...",
    speak_native: "Tala íslensku",
    speak: "Tala",
    show_native_text: "Sýna íslensku",
    hide_native_text: "Fela íslensku",
    hide: "Fela",
    text: "Texti",
    show: "Sýna",
    no_language_found: "Gat ekki fundið tungumálið til að tala á",
    arb: "Arabíska",
    "cmn-CN": "Kínverska",
    "da-DK": "Danska",
    "nl-NL": "Hollenska",
    "en-AU": "Enska Ástralía",
    "en-GB": "Enska Bretland",
    "en-IN": "Enska Indland",
    "en-US": "Enska Bandaríkin",
    "en-GB-WLS": "Enska Bretland Wales",
    "fr-FR": "Franska Frakkland",
    "fr-CA": "Franska Kanada",
    "de-DE": "Þýska",
    "hi-IN": "Hindí",
    "is-IS": "Íslenska",
    "it-IT": "Ítalska",
    "ja-JP": "Japanska",
    "ko-KR": "Kóreska",
    "nb-NO": "Norska",
    "pl-PL": "Pólska",
    "pt-BR": "Portúgalska Brasilía",
    "pt-PT": "Portúgalska Portúgal",
    "ro-RO": "Rúmenska",
    "ru-RU": "Rússneska",
    "es-ES": "Spænska Spánn",
    "es-MX": "Spænska Mexíkó",
    "es-US": "Spænska Bandaríkin",
    "sv-SE": "Sænska",
    "tr-TR": "Tyrkneska",
    "cy-GB": "Velska",
    zh: "Kínverska",
    "zh-TW": "Hefðbundin kínverska",
    ar: "Arabíska",
    bn: "Bengalska",
    da: "Danska",
    nl: "Hollenska",
    en: "Enska",
    fr: "Franska",
    de: "Þýska",
    hi: "Hindí",
    is: "Íslenska",
    it: "Ítalska",
    ja: "Japanska",
    ko: "Kóreska",
    nb: "Norska",
    pl: "Pólska",
    pt: "Portúgalska",
    ro: "Rúmenska",
    ru: "Rússneska",
    es: "Spænska",
    sv: "Sænska",
    tr: "Tyrkneska",
    cy: "Velska",
    add_cue_card: "Bæta við bendi korti",
    dismiss: "Hafna",
    flip: "Snúa við",
    next: "Næsta",
    correct: "Rétt",
    incorrect: "Rangt",
    favorite: "Uppáhald",
    archive: "Geyma",
    home: "Heim",
    play: "Spila",
    cue_card: "Kort",
    history: "Saga",
    search: "Leita",
    publish: "Birta",
    comment: "Athugasemd",
    submit: "Senda inn",
    numLikes: "Læk",
    settings: "Stillingar",
    language: "Tungumál",
    pick_language: "Velja sýningartungumál",
    try_this: "Reyna þetta",
    sign_out: "Skrá út",
    sign_in: "Skrá inn",
    sign_up: "Skrá sig",
    username: "Notendanafn",
    screenname: "Skjánafn",
    save: "Vista",
    save_cue_card:
      "Bæta við bendi korti með því að merkja erlendan texta í samtali",
    explore: "Kanna",
    have_conversation: "Hafa samtal með einhverjum hjá",
    coach: "Þjálfari",
    explore_help: "Segðu 'St. Péturskirkjan í Vatíkaninu'",
    coach_help:
      "Farðu í Tal flipa og hafðu nokkur samtöl og komdu svo hingað aftur til að fá leiðsögn",
    speak_help:
      "Segðu 'talaðu við heimamann við Eiffelturninn í París, Frakklandi'",
    home_help: "Hlustaðu á birt samtöl frá öðrum notendum",
    ask_coach: "Spyrja þjálfara",
    coach_header: "Bendikort þjálfari",
    cue_card_suggestion: "Tillögur um bendi kort",
    conversation_suggestion: "Tillögur um samtal",
    tut_overview:
      "YouSpeak.ai vill tengja heiminn í gegnum tungumál. Læra tungumál með því að kanna staði sem þú vilt sjá einn dag. Hafðu eftirlíkingar samtöl við útlendinga. Búðu til bendikort með því að merkja orð sem þú þekkir ekki. Hafðu þjálfara til að leiða þig í gegnum hindranir og ögra þér til að ná lengra.",
    tut_explore:
      "Kanna staði sem þú vilt heimsækja. Skrunaðu um kortið og smelltu á staði. Ýttu á 'Reyna þetta' til að hafa eftirlíkingar samtal á þeim stað.",
    tut_speak:
      "Hafðu samtal við hvern sem er um allan heim. Talaðu á móðurmáli þínu eða æfa þig að tala á erlendu tungumáli. Sýna eða fela þýddan texta. Merktu orð eða setningar til að búa til bendikort.",
    tut_coach:
      "Spyrðu þjálfara um ráð við að muna erfiða orðatiltæki. Prófaðu að bæta við nokkrum tillögum um bendikort. Prófaðu að tala nokkur tillögð samtöl.",
    tut_cuecard: "Notaðu bendikort til að hjálpa þér að muna orð og setningar.",
    list: "listi",
    native_language: "Móðurmál",
    foreign_language: "Erlent tungumál",
    language_code: "Tungumálakóði",
    limit_reached:
      "Hámark náð. Vinsamlegast komdu aftur eftir 24 klukkustundir til að halda áfram að læra. Sendu tölvupóst á upgrade@youspeak.ai til að fá frekari upplýsingar.",
  },
  it: {
    lang_not_found: "Impossibile individuare la lingua con cui conversare",
    initialize: "Inizializzazione...",
    stop_recording: "Interrompi registrazione",
    processing: "Elaborazione...",
    speak_native: "Parla italiano",
    speak: "Parla",
    show_native_text: "Mostra italiano",
    hide_native_text: "Nascondi italiano",
    hide: "Nascondi",
    text: "Testo",
    show: "Mostra",
    no_language_found: "Impossibile individuare la lingua con cui conversare",
    arb: "Arabo",
    "cmn-CN": "Cinese",
    "da-DK": "Danese",
    "nl-NL": "Olandese",
    "en-AU": "Inglese Australia",
    "en-GB": "Inglese Regno Unito",
    "en-IN": "Inglese India",
    "en-US": "Inglese Stati Uniti",
    "en-GB-WLS": "Inglese Regno Unito Gallese",
    "fr-FR": "Francese Francia",
    "fr-CA": "Francese Canada",
    "de-DE": "Tedesco",
    "hi-IN": "Hindi",
    "is-IS": "Islandese",
    "it-IT": "Italiano",
    "ja-JP": "Giapponese",
    "ko-KR": "Coreano",
    "nb-NO": "Norvegese",
    "pl-PL": "Polacco",
    "pt-BR": "Portoghese Brasile",
    "pt-PT": "Portoghese Portogallo",
    "ro-RO": "Rumeno",
    "ru-RU": "Russo",
    "es-ES": "Spagnolo Spagna",
    "es-MX": "Spagnolo Messico",
    "es-US": "Spagnolo Stati Uniti",
    "sv-SE": "Svedese",
    "tr-TR": "Turco",
    "cy-GB": "Gallese",
    zh: "Cinese",
    "zh-TW": "Cinese tradizionale",
    ar: "Arabo",
    bn: "Bengalese",
    da: "Danese",
    nl: "Olandese",
    en: "Inglese",
    fr: "Francese",
    de: "Tedesco",
    hi: "Hindi",
    is: "Islandese",
    it: "Italiano",
    ja: "Giapponese",
    ko: "Coreano",
    nb: "Norvegese",
    pl: "Polacco",
    pt: "Portoghese",
    ro: "Rumeno",
    ru: "Russo",
    es: "Spagnolo",
    sv: "Svedese",
    tr: "Turco",
    cy: "Gallese",
    add_cue_card: "Aggiungi scheda",
    dismiss: "Annulla",
    flip: "Capovolgi",
    next: "Avanti",
    correct: "Corretto",
    incorrect: "Errato",
    favorite: "Preferito",
    archive: "Archivio",
    home: "Home",
    play: "Gioca",
    cue_card: "Scheda",
    history: "Cronologia",
    search: "Cerca",
    publish: "Pubblica",
    comment: "Commenta",
    submit: "Invia",
    numLikes: "Mi piace",
    settings: "Impostazioni",
    language: "Lingua",
    pick_language: "Seleziona lingua di visualizzazione",
    try_this: "Prova questo",
    sign_out: "Disconnetti",
    sign_in: "Accedi",
    sign_up: "Registrati",
    username: "Nome utente",
    screenname: "Nome schermata",
    save: "Salva",
    save_cue_card:
      "Aggiungi scheda evidenziando il testo straniero in una conversazione",
    explore: "Esplora",
    have_conversation: "Conversa con qualcuno a",
    coach: "Allenatore",
    explore_help: "Di' 'La Basilica di San Pietro in Vaticano'",
    coach_help:
      "Vai alla scheda Parla, fai qualche conversazione e torna qui per ricevere suggerimenti",
    speak_help:
      "Di' 'parla con un locale vicino alla Torre Eiffel a Parigi, Francia'",
    home_help: "Ascolta le conversazioni pubblicate da altri utenti",
    ask_coach: "Chiedi all'allenatore",
    coach_header: "Allenatore delle schede",
    cue_card_suggestion: "Suggerimenti per le schede",
    conversation_suggestion: "Suggerimenti per la conversazione",
    tut_overview:
      "YouSpeak.ai vuole connettere il mondo attraverso la lingua. Impara una lingua esplorando i posti che vuoi vedere un giorno. Fai conversazioni simulate con stranieri. Crea schede evidenziando parole che non conosci. Ricevi suggerimenti dall'allenatore per superare gli ostacoli e raggiungere nuovi obiettivi.",
    tut_explore:
      "Esplora i posti che vuoi visitare. Scorri la mappa e clicca sui luoghi. Premi 'Prova questo' per avere una conversazione simulata in quel luogo.",
    tut_speak:
      "Conversa con chiunque nel mondo. Parla nella tua lingua madre o pratica una lingua straniera. Mostra o nascondi il testo tradotto. Evidenzia parole o frasi per creare schede.",
    tut_coach:
      "Chiedi suggerimenti all'allenatore per memorizzare un'idioma difficile. Prova ad aggiungere alcune schede suggerite. Prova a parlare di alcune conversazioni suggerite.",
    tut_cuecard: "Usa le schede per aiutarti a memorizzare parole e frasi.",
    list: "lista",
    native_language: "Lingua madre",
    foreign_language: "Lingua straniera",
    language_code: "Codice lingua",
    limit_reached:
      "Limite raggiunto. Torna dopo 24 ore per riprendere l'apprendimento. Invia un'email a upgrade@youspeak.ai per maggiori informazioni.",
  },
  ja: {
    lang_not_found: "どの言語で会話するのか見つけられなかった",
    initialize: "初期化中...",
    stop_recording: "録音を停止",
    processing: "処理中...",
    speak_native: "日本語を話す",
    speak: "話す",
    show_native_text: "日本語のテキストを表示",
    hide_native_text: "日本語のテキストを非表示",
    hide: "非表示",
    text: "テキスト",
    show: "表示",
    no_language_found: "どの言語で会話するのか見つけられなかった",
    arb: "アラビア語",
    "cmn-CN": "中国語",
    "da-DK": "デンマーク語",
    "nl-NL": "オランダ語",
    "en-AU": "オーストラリアの英語",
    "en-GB": "イギリスの英語",
    "en-IN": "インドの英語",
    "en-US": "アメリカの英語",
    "en-GB-WLS": "イギリスの英語（ウェールズ）",
    "fr-FR": "フランスのフランス語",
    "fr-CA": "カナダのフランス語",
    "de-DE": "ドイツ語",
    "hi-IN": "ヒンディー語",
    "is-IS": "アイスランド語",
    "it-IT": "イタリア語",
    "ja-JP": "日本語",
    "ko-KR": "韓国語",
    "nb-NO": "ノルウェー語",
    "pl-PL": "ポーランド語",
    "pt-BR": "ブラジルのポルトガル語",
    "pt-PT": "ポルトガルのポルトガル語",
    "ro-RO": "ルーマニア語",
    "ru-RU": "ロシア語",
    "es-ES": "スペインのスペイン語",
    "es-MX": "メキシコのスペイン語",
    "es-US": "アメリカのスペイン語",
    "sv-SE": "スウェーデン語",
    "tr-TR": "トルコ語",
    "cy-GB": "ウェールズ語",
    zh: "中国語",
    "zh-TW": "繁体字中国語",
    ar: "アラビア語",
    bn: "ベンガル語",
    da: "デンマーク語",
    nl: "オランダ語",
    en: "英語",
    fr: "フランス語",
    de: "ドイツ語",
    hi: "ヒンディー語",
    is: "アイスランド語",
    it: "イタリア語",
    ja: "日本語",
    ko: "韓国語",
    nb: "ノルウェー語",
    pl: "ポーランド語",
    pt: "ポルトガル語",
    ro: "ルーマニア語",
    ru: "ロシア語",
    es: "スペイン語",
    sv: "スウェーデン語",
    tr: "トルコ語",
    cy: "ウェールズ語",
    add_cue_card: "キューカードを追加",
    dismiss: "閉じる",
    flip: "反転",
    next: "次",
    correct: "正解",
    incorrect: "不正解",
    favorite: "お気に入り",
    archive: "アーカイブ",
    home: "ホーム",
    play: "再生",
    cue_card: "カード",
    history: "履歴",
    search: "検索",
    publish: "公開",
    comment: "コメント",
    submit: "送信",
    numLikes: "いいね",
    settings: "設定",
    language: "言語",
    pick_language: "表示言語を選択",
    try_this: "これを試す",
    sign_out: "サインアウト",
    sign_in: "サインイン",
    sign_up: "サインアップ",
    username: "ユーザー名",
    screenname: "スクリーンネーム",
    save: "保存",
    save_cue_card:
      "会話の中で外国語のテキストをハイライトしてキューカードを追加",
    explore: "探検する",
    have_conversation: "誰かと会話する",
    coach: "コーチ",
    explore_help: "「バチカン市国のサン・ピエトロ大聖堂」を言う",
    coach_help:
      "スピークタブに移動して会話をいくつか行い、ここに戻ってコーチングを受ける",
    speak_help:
      "「フランスのパリにあるエッフェル塔の近くで地元の人と話す」を言う",
    home_help: "他のユーザーによる公開された会話を聴く",
    ask_coach: "コーチに聞く",
    coach_header: "キューカード コーチ",
    cue_card_suggestion: "キューカードの提案",
    conversation_suggestion: "会話の提案",
    tut_overview:
      "YouSpeak.aiは言語を通じて世界をつなげたいと考えています。  いつか訪れたい場所を探検して言語を学びます。  外国人とのシミュレーション会話を行います。  知らない言葉をハイライトしてキューカードを作成します。  コーチが障害を乗り越えるための支援を提供し、さらに上を目指すようにチャレンジさせます。",
    tut_explore:
      "訪れたい場所を探検します。  地図をスクロールして場所をクリックします。 その場所でシミュレートされた会話を試すために「これを試す」を押します。",
    tut_speak:
      "世界中の誰とでも会話をします。  母国語で話すか、外国語での会話を練習します。  翻訳されたテキストを表示または非表示にします。 フレーズをハイライトしてキューカードを作成します。",
    tut_coach:
      "難しいイディオムを覚えるためのヒントをコーチに尋ねます。  提案されたキューカードをいくつか追加してみます。 提案された会話をいくつか話してみます。",
    tut_cuecard: "キューカードを使用して単語やフレーズを覚えます。",
    list: "リスト",
    native_language: "母国語",
    foreign_language: "外国語",
    language_code: "言語コード",
    limit_reached:
      "制限に達しました。  24時間後に学習を再開してください。 詳細についてはupgrade@youspeak.aiまでメールしてください。",
  },
  ko: {
    lang_not_found: "어떤 언어로 대화해야 할지 찾을 수 없습니다",
    initialize: "초기화 중...",
    stop_recording: "녹음 중지",
    processing: "처리 중...",
    speak_native: "한국어로 말하기",
    speak: "말하다",
    show_native_text: "한국어 텍스트 보기",
    hide_native_text: "한국어 텍스트 숨기기",
    hide: "숨기다",
    text: "텍스트",
    show: "보여주다",
    no_language_found: "어떤 언어로 대화해야 할지 찾을 수 없습니다",
    arb: "아랍어",
    "cmn-CN": "중국어",
    "da-DK": "덴마크어",
    "nl-NL": "네덜란드어",
    "en-AU": "영어 호주",
    "en-GB": "영어 영국",
    "en-IN": "영어 인도",
    "en-US": "영어 미국",
    "en-GB-WLS": "영어 영국 웨일스",
    "fr-FR": "프랑스어 프랑스",
    "fr-CA": "프랑스어 캐나다",
    "de-DE": "독일어",
    "hi-IN": "힌디어",
    "is-IS": "아이슬란드어",
    "it-IT": "이탈리아어",
    "ja-JP": "일본어",
    "ko-KR": "한국어",
    "nb-NO": "노르웨이어",
    "pl-PL": "폴란드어",
    "pt-BR": "포르투갈어 브라질",
    "pt-PT": "포르투갈어 포르투갈",
    "ro-RO": "루마니아어",
    "ru-RU": "러시아어",
    "es-ES": "스페인어 스페인",
    "es-MX": "스페인어 멕시코",
    "es-US": "스페인어 미국",
    "sv-SE": "스웨덴어",
    "tr-TR": "터키어",
    "cy-GB": "웨일스어",
    zh: "중국어",
    "zh-TW": "중국어(번체)",
    ar: "아랍어",
    bn: "벵골어",
    da: "덴마크어",
    nl: "네덜란드어",
    en: "영어",
    fr: "프랑스어",
    de: "독일어",
    hi: "힌디어",
    is: "아이슬란드어",
    it: "이탈리아어",
    ja: "일본어",
    ko: "한국어",
    nb: "노르웨이어",
    pl: "폴란드어",
    pt: "포르투갈어",
    ro: "루마니아어",
    ru: "러시아어",
    es: "스페인어",
    sv: "스웨덴어",
    tr: "터키어",
    cy: "웨일스어",
    add_cue_card: "큐카드 추가",
    dismiss: "무시",
    flip: "뒤집기",
    next: "다음",
    correct: "정답",
    incorrect: "오답",
    favorite: "즐겨찾기",
    archive: "보관",
    home: "홈",
    play: "재생",
    cue_card: "카드",
    history: "기록",
    search: "검색",
    publish: "게시",
    comment: "댓글",
    submit: "제출",
    numLikes: "좋아요",
    settings: "설정",
    language: "언어",
    pick_language: "표시 언어 선택",
    try_this: "이거 해봐",
    sign_out: "로그아웃",
    sign_in: "로그인",
    sign_up: "가입",
    username: "사용자 이름",
    screenname: "화면 이름",
    save: "저장",
    save_cue_card: "대화 중에 외국어 텍스트를 강조하여 큐카드 추가",
    explore: "탐색",
    have_conversation: "누군가와 대화하다",
    coach: "코치",
    explore_help: "예) '바티칸 시티에 있는 성 베드로 대성당'",
    coach_help:
      "말하기 탭으로 가서 몇 번 대화하고 여기로 돌아와 코치의 조언을 얻으세요",
    speak_help: "예) '프랑스 파리의 에펠탑에서 현지인과 대화하세요'",
    home_help: "다른 사용자가 게시한 대화를 들어보세요",
    ask_coach: "코치에게 물어보기",
    coach_header: "큐 카드 코치",
    cue_card_suggestion: "큐카드 제안",
    conversation_suggestion: "대화 제안",
    tut_overview:
      "YouSpeak.ai는 언어를 통해 세상을 연결하고자 합니다. 언젠가 보고 싶은 곳을 탐험하며 언어를 배우세요. 외국인과의 모의 대화를 하세요. 모르는 단어를 강조하여 큐카드를 만드세요. 코치에게 조언을 받고 더 멀리 나아가도록 도전하세요.",
    tut_explore:
      "가보고 싶은 곳을 탐험하세요. 지도를 둘러보고 다양한 장소를 클릭하세요. '이거 해봐'를 눌러 해당 장소에서 모의 대화를 해보세요.",
    tut_speak:
      "전 세계의 누구와도 대화하세요. 모국어로 말하거나 외국어로 말하기를 연습하세요. 번역된 텍스트를 보이거나 숨깁니다. 큐카드를 만들기 위해 단어나 구를 강조하세요.",
    tut_coach:
      "어려운 관용구를 외우는 팁을 코치에게 물어보세요. 제안된 큐카드를 추가해보세요. 제안된 대화를 말해보세요.",
    tut_cuecard: "단어와 구를 외우는 데 큐카드를 사용하세요.",
    list: "목록",
    native_language: "모국어",
    foreign_language: "외국어",
    language_code: "언어 코드",
    limit_reached:
      "한도에 도달했습니다. 24시간 후에 학습을 재개해주세요. 추가 정보를 원하시면 upgrade@youspeak.ai로 이메일을 보내주세요.",
  },
  nb: {
    lang_not_found: "Kunne ikke finne hvilket språk å snakke på",
    initialize: "Initialiserer...",
    stop_recording: "Stopp opptak",
    processing: "Behandler...",
    speak_native: "Snakk norsk",
    speak: "Snakk",
    show_native_text: "Vis norsk",
    hide_native_text: "Skjul norsk",
    hide: "Skjul",
    text: "Tekst",
    show: "Vis",
    no_language_found: "Kunne ikke finne hvilket språk å snakke på",
    arb: "Arabisk",
    "cmn-CN": "Kinesisk",
    "da-DK": "Dansk",
    "nl-NL": "Nederlandsk",
    "en-AU": "Engelsk Australia",
    "en-GB": "Engelsk UK",
    "en-IN": "Engelsk India",
    "en-US": "Engelsk USA",
    "en-GB-WLS": "Engelsk UK Welsh",
    "fr-FR": "Fransk Frankrike",
    "fr-CA": "Fransk Canada",
    "de-DE": "Tysk",
    "hi-IN": "Hindi",
    "is-IS": "Islandsk",
    "it-IT": "Italiensk",
    "ja-JP": "Japansk",
    "ko-KR": "Koreansk",
    "nb-NO": "Norsk",
    "pl-PL": "Polsk",
    "pt-BR": "Portugisisk Brasil",
    "pt-PT": "Portugisisk Portugal",
    "ro-RO": "Rumensk",
    "ru-RU": "Russisk",
    "es-ES": "Spansk Spania",
    "es-MX": "Spansk Mexico",
    "es-US": "Spansk USA",
    "sv-SE": "Svensk",
    "tr-TR": "Tyrkisk",
    "cy-GB": "Walisisk",
    zh: "Kinesisk",
    "zh-TW": "Tradisjonell kinesisk",
    ar: "Arabisk",
    bn: "Bengali",
    da: "Dansk",
    nl: "Nederlandsk",
    en: "Engelsk",
    fr: "Fransk",
    de: "Tysk",
    hi: "Hindi",
    is: "Islandsk",
    it: "Italiensk",
    ja: "Japansk",
    ko: "Koreansk",
    nb: "Norsk",
    pl: "Polsk",
    pt: "Portugisisk",
    ro: "Rumensk",
    ru: "Russisk",
    es: "Spansk",
    sv: "Svensk",
    tr: "Tyrkisk",
    cy: "Walisisk",
    add_cue_card: "Legg til kort",
    dismiss: "Avvis",
    flip: "Vend",
    next: "Neste",
    correct: "Riktig",
    incorrect: "Feil",
    favorite: "Favorit",
    archive: "Arkiv",
    home: "Hjem",
    play: "Spill",
    cue_card: "Kort",
    history: "Historie",
    search: "Søk",
    publish: "Publiser",
    comment: "Kommentar",
    submit: "Send inn",
    numLikes: "Likes",
    settings: "Innstillinger",
    language: "Språk",
    pick_language: "Velg språk",
    try_this: "Prøv dette",
    sign_out: "Logg ut",
    sign_in: "Logg inn",
    sign_up: "Registrer",
    username: "Brukernavn",
    screenname: "Skjermnavn",
    save: "Lagre",
    save_cue_card: "Legg til kort ved å markere fremmed tekst i en samtale",
    explore: "Utforsk",
    have_conversation: "Ha en samtale med noen på",
    coach: "Trener",
    explore_help: "Si 'St. Peter’s Basilica i Vatikanstaten'",
    coach_help:
      "Gå til Snakk fanen og ha noen samtaler og kom tilbake her for noen råd.",
    speak_help: "Si 'snakke med en lokal ved Eiffeltårnet i Paris, Frankrike'",
    home_help: "Lytt til publiserte samtaler av andre brukere",
    ask_coach: "Spør treneren",
    coach_header: "Kort trener",
    cue_card_suggestion: "Kort forslag",
    conversation_suggestion: "Samtale forslag",
    tut_overview:
      "YouSpeak.ai ønsker å koble verden gjennom språk. Lær et språk ved å utforske steder du ønsker å se en dag. Ha simulerte samtaler med utlendinger. Lag kort ved å markere ord du ikke vet. Ha en trener for å komme deg gjennom snublesteiner og utfordre deg til å nå lengre.",
    tut_explore:
      "Utforsk steder du vil besøke. Rull rundt på kartet og klikk på steder. Trykk på 'Prøv dette' for å ha en simulert samtale på det stedet.",
    tut_speak:
      "Ha en samtale med noen over hele verden. Snakk på ditt morsmål eller øv på å snakke på et fremmed språk. Vis eller skjul oversatt tekst. Marker ord eller setninger for å opprette kort.",
    tut_coach:
      "Spør treneren om tips for å huske en vanskelig idiom. Prøv å legge til noen foreslåtte kort. Prøv å si noen foreslåtte samtaler.",
    tut_cuecard: "Bruk kort for å hjelpe deg med å huske ord og setninger.",
    list: "liste",
    native_language: "Morsmål",
    foreign_language: "Fremmed språk",
    language_code: "Språkkode",
    limit_reached:
      "Grensen nådd. Vennligst kom tilbake etter 24 timer for å fortsette læringen. Send e-post til upgrade@youspeak.ai for mer informasjon.",
  },
  pl: {
    lang_not_found: "Nie można znaleźć, w jakim języku rozmawiać",
    initialize: "Inicjalizacja...",
    stop_recording: "Zatrzymaj nagrywanie",
    processing: "Przetwarzanie...",
    speak_native: "Mów po polsku",
    speak: "Mów",
    show_native_text: "Pokaż tekst po polsku",
    hide_native_text: "Ukryj tekst po polsku",
    hide: "Ukryj",
    text: "Tekst",
    show: "Pokaż",
    no_language_found: "Nie można znaleźć, w jakim języku rozmawiać",
    arb: "Arabski",
    "cmn-CN": "Chiński",
    "da-DK": "Duński",
    "nl-NL": "Holenderski",
    "en-AU": "Angielski Australia",
    "en-GB": "Angielski UK",
    "en-IN": "Angielski Indie",
    "en-US": "Angielski US",
    "en-GB-WLS": "Angielski UK Wallijski",
    "fr-FR": "Francuski Francja",
    "fr-CA": "Francuski Kanada",
    "de-DE": "Niemiecki",
    "hi-IN": "Hindi",
    "is-IS": "Islandzki",
    "it-IT": "Włoski",
    "ja-JP": "Japoński",
    "ko-KR": "Koreański",
    "nb-NO": "Norweski",
    "pl-PL": "Polski",
    "pt-BR": "Portugalski Brazylia",
    "pt-PT": "Portugalski Portugalia",
    "ro-RO": "Rumuński",
    "ru-RU": "Rosyjski",
    "es-ES": "Hiszpański Hiszpania",
    "es-MX": "Hiszpański Meksyk",
    "es-US": "Hiszpański US",
    "sv-SE": "Szwedzki",
    "tr-TR": "Turecki",
    "cy-GB": "Walijski",
    zh: "Chiński",
    "zh-TW": "Tradycyjny chiński",
    ar: "Arabski",
    bn: "Bengalski",
    da: "Duński",
    nl: "Holenderski",
    en: "Angielski",
    fr: "Francuski",
    de: "Niemiecki",
    hi: "Hindi",
    is: "Islandzki",
    it: "Włoski",
    ja: "Japoński",
    ko: "Koreański",
    nb: "Norweski",
    pl: "Polski",
    pt: "Portugalski",
    ro: "Rumuński",
    ru: "Rosyjski",
    es: "Hiszpański",
    sv: "Szwedzki",
    tr: "Turecki",
    cy: "Walijski",
    add_cue_card: "Dodaj kartę podpowiedzi",
    dismiss: "Odrzuć",
    flip: "Przewróć",
    next: "Następny",
    correct: "Poprawny",
    incorrect: "Niepoprawny",
    favorite: "Ulubiony",
    archive: "Archiwum",
    home: "Strona główna",
    play: "Odtwórz",
    cue_card: "Karta",
    history: "Historia",
    search: "Szukaj",
    publish: "Publikuj",
    comment: "Komentarz",
    submit: "Zatwierdź",
    numLikes: "Polubienia",
    settings: "Ustawienia",
    language: "Język",
    pick_language: "Wybierz język wyświetlania",
    try_this: "Spróbuj tego",
    sign_out: "Wyloguj się",
    sign_in: "Zaloguj się",
    sign_up: "Zarejestruj się",
    username: "Nazwa użytkownika",
    screenname: "Nazwa ekranu",
    save: "Zapisz",
    save_cue_card: "Dodaj kartę podpowiedzi, zaznaczając obcy tekst w rozmowie",
    explore: "Eksploruj",
    have_conversation: "Rozmawiaj z kimś w",
    coach: "Trener",
    explore_help: "Powiedz 'Bazylika św. Piotra w Watykanie'",
    coach_help:
      "Przejdź do zakładki Mów i prowadź rozmowy, a następnie wróć tutaj po porady od trenera",
    speak_help:
      "Powiedz 'porozmawiaj z miejscowym pod Wieżą Eiffla w Paryżu, Francja'",
    home_help: "Słuchaj opublikowanych rozmów innych użytkowników",
    ask_coach: "Zapytaj trenera",
    coach_header: "Trener Kart Podpowiedzi",
    cue_card_suggestion: "Sugestie Kart Podpowiedzi",
    conversation_suggestion: "Sugestie rozmów",
    tut_overview:
      "YouSpeak.ai chce połączyć świat przez język. Naucz się języka, eksplorując miejsca, które chcesz zobaczyć pewnego dnia. Prowadź symulowane rozmowy z obcokrajowcami. Twórz karty podpowiedzi, zaznaczając niezrozumiałe słowa. Miej trenera, który pomoże ci pokonać trudności i zachęci cię do dalszych osiągnięć.",
    tut_explore:
      "Eksploruj miejsca, które chcesz odwiedzić. Przeglądaj mapę i klikaj na miejsca. Wciśnij 'Spróbuj tego', aby przeprowadzić symulowaną rozmowę w wybranym miejscu.",
    tut_speak:
      "Prowadź rozmowy z każdym na świecie. Mów w swoim ojczystym języku lub ćwicz mówienie w obcym języku. Pokaż lub ukryj przetłumaczony tekst. Zaznaczaj słowa lub frazy, aby tworzyć karty podpowiedzi.",
    tut_coach:
      "Zapytaj trenera o wskazówki dotyczące zapamiętywania trudnych idiomów. Dodaj sugerowane karty podpowiedzi. Spróbuj przeprowadzić sugerowane rozmowy.",
    tut_cuecard:
      "Używaj kart podpowiedzi, aby pomóc sobie w zapamiętywaniu słów i fraz.",
    list: "lista",
    native_language: "Język ojczysty",
    foreign_language: "Język obcy",
    language_code: "Kod języka",
    limit_reached:
      "Osiągnięto limit. Proszę wrócić po 24 godzinach, aby kontynuować naukę. Aby uzyskać więcej informacji, prosimy o kontakt pod adresem upgrade@youspeak.ai.",
  },
  pt: {
    lang_not_found: "Não foi possível localizar o idioma para conversar",
    initialize: "Inicializando...",
    stop_recording: "Parar de Gravar",
    processing: "Processando...",
    speak_native: "Falar Português",
    speak: "Falar",
    show_native_text: "Mostrar Português",
    hide_native_text: "Ocultar Português",
    hide: "Ocultar",
    text: "Texto",
    show: "Mostrar",
    no_language_found: "Não foi possível localizar o idioma para conversar",
    arb: "Árabe",
    "cmn-CN": "Chinês",
    "da-DK": "Dinamarquês",
    "nl-NL": "Holandês",
    "en-AU": "Inglês Austrália",
    "en-GB": "Inglês Reino Unido",
    "en-IN": "Inglês Índia",
    "en-US": "Inglês Estados Unidos",
    "en-GB-WLS": "Inglês Reino Unido Galês",
    "fr-FR": "Francês França",
    "fr-CA": "Francês Canadá",
    "de-DE": "Alemão",
    "hi-IN": "Hindi",
    "is-IS": "Islandês",
    "it-IT": "Italiano",
    "ja-JP": "Japonês",
    "ko-KR": "Coreano",
    "nb-NO": "Norueguês",
    "pl-PL": "Polonês",
    "pt-BR": "Português Brasil",
    "pt-PT": "Português Portugal",
    "ro-RO": "Romeno",
    "ru-RU": "Russo",
    "es-ES": "Espanhol Espanha",
    "es-MX": "Espanhol México",
    "es-US": "Espanhol Estados Unidos",
    "sv-SE": "Sueco",
    "tr-TR": "Turco",
    "cy-GB": "Galês Reino Unido",
    zh: "Chinês",
    "zh-TW": "Chinês Tradicional",
    ar: "Árabe",
    bn: "Bengali",
    da: "Dinamarquês",
    nl: "Holandês",
    en: "Inglês",
    fr: "Francês",
    de: "Alemão",
    hi: "Hindi",
    is: "Islandês",
    it: "Italiano",
    ja: "Japonês",
    ko: "Coreano",
    nb: "Norueguês",
    pl: "Polonês",
    pt: "Português",
    ro: "Romeno",
    ru: "Russo",
    es: "Espanhol",
    sv: "Sueco",
    tr: "Turco",
    cy: "Galês",
    add_cue_card: "Adicionar Carta de Anotação",
    dismiss: "Dispensar",
    flip: "Virar",
    next: "Próximo",
    correct: "Correto",
    incorrect: "Incorreto",
    favorite: "Favorito",
    archive: "Arquivo",
    home: "Início",
    play: "Jogar",
    cue_card: "Carta",
    history: "História",
    search: "Buscar",
    publish: "Publicar",
    comment: "Comentar",
    submit: "Enviar",
    numLikes: "Curtidas",
    settings: "Configurações",
    language: "Idioma",
    pick_language: "Selecionar idioma de exibição",
    try_this: "Experimente Isto",
    sign_out: "Sair",
    sign_in: "Entrar",
    sign_up: "Inscrever-se",
    username: "Nome de usuário",
    screenname: "Nome de tela",
    save: "Salvar",
    save_cue_card:
      "Adicionar Carta de Anotação destacando texto estrangeiro em uma conversa",
    explore: "Explorar",
    have_conversation: "Tenha uma conversa com alguém em",
    coach: "Treinador",
    explore_help: "Diga 'Basílica de São Pedro na Cidade do Vaticano'",
    coach_help:
      "Vá para a aba Falar e tenha algumas conversas e volte aqui para alguma orientação",
    speak_help:
      "Diga 'falar com um local perto da Torre Eiffel em Paris, França'",
    home_help: "Ouça conversas publicadas por outros usuários",
    ask_coach: "Perguntar ao Treinador",
    coach_header: "Treinador de Cartas de Anotação",
    cue_card_suggestion: "Sugestões de Cartas de Anotação",
    conversation_suggestion: "Sugestões de Conversas",
    tut_overview:
      "A YouSpeak.ai quer conectar o mundo através da linguagem. Aprenda um idioma explorando lugares que você quer ver um dia. Tenha conversas simuladas com estrangeiros. Faça cartas de anotação destacando palavras que você não conhece. Tenha um treinador para ajudá-lo a superar obstáculos e desafiá-lo a ir mais longe.",
    tut_explore:
      "Explore lugares que você quer visitar. Role pelo mapa e clique em lugares. Pressione 'Experimente Isto' para ter uma conversa simulada nesse lugar.",
    tut_speak:
      "Tenha uma conversa com qualquer pessoa ao redor do mundo. Fale em sua língua nativa ou pratique falar em uma língua estrangeira. Mostrar ou ocultar o texto traduzido Destaque palavras ou frases para criar carta de anotação.",
    tut_coach:
      "Pergunte ao treinador por dicas para memorizar um idioma difícil. Tente adicionar algumas cartas de anotação sugeridas. Tente falar algumas conversas sugeridas.",
    tut_cuecard:
      "Use cartas de anotação para ajudá-lo a memorizar palavras e frases.",
    list: "lista",
    native_language: "Língua Nativa",
    foreign_language: "Língua Estrangeira",
    language_code: "Código da Língua",
    limit_reached:
      "Limite atingido. Por favor, volte em 24 horas para retomar o aprendizado. Envie e-mail para upgrade@youspeak.ai para mais informações.",
  },
  "pt-PT": {
    lang_not_found: "Não foi possível localizar o idioma para conversar",
    initialize: "Inicializando...",
    stop_recording: "Parar Gravação",
    processing: "Processando...",
    speak_native: "Fale Português",
    speak: "Falar",
    show_native_text: "Mostrar Português",
    hide_native_text: "Esconder Português",
    hide: "Esconder",
    text: "Texto",
    show: "Mostrar",
    no_language_found: "Não foi possível localizar o idioma para conversar",
    arb: "Árabe",
    "cmn-CN": "Chinês",
    "da-DK": "Dinamarquês",
    "nl-NL": "Holandês",
    "en-AU": "Inglês Austrália",
    "en-GB": "Inglês Reino Unido",
    "en-IN": "Inglês Índia",
    "en-US": "Inglês Estados Unidos",
    "en-GB-WLS": "Inglês Reino Unido Galês",
    "fr-FR": "Francês França",
    "fr-CA": "Francês Canadá",
    "de-DE": "Alemão",
    "hi-IN": "Hindi",
    "is-IS": "Islandês",
    "it-IT": "Italiano",
    "ja-JP": "Japonês",
    "ko-KR": "Coreano",
    "nb-NO": "Norueguês",
    "pl-PL": "Polonês",
    "pt-BR": "Português Brasil",
    "pt-PT": "Português Portugal",
    "ro-RO": "Romeno",
    "ru-RU": "Russo",
    "es-ES": "Espanhol Espanha",
    "es-MX": "Espanhol México",
    "es-US": "Espanhol Estados Unidos",
    "sv-SE": "Sueco",
    "tr-TR": "Turco",
    "cy-GB": "Galês",
    zh: "Chinês",
    "zh-TW": "Chinês Tradicional",
    ar: "Árabe",
    bn: "Bengali",
    da: "Dinamarquês",
    nl: "Holandês",
    en: "Inglês",
    fr: "Francês",
    de: "Alemão",
    hi: "Hindi",
    is: "Islandês",
    it: "Italiano",
    ja: "Japonês",
    ko: "Coreano",
    nb: "Norueguês",
    pl: "Polonês",
    pt: "Português",
    ro: "Romeno",
    ru: "Russo",
    es: "Espanhol",
    sv: "Sueco",
    tr: "Turco",
    cy: "Galês",
    add_cue_card: "Adicionar Cartão de Estudo",
    dismiss: "Dispensar",
    flip: "Virar",
    next: "Próximo",
    correct: "Correto",
    incorrect: "Incorreto",
    favorite: "Favorito",
    archive: "Arquivo",
    home: "Início",
    play: "Reproduzir",
    cue_card: "Cartão de Estudo",
    history: "Histórico",
    search: "Procurar",
    publish: "Publicar",
    comment: "Comentar",
    submit: "Enviar",
    numLikes: "Gostos",
    settings: "Configurações",
    language: "Idioma",
    pick_language: "Selecionar idioma de exibição",
    try_this: "Experimente isto",
    sign_out: "Sair",
    sign_in: "Entrar",
    sign_up: "Inscrever-se",
    username: "Nome de Usuário",
    screenname: "Nome de Tela",
    save: "Salvar",
    save_cue_card:
      "Adicionar Cartão de Estudo destacando texto estrangeiro em uma conversa",
    explore: "Explorar",
    have_conversation: "Tenha uma conversa com alguém em",
    coach: "Treinador",
    explore_help: "Diga 'Basílica de São Pedro na Cidade do Vaticano'",
    coach_help:
      "Vá até a guia Falar e tenha algumas conversas, depois volte aqui para algumas dicas",
    speak_help: "Diga 'fale com um local na Torre Eiffel em Paris, França'",
    home_help: "Ouça conversas publicadas por outros usuários",
    ask_coach: "Pergunte ao Treinador",
    coach_header: "Treinador de Cartão de Estudo",
    cue_card_suggestion: "Sugestões de Cartão de Estudo",
    conversation_suggestion: "Sugestões de Conversação",
    tut_overview:
      "YouSpeak.ai quer conectar o mundo através da linguagem. Aprenda um idioma explorando lugares que deseja ver um dia. Tenha conversas simuladas com estrangeiros. Crie cartões de estudo destacando palavras que você não conhece. Tenha um treinador para ajudá-lo a superar obstáculos e desafiá-lo a ir mais longe.",
    tut_explore:
      "Explore lugares que você deseja visitar. Role pelo mapa e clique em lugares. Pressione 'Experimente isto' para ter uma conversa simulada nesse lugar.",
    tut_speak:
      "Tenha uma conversa com qualquer pessoa ao redor do mundo. Fale em sua língua nativa ou pratique falar em uma língua estrangeira. Mostre ou esconda o texto traduzido. Destaque palavras ou frases para criar cartão de estudo.",
    tut_coach:
      "Peça dicas ao treinador para memorizar um idiom difícil. Tente adicionar alguns cartões de estudo sugeridos. Experimente falar algumas conversas sugeridas.",
    tut_cuecard:
      "Use cartões de estudo para ajudá-lo a memorizar palavras e frases.",
    list: "lista",
    native_language: "Idioma Nativo",
    foreign_language: "Idioma Estrangeiro",
    language_code: "Código de Idioma",
    limit_reached:
      "Limite atingido. Por favor, volte após 24 horas para continuar aprendendo. Envie um e-mail para upgrade@youspeak.ai para mais informações.",
  },
  ro: {
    lang_not_found: "Nu s-a putut găsi în ce limbă să se converseze",
    initialize: "Inițializare...",
    stop_recording: "Opriți înregistrarea",
    processing: "Procesare...",
    speak_native: "Vorbește Română",
    speak: "Vorbește",
    show_native_text: "Afișați Română",
    hide_native_text: "Ascundeți Română",
    hide: "Ascunde",
    text: "Text",
    show: "Afișați",
    no_language_found: "Nu s-a putut găsi în ce limbă să se converseze",
    arb: "Arabă",
    "cmn-CN": "Chineză",
    "da-DK": "Daneză",
    "nl-NL": "Olandeză",
    "en-AU": "Engleză Australia",
    "en-GB": "Engleză UK",
    "en-IN": "Engleză India",
    "en-US": "Engleză SUA",
    "en-GB-WLS": "Engleză UK Galeză",
    "fr-FR": "Franceză Franța",
    "fr-CA": "Franceză Canada",
    "de-DE": "Germană",
    "hi-IN": "Hindusă",
    "is-IS": "Islandeză",
    "it-IT": "Italiană",
    "ja-JP": "Japoneză",
    "ko-KR": "Coreeană",
    "nb-NO": "Norvegiană",
    "pl-PL": "Poloneză",
    "pt-BR": "Portugheză Brazilia",
    "pt-PT": "Portugheză Portugalia",
    "ro-RO": "Română",
    "ru-RU": "Rusă",
    "es-ES": "Spaniolă Spania",
    "es-MX": "Spaniolă Mexic",
    "es-US": "Spaniolă SUA",
    "sv-SE": "Suedeză",
    "tr-TR": "Turcă",
    "cy-GB": "Galeză",
    zh: "Chineză",
    "zh-TW": "Chineză Tradițională",
    ar: "Arabă",
    bn: "Bengaleză",
    da: "Daneză",
    nl: "Olandeză",
    en: "Engleză",
    fr: "Franceză",
    de: "Germană",
    hi: "Hindusă",
    is: "Islandeză",
    it: "Italiană",
    ja: "Japoneză",
    ko: "Coreeană",
    nb: "Norvegiană",
    pl: "Poloneză",
    pt: "Portugheză",
    ro: "Română",
    ru: "Rusă",
    es: "Spaniolă",
    sv: "Suedeză",
    tr: "Turcă",
    cy: "Galeză",
    add_cue_card: "Adăugați cartonaș",
    dismiss: "Ignorați",
    flip: "Întoarceți",
    next: "Următorul",
    correct: "Corect",
    incorrect: "Incorect",
    favorite: "Preferat",
    archive: "Arhivă",
    home: "Acasă",
    play: "Redați",
    cue_card: "Cartonaș",
    history: "Istorie",
    search: "Căutare",
    publish: "Publicați",
    comment: "Comentariu",
    submit: "Trimiteți",
    numLikes: "Aprecieri",
    settings: "Setări",
    language: "Limbă",
    pick_language: "Selectați limba afișajului",
    try_this: "Încercați acest lucru",
    sign_out: "Deconectare",
    sign_in: "Conectare",
    sign_up: "Înregistrare",
    username: "Nume utilizator",
    screenname: "Nume ecran",
    save: "Salvare",
    save_cue_card:
      "Adăugați cartonaș prin evidențierea unui text străin într-o conversație",
    explore: "Explorați",
    have_conversation: "Aveți o conversație cu cineva la",
    coach: "Antrenor",
    explore_help: "Spuneți 'Bazilica Sfântul Petru în Vatican'",
    coach_help:
      "Accesați fila Vorbește și aveți câteva conversații, apoi reveniți aici pentru antrenare",
    speak_help:
      "Spuneți 'vorbiți cu un localnic lângă Turnul Eiffel din Paris, Franța'",
    home_help: "Ascultați conversații publicate de alți utilizatori",
    ask_coach: "Întrebați antrenorul",
    coach_header: "Antrenor de cartonașe",
    cue_card_suggestion: "Sugestii de cartonașe",
    conversation_suggestion: "Sugestii de conversație",
    tut_overview:
      "YouSpeak.ai vrea să conecteze lumea prin limbaj. Învățați o limbă explorând locuri pe care doriți să le vedeți într-o zi. Aveți conversații simulate cu străini. Creați cartonașe evidențiind cuvinte pe care nu le cunoașteți. Aveți un antrenor pentru a vă ajuta să treceți peste blocajele și pentru a vă provoca să mergeți mai departe.",
    tut_explore:
      "Explorați locuri pe care doriți să le vizitați. Derulați harta și faceți clic pe locuri. Apăsați 'Încercați acest lucru' pentru a avea o conversație simulată în acel loc.",
    tut_speak:
      "Aveți o conversație cu oricine din lumea întreagă. Vorbiți în limba dvs. maternă sau exersați vorbind într-o limbă străină. Afișați sau ascundeți textul tradus. Evidențiați cuvinte sau fraze pentru a crea cartonașe.",
    tut_coach:
      "Întrebați antrenorul pentru sfaturi privind memorarea unui idiom dificil. Încercați să adăugați câteva cartonașe sugerate. Încercați să vorbiți câteva conversații sugerate.",
    tut_cuecard:
      "Utilizați cartonașele pentru a vă ajuta să memorați cuvinte și expresii.",
    list: "listă",
    native_language: "Limbă Maternă",
    foreign_language: "Limbă Străină",
    language_code: "Cod Limbă",
    limit_reached:
      "Limita atinsă. Vă rugăm să reveniți după 24 de ore pentru a continua învățarea. Trimiteți un email la upgrade@youspeak.ai pentru mai multe informații.",
  },
  ru: {
    lang_not_found: "Не удалось определить, на каком языке вести беседу",
    initialize: "Инициализация...",
    stop_recording: "Остановить запись",
    processing: "Обработка...",
    speak_native: "Говорите по-русски",
    speak: "Говорить",
    show_native_text: "Показать русский текст",
    hide_native_text: "Скрыть русский текст",
    hide: "Скрыть",
    text: "Текст",
    show: "Показать",
    no_language_found: "Не удалось определить, на каком языке вести беседу",
    arb: "Арабский",
    "cmn-CN": "Китайский",
    "da-DK": "Датский",
    "nl-NL": "Нидерландский",
    "en-AU": "Английский Австралия",
    "en-GB": "Английский Великобритания",
    "en-IN": "Английский Индия",
    "en-US": "Английский США",
    "en-GB-WLS": "Английский Великобритания Уэльский",
    "fr-FR": "Французский Франция",
    "fr-CA": "Французский Канада",
    "de-DE": "Немецкий",
    "hi-IN": "Хинди",
    "is-IS": "Исландский",
    "it-IT": "Итальянский",
    "ja-JP": "Японский",
    "ko-KR": "Корейский",
    "nb-NO": "Норвежский",
    "pl-PL": "Польский",
    "pt-BR": "Португальский Бразилия",
    "pt-PT": "Португальский Португалия",
    "ro-RO": "Румынский",
    "ru-RU": "Русский",
    "es-ES": "Испанский Испания",
    "es-MX": "Испанский Мексика",
    "es-US": "Испанский США",
    "sv-SE": "Шведский",
    "tr-TR": "Турецкий",
    "cy-GB": "Валлийский Великобритания",
    zh: "Китайский",
    "zh-TW": "Традиционный китайский",
    ar: "Арабский",
    bn: "Бенгальский",
    da: "Датский",
    nl: "Нидерландский",
    en: "Английский",
    fr: "Французский",
    de: "Немецкий",
    hi: "Хинди",
    is: "Исландский",
    it: "Итальянский",
    ja: "Японский",
    ko: "Корейский",
    nb: "Норвежский",
    pl: "Польский",
    pt: "Португальский",
    ro: "Румынский",
    ru: "Русский",
    es: "Испанский",
    sv: "Шведский",
    tr: "Турецкий",
    cy: "Валлийский",
    add_cue_card: "Добавить карточку",
    dismiss: "Отклонить",
    flip: "Перевернуть",
    next: "Следующий",
    correct: "Правильно",
    incorrect: "Неправильно",
    favorite: "Избранное",
    archive: "Архивировать",
    home: "Главная",
    play: "Играть",
    cue_card: "Карточка",
    history: "История",
    search: "Поиск",
    publish: "Публиковать",
    comment: "Комментировать",
    submit: "Отправить",
    numLikes: "Лайки",
    settings: "Настройки",
    language: "Язык",
    pick_language: "Выберите язык отображения",
    try_this: "Попробуй это",
    sign_out: "Выйти",
    sign_in: "Войти",
    sign_up: "Зарегистрироваться",
    username: "Имя пользователя",
    screenname: "Псевдоним",
    save: "Сохранить",
    save_cue_card: "Добавьте карточку, выделяя иностранный текст в беседе",
    explore: "Исследовать",
    have_conversation: "Поговорите с кем-либо на",
    coach: "Тренер",
    explore_help: "Скажите 'Собор Святого Петра в Ватикане'",
    coach_help:
      "Перейдите на вкладку 'Говорить' и поговорите, затем вернитесь сюда для тренировки",
    speak_help:
      "Скажите 'поговорите с местным жителем у Эйфелевой башни в Париже, Франция'",
    home_help: "Слушайте опубликованные беседы других пользователей",
    ask_coach: "Спросить тренера",
    coach_header: "Тренер карточек",
    cue_card_suggestion: "Предложения карточек",
    conversation_suggestion: "Предложения беседы",
    tut_overview:
      "YouSpeak.ai хочет соединить мир через язык. Изучайте язык, исследуя места, которые хотели бы увидеть однажды. Ведите имитационные беседы с иностранцами. Создавайте карточки, выделяя слова, которых вы не знаете. Получайте советы от тренера, чтобы преодолеть трудности и продвигаться дальше.",
    tut_explore:
      "Исследуйте места, которые хотите посетить. Прокручивайте карту и щелкайте по местам. Нажмите 'Попробуй это', чтобы вести имитационную беседу в этом месте.",
    tut_speak:
      "Поговорите с кем-либо из любой точки мира. Говорите на родном языке или практикуйтесь в иностранном. Показывать или скрывать перевод\nВыделяйте слова или фразы, чтобы создать карточки.",
    tut_coach:
      "Спросите тренера о методах запоминания сложных идиом. Попробуйте добавить предложенные карточки. Потренируйтесь говорить в предложенных беседах.",
    tut_cuecard: "Используйте карточки, чтобы запоминать слова и фразы.",
    list: "список",
    native_language: "Родной язык",
    foreign_language: "Иностранный язык",
    language_code: "Код языка",
    limit_reached:
      "Достигнут лимит. Пожалуйста, вернитесь через 24 часа, чтобы продолжить обучение. Напишите на upgrade@youspeak.ai для получения дополнительной информации.",
  },
  es: {
    lang_not_found: "No se pudo ubicar en qué idioma hablar",
    initialize: "Inicializando...",
    stop_recording: "Dejar de Grabar",
    processing: "Procesando...",
    speak_native: "Habla Español",
    speak: "Hablar",
    show_native_text: "Mostrar Español",
    hide_native_text: "Ocultar Español",
    hide: "Ocultar",
    text: "Texto",
    show: "Mostrar",
    no_language_found: "No se pudo ubicar en qué idioma hablar",
    arb: "Árabe",
    "cmn-CN": "Chino",
    "da-DK": "Danés",
    "nl-NL": "Holandés",
    "en-AU": "Inglés Australia",
    "en-GB": "Inglés Reino Unido",
    "en-IN": "Inglés India",
    "en-US": "Inglés EE.UU.",
    "en-GB-WLS": "Galés Inglés Reino Unido",
    "fr-FR": "Francés Francia",
    "fr-CA": "Francés Canadá",
    "de-DE": "Alemán",
    "hi-IN": "Hindi",
    "is-IS": "Islandés",
    "it-IT": "Italiano",
    "ja-JP": "Japonés",
    "ko-KR": "Coreano",
    "nb-NO": "Noruego",
    "pl-PL": "Polaco",
    "pt-BR": "Portugués Brasil",
    "pt-PT": "Portugués Portugal",
    "ro-RO": "Rumano",
    "ru-RU": "Ruso",
    "es-ES": "Español España",
    "es-MX": "Español México",
    "es-US": "Español EE.UU.",
    "sv-SE": "Sueco",
    "tr-TR": "Turco",
    "cy-GB": "Galés",
    zh: "Chino",
    "zh-TW": "Chino Tradicional",
    ar: "Árabe",
    bn: "Bengalí",
    da: "Danés",
    nl: "Holandés",
    en: "Inglés",
    fr: "Francés",
    de: "Alemán",
    hi: "Hindi",
    is: "Islandés",
    it: "Italiano",
    ja: "Japonés",
    ko: "Coreano",
    nb: "Noruego",
    pl: "Polaco",
    pt: "Portugués",
    ro: "Rumano",
    ru: "Ruso",
    es: "Español",
    sv: "Sueco",
    tr: "Turco",
    cy: "Galés",
    add_cue_card: "Agregar Tarjeta",
    dismiss: "Descartar",
    flip: "Girar",
    next: "Siguiente",
    correct: "Correcto",
    incorrect: "Incorrecto",
    favorite: "Favorito",
    archive: "Archivo",
    home: "Inicio",
    play: "Reproducir",
    cue_card: "Tarjeta",
    history: "Historial",
    search: "Buscar",
    publish: "Publicar",
    comment: "Comentar",
    submit: "Enviar",
    numLikes: "Me gusta",
    settings: "Configuraciones",
    language: "Idioma",
    pick_language: "Selecciona el idioma de visualización",
    try_this: "Prueba Esto",
    sign_out: "Cerrar Sesión",
    sign_in: "Iniciar Sesión",
    sign_up: "Regístrate",
    username: "Nombre de Usuario",
    screenname: "Nombre para Mostrar",
    save: "Guardar",
    save_cue_card:
      "Agrega una Tarjeta resaltando texto extranjero en una conversación",
    explore: "Explorar",
    have_conversation: "Ten una conversación con alguien en",
    coach: "Entrenador",
    explore_help: "Diga 'Basilica de San Pedro en la Ciudad del Vaticano'",
    coach_help:
      "Vaya a la pestaña Hablar y tenga algunas conversaciones y vuelva aquí para obtener algo de entrenamiento",
    speak_help:
      "Diga 'habla con un local cerca de la Torre Eiffel en París, Francia'",
    home_help: "Escucha las conversaciones publicadas por otros usuarios",
    ask_coach: "Preguntar al Entrenador",
    coach_header: "Entrenador de Tarjetas",
    cue_card_suggestion: "Sugerencias de Tarjetas",
    conversation_suggestion: "Sugerencias de Conversaciones",
    tut_overview:
      "YouSpeak.ai quiere conectar el mundo a través del idioma. Aprende un idioma explorando los lugares que deseas ver algún día. Ten conversaciones simuladas con extranjeros. Crea tarjetas resaltando palabras que no conoces. Ten un entrenador que te ayude a superar obstáculos y te desafíe a llegar más lejos.",
    tut_explore:
      "Explora lugares que deseas visitar. Desplázate por el mapa y haz clic en los lugares. Presiona 'Prueba Esto' para tener una conversación simulada en ese lugar.",
    tut_speak:
      "Ten una conversación con cualquier persona alrededor del mundo. Habla en tu lengua materna o practica hablar en una lengua extranjera. Muestra u oculta el texto traducido. Resalta palabras o frases para crear una tarjeta.",
    tut_coach:
      "Pregunta al entrenador por consejos para memorizar un modismo difícil. Intenta agregar algunas tarjetas sugeridas. Intenta hablar en algunas conversaciones sugeridas.",
    tut_cuecard:
      "Usa las tarjetas para ayudarte a memorizar palabras y frases.",
    list: "lista",
    native_language: "Idioma Nativo",
    foreign_language: "Idioma Extranjero",
    language_code: "Código de Idioma",
    limit_reached:
      "Límite alcanzado. Por favor, regresa después de 24 horas para reanudar el aprendizaje. Envía un correo a upgrade@youspeak.ai para obtener más información.",
  },
  "es-MX": {
    lang_not_found: "No se pudo localizar en qué idioma conversar",
    initialize: "Iniciando...",
    stop_recording: "Dejar de grabar",
    processing: "Procesando...",
    speak_native: "Habla Español Mexicano",
    speak: "Hablar",
    show_native_text: "Mostrar Español Mexicano",
    hide_native_text: "Ocultar Español Mexicano",
    hide: "Ocultar",
    text: "Texto",
    show: "Mostrar",
    no_language_found: "No se pudo localizar en qué idioma conversar",
    arb: "Árabe",
    "cmn-CN": "Chino",
    "da-DK": "Danés",
    "nl-NL": "Holandés",
    "en-AU": "Inglés Australia",
    "en-GB": "Inglés UK",
    "en-IN": "Inglés India",
    "en-US": "Inglés US",
    "en-GB-WLS": "Inglés UK Galés",
    "fr-FR": "Francés Francia",
    "fr-CA": "Francés Canadá",
    "de-DE": "Alemán",
    "hi-IN": "Hindi",
    "is-IS": "Islandés",
    "it-IT": "Italiano",
    "ja-JP": "Japonés",
    "ko-KR": "Coreano",
    "nb-NO": "Noruego",
    "pl-PL": "Polaco",
    "pt-BR": "Portugués Brasil",
    "pt-PT": "Portugués Portugal",
    "ro-RO": "Rumano",
    "ru-RU": "Ruso",
    "es-ES": "Español España",
    "es-MX": "Español Mexicano",
    "es-US": "Español US",
    "sv-SE": "Sueco",
    "tr-TR": "Turco",
    "cy-GB": "Galés",
    zh: "Chino",
    "zh-TW": "Chino Tradicional",
    ar: "Árabe",
    bn: "Bengalí",
    da: "Danés",
    nl: "Holandés",
    en: "Inglés",
    fr: "Francés",
    de: "Alemán",
    hi: "Hindi",
    is: "Islandés",
    it: "Italiano",
    ja: "Japonés",
    ko: "Coreano",
    nb: "Noruego",
    pl: "Polaco",
    pt: "Portugués",
    ro: "Rumano",
    ru: "Ruso",
    es: "Español",
    sv: "Sueco",
    tr: "Turco",
    cy: "Galés",
    add_cue_card: "Agregar Tarjeta de Referencia",
    dismiss: "Descartar",
    flip: "Voltear",
    next: "Siguiente",
    correct: "Correcto",
    incorrect: "Incorrecto",
    favorite: "Favorito",
    archive: "Archivo",
    home: "Inicio",
    play: "Reproducir",
    cue_card: "Tarjeta de Referencia",
    history: "Historial",
    search: "Buscar",
    publish: "Publicar",
    comment: "Comentario",
    submit: "Enviar",
    numLikes: "Me gusta",
    settings: "Configuraciones",
    language: "Idioma",
    pick_language: "Seleccione el idioma de visualización",
    try_this: "Prueba esto",
    sign_out: "Cerrar sesión",
    sign_in: "Iniciar sesión",
    sign_up: "Registrar",
    username: "Nombre de usuario",
    screenname: "Nombre de pantalla",
    save: "Guardar",
    save_cue_card:
      "Agrega tarjeta de referencia resaltando el texto extranjero en una conversación",
    explore: "Explorar",
    have_conversation: "Ten una conversación con alguien en",
    coach: "Entrenador",
    explore_help: "Di 'La Basílica de San Pedro en la Ciudad del Vaticano'",
    coach_help:
      "Ve a la pestaña Hablar y ten algunas conversaciones, y vuelve aquí para algunos consejos",
    speak_help: "Di 'habla con un local en la Torre Eiffel en París, Francia'",
    home_help: "Escucha las conversaciones publicadas por otros usuarios",
    ask_coach: "Preguntar al Entrenador",
    coach_header: "Entrenador de Tarjetas de Referencia",
    cue_card_suggestion: "Sugerencias de Tarjetas de Referencia",
    conversation_suggestion: "Sugerencias de Conversación",
    tut_overview:
      "YouSpeak.ai quiere conectar al mundo a través del idioma. Aprende un idioma explorando lugares que quieres visitar algún día. Ten conversaciones simuladas con extranjeros. Crea tarjetas de referencia resaltando palabras que no conoces. Consigue un entrenador para superarte en los bloques de obstáculos y desafiarte a llegar más lejos.",
    tut_explore:
      "Explora lugares que deseas visitar. Desplázate por el mapa y haz clic en lugares. Presiona 'Prueba Esto' para tener una conversación simulada en ese lugar.",
    tut_speak:
      "Ten una conversación con cualquier persona alrededor del mundo. Habla en tu lengua materna o practica hablando en una lengua extranjera. Muestra u oculta el texto traducido. Resalta palabras o frases para crear una tarjeta de referencia.",
    tut_coach:
      "Pregunta al entrenador por consejos para memorizar un modismo difícil. Intenta agregar algunas de las tarjetas de referencia sugeridas. Intenta hablar algunas de las conversaciones sugeridas.",
    tut_cuecard:
      "Usa tarjetas de referencia para ayudarte a memorizar palabras y frases.",
    list: "lista",
    native_language: "Idioma Nativo",
    foreign_language: "Idioma Extranjero",
    language_code: "Código de Idioma",
    limit_reached:
      "Límite alcanzado. Por favor vuelve después de 24 horas para reanudar el aprendizaje. Envíe un correo electrónico a upgrade@youspeak.ai para más información.",
  },
  sv: {
    lang_not_found: "Kunde inte hitta vilket språk att samtala på",
    initialize: "Initierar...",
    stop_recording: "Stoppa inspelningen",
    processing: "Bearbetar...",
    speak_native: "Tala Svenska",
    speak: "Tala",
    show_native_text: "Visa Svenska",
    hide_native_text: "Dölj Svenska",
    hide: "Dölj",
    text: "Text",
    show: "Visa",
    no_language_found: "Kunde inte hitta vilket språk att samtala på",
    arb: "Arabiska",
    "cmn-CN": "Kinesiska",
    "da-DK": "Danska",
    "nl-NL": "Holländska",
    "en-AU": "Engelska Australien",
    "en-GB": "Engelska UK",
    "en-IN": "Engelska Indien",
    "en-US": "Engelska US",
    "en-GB-WLS": "Engelska UK Walesiska",
    "fr-FR": "Franska Frankrike",
    "fr-CA": "Franska Kanada",
    "de-DE": "Tyska",
    "hi-IN": "Hindi",
    "is-IS": "Isländska",
    "it-IT": "Italienska",
    "ja-JP": "Japanska",
    "ko-KR": "Koreanska",
    "nb-NO": "Norska",
    "pl-PL": "Polska",
    "pt-BR": "Portugisiska Brasilien",
    "pt-PT": "Portugisiska Portugal",
    "ro-RO": "Rumänska",
    "ru-RU": "Ryska",
    "es-ES": "Spanska Spanien",
    "es-MX": "Spanska Mexiko",
    "es-US": "Spanska US",
    "sv-SE": "Svenska",
    "tr-TR": "Turkiska",
    "cy-GB": "Walesiska",
    zh: "Kinesiska",
    "zh-TW": "Traditionell kinesiska",
    ar: "Arabiska",
    bn: "Bengaliska",
    da: "Danska",
    nl: "Holländska",
    en: "Engelska",
    fr: "Franska",
    de: "Tyska",
    hi: "Hindi",
    is: "Isländska",
    it: "Italienska",
    ja: "Japanska",
    ko: "Koreanska",
    nb: "Norska",
    pl: "Polska",
    pt: "Portugisiska",
    ro: "Rumänska",
    ru: "Ryska",
    es: "Spanska",
    sv: "Svenska",
    tr: "Turkiska",
    cy: "Walesiska",
    add_cue_card: "Lägg till Cue Card",
    dismiss: "Avfärda",
    flip: "Vänd",
    next: "Nästa",
    correct: "Korrekt",
    incorrect: "Fel",
    favorite: "Favorit",
    archive: "Arkiv",
    home: "Hem",
    play: "Spela",
    cue_card: "Kort",
    history: "Historik",
    search: "Sök",
    publish: "Publicera",
    comment: "Kommentera",
    submit: "Skicka",
    numLikes: "Gillar",
    settings: "Inställningar",
    language: "Språk",
    pick_language: "Välj visningsspråk",
    try_this: "Försök detta",
    sign_out: "Logga ut",
    sign_in: "Logga in",
    sign_up: "Registrera dig",
    username: "Användarnamn",
    screenname: "Skärmnamn",
    save: "Spara",
    save_cue_card:
      "Lägg till Cue Card genom att markera utländsk text i en konversation",
    explore: "Utforska",
    have_conversation: "Ha en konversation med någon på",
    coach: "Coach",
    explore_help: "Säg 'St. Peterskyrkan i Vatikanstaten'",
    coach_help:
      "Gå till fliken Tala och ha några samtal och kom tillbaka hit för coaching",
    speak_help: "Säg 'prata med en lokal vid Eiffeltornet i Paris, Frankrike'",
    home_help: "Lyssna på publicerade samtal av andra användare",
    ask_coach: "Fråga coach",
    coach_header: "Cue Card Coach",
    cue_card_suggestion: "Cue Kort Förslag",
    conversation_suggestion: "Konversationsförslag",
    tut_overview:
      "YouSpeak.ai vill sammanföra världen genom språket. Lär dig ett språk genom att utforska platser du vill se en dag. Ha simulerade konversationer med utlänningar. Skapa cue cards genom att markera ord du inte känner till. Ha en coach för att guida dig genom svårigheter och utmana dig att nå längre.",
    tut_explore:
      "Utforska platser du vill besöka. Bläddra runt på kartan och klicka på platser. Tryck på 'Försök detta' för att ha en simulerad konversation på den platsen.",
    tut_speak:
      "Ha en konversation med vem som helst runt om i världen. Tala på ditt modersmål eller öva på att tala ett utländskt språk. Visa eller dölj översatt text. Markera ord eller fraser för att skapa ett cue card.",
    tut_coach:
      "Be om tips från coachen för att memorera ett svårt uttryck. Prova att lägga till några föreslagna cue cards. Försök att tala några föreslagna konversationer.",
    tut_cuecard:
      "Använd cuecards för att hjälpa dig att memorera ord och fraser.",
    list: "lista",
    native_language: "Modersmål",
    foreign_language: "Utländskt språk",
    language_code: "Språkkod",
    limit_reached:
      "Gräns nådd. Vänligen kom tillbaka efter 24 timmar för att återuppta lärandet. E-posta upgrade@youspeak.ai för mer information.",
  },
  tr: {
    lang_not_found: "Hangi dilde konuşacağımızı bulamadık",
    initialize: "Başlatılıyor...",
    stop_recording: "Kaydı Durdur",
    processing: "İşleniyor...",
    speak_native: "Türkçe Konuş",
    speak: "Konuş",
    show_native_text: "Türkçe Göster",
    hide_native_text: "Türkçe Gizle",
    hide: "Gizle",
    text: "Metin",
    show: "Göster",
    no_language_found: "Hangi dilde konuşacağımızı bulamadık",
    arb: "Arapça",
    "cmn-CN": "Çince",
    "da-DK": "Danca",
    "nl-NL": "Flemenkçe",
    "en-AU": "İngilizce Avustralya",
    "en-GB": "İngilizce İngiltere",
    "en-IN": "İngilizce Hindistan",
    "en-US": "İngilizce ABD",
    "en-GB-WLS": "İngilizce İngiltere Galler",
    "fr-FR": "Fransızca Fransa",
    "fr-CA": "Fransızca Kanada",
    "de-DE": "Almanca",
    "hi-IN": "Hintçe",
    "is-IS": "İzlandaca",
    "it-IT": "İtalyanca",
    "ja-JP": "Japonca",
    "ko-KR": "Korece",
    "nb-NO": "Norveççe",
    "pl-PL": "Lehçe",
    "pt-BR": "Portekizce Brezilya",
    "pt-PT": "Portekizce Portekiz",
    "ro-RO": "Rumence",
    "ru-RU": "Rusça",
    "es-ES": "İspanyolca İspanya",
    "es-MX": "İspanyolca Meksika",
    "es-US": "İspanyolca ABD",
    "sv-SE": "İsveççe",
    "tr-TR": "Türkçe",
    "cy-GB": "Gallerce",
    zh: "Çince",
    "zh-TW": "Geleneksel Çince",
    ar: "Arapça",
    bn: "Bengalce",
    da: "Danca",
    nl: "Flemenkçe",
    en: "İngilizce",
    fr: "Fransızca",
    de: "Almanca",
    hi: "Hintçe",
    is: "İzlandaca",
    it: "İtalyanca",
    ja: "Japonca",
    ko: "Korece",
    nb: "Norveççe",
    pl: "Lehçe",
    pt: "Portekizce",
    ro: "Rumence",
    ru: "Rusça",
    es: "İspanyolca",
    sv: "İsveççe",
    tr: "Türkçe",
    cy: "Gallerce",
    add_cue_card: "Kart Ekle",
    dismiss: "Kapat",
    flip: "Çevir",
    next: "Sonraki",
    correct: "Doğru",
    incorrect: "Yanlış",
    favorite: "Favori",
    archive: "Arşivle",
    home: "Anasayfa",
    play: "Oynat",
    cue_card: "Kart",
    history: "Geçmiş",
    search: "Ara",
    publish: "Yayınla",
    comment: "Yorum",
    submit: "Gönder",
    numLikes: "Beğeni",
    settings: "Ayarlar",
    language: "Dil",
    pick_language: "Görüntüleme dilini seçin",
    try_this: "Bunu Dene",
    sign_out: "Çıkış Yap",
    sign_in: "Giriş Yap",
    sign_up: "Kaydol",
    username: "Kullanıcı Adı",
    screenname: "Ekran Adı",
    save: "Kaydet",
    save_cue_card: "Bir konuşmada yabancı metni vurgulayarak kart ekleyin",
    explore: "Keşfet",
    have_conversation: "Birisiyle konuş at",
    coach: "Koç",
    explore_help: "Örnek: 'Vatican City'de St. Peter's Basilica'sı'",
    coach_help:
      "Konuş sekmesine gidin ve bazı konuşmalar yapın, ardından buraya geri dönüp koçluğu inceleyin",
    speak_help:
      "Örnek: 'Paris, Fransa'da Eiffel Kulesi'nin yanında bir yerliyle konuş'",
    home_help: "Diğer kullanıcılar tarafından yayınlanan konuşmaları dinleyin",
    ask_coach: "Koça Sor",
    coach_header: "Kart Koçu",
    cue_card_suggestion: "Kart Önerileri",
    conversation_suggestion: "Konuşma Önerileri",
    tut_overview:
      "YouSpeak.ai dünyayı dil yoluyla birbirine bağlamak istiyor. Bir dili, bir gün görmek istediğiniz yerleri keşfederek öğrenin. Yabancılarla simüle edilmiş konuşmalar yapın. Bilmediğiniz kelimeleri vurgulayarak kartlar oluşturun. Koçun yardımıyla zorlukları aşın ve daha ileriye ulaşmak için kendinizi zorlayın.",
    tut_explore:
      "Ziyaret etmek istediğiniz yerleri keşfedin. Haritada gezinin ve yerlerin üzerine tıklayın. O yerle ilgili simüle edilmiş bir konuşma yapmak için 'Bunu Dene'yi seçin.",
    tut_speak:
      "Dünyanın her yerinden biriyle konuşun. Ana dilinizde konuşun veya yabancı bir dilde pratiğinizi yapın. Çeviri metnini gösterin veya gizleyin. Kelimeleri veya ifadeleri vurgulayarak kart oluşturun.",
    tut_coach:
      "Zor bir deyimi hatırlamak için koçtan ipuçları isteyin. Bazı önerilen kartlar eklemeyi deneyin. Bazı önerilen konuşmaları yapmayı deneyin.",
    tut_cuecard: "Kelime ve ifadeleri hatırlamak için kartları kullanın.",
    list: "liste",
    native_language: "Ana Dil",
    foreign_language: "Yabancı Dil",
    language_code: "Dil Kodu",
    limit_reached:
      "Sınıra ulaşıldı. Öğrenmeye devam etmek için lütfen 24 saat sonra tekrar gelin. Daha fazla bilgi için upgrade@youspeak.ai adresine e-posta gönderin.",
  },
  cy: {
    lang_not_found: "Methu dod o hyd i'r iaith i sgwrsio ynddo",
    initialize: "Yn cychwyn...",
    stop_recording: "Rhoi'r gorau i Recordio",
    processing: "Wrthi'n prosesu...",
    speak_native: "Siarad Cymraeg",
    speak: "Siarad",
    show_native_text: "Dangos Cymraeg",
    hide_native_text: "Cuddio Cymraeg",
    hide: "Cuddio",
    text: "Testun",
    show: "Dangos",
    no_language_found: "Methu dod o hyd i'r iaith i sgwrsio ynddo",
    arb: "Arabeg",
    "cmn-CN": "Tsieinëeg",
    "da-DK": "Daneg",
    "nl-NL": "Iseldireg",
    "en-AU": "Saesneg Awstralia",
    "en-GB": "Saesneg DU",
    "en-IN": "Saesneg India",
    "en-US": "Saesneg UDA",
    "en-GB-WLS": "Saesneg DU Cymraeg",
    "fr-FR": "Ffrangeg Ffrainc",
    "fr-CA": "Ffrangeg Canada",
    "de-DE": "Almaeneg",
    "hi-IN": "Hindi",
    "is-IS": "Islandeg",
    "it-IT": "Eidaleg",
    "ja-JP": "Japaneaeg",
    "ko-KR": "Coreeg",
    "nb-NO": "Norwyeg",
    "pl-PL": "Pwyleg",
    "pt-BR": "Portiwgaleg Brasil",
    "pt-PT": "Portiwgaleg Portiwgal",
    "ro-RO": "Rwmaneg",
    "ru-RU": "Rwsieg",
    "es-ES": "Sbaeneg Sbaen",
    "es-MX": "Sbaeneg Mecsico",
    "es-US": "Sbaeneg UDA",
    "sv-SE": "Swedeg",
    "tr-TR": "Tyrceg",
    "cy-GB": "Cymraeg",
    zh: "Tsieinëeg",
    "zh-TW": "Tsieinëeg Traddodiadol",
    ar: "Arabeg",
    bn: "Bengaleg",
    da: "Daneg",
    nl: "Iseldireg",
    en: "Saesneg",
    fr: "Ffrangeg",
    de: "Almaeneg",
    hi: "Hindi",
    is: "Islandeg",
    it: "Eidaleg",
    ja: "Japaneaeg",
    ko: "Coreeg",
    nb: "Norwyeg",
    pl: "Pwyleg",
    pt: "Portiwgaleg",
    ro: "Rwmaneg",
    ru: "Rwsieg",
    es: "Sbaeneg",
    sv: "Swedeg",
    tr: "Tyrceg",
    cy: "Cymraeg",
    add_cue_card: "Ychwanegu Cerdyn Ciw",
    dismiss: "Diystyru",
    flip: "Troi",
    next: "Nesaf",
    correct: "Cywir",
    incorrect: "Anghywir",
    favorite: "Hoff",
    archive: "Archif",
    home: "Hafan",
    play: "Chwarae",
    cue_card: "Cerdyn",
    history: "Hanes",
    search: "Chwilio",
    publish: "Cyhoeddi",
    comment: "Sylw",
    submit: "Cyflwyno",
    numLikes: "Hoff",
    settings: "Gosodiadau",
    language: "Iaith",
    pick_language: "Dewiswch iaith arddangos",
    try_this: "Rhowch Gynnig ar Hyn",
    sign_out: "Allgofnodi",
    sign_in: "Mewngofnodi",
    sign_up: "Cofrestru",
    username: "Enw Defnyddiwr",
    screenname: "Enw Sgrin",
    save: "Arbed",
    save_cue_card:
      "Ychwanegu Cerdyn Ciw trwy dynnu sylw at destun tramor mewn sgwrs",
    explore: "Archwilio",
    have_conversation: "Cael sgwrs gyda rhywun yn",
    coach: "Hyfforddwr",
    explore_help: "Dywedwch 'Basilica Sant Pedr yn Ninas y Fatican'",
    coach_help:
      "Ewch i'r tab Siarad a chael rhai sgyrsiau a dychwelwch yma ar gyfer rhywfaint o hyfforddi",
    speak_help:
      "Dywedwch 'siarad â pherson lleol gan Dŵr Eiffel ym Mharis, Ffrainc'",
    home_help:
      "Gwrandewch ar sgyrsiau sydd wedi’u cyhoeddi gan ddefnyddwyr eraill",
    ask_coach: "Gofynnwch i’r Hyfforddwr",
    coach_header: "Hyfforddwr Cerdyn Ciw",
    cue_card_suggestion: "Awgrymiadau Cerdyn Ciw",
    conversation_suggestion: "Awgrymiadau Sgwrs",
    tut_overview:
      "Mae YouSpeak.ai eisiau cysylltu'r byd drwy iaith. Dysgwch iaith trwy archwilio lleoedd rydych chi am eu gweld un diwrnod. Cael sgyrsiau wedi’u efelychu gyda thramorwyr. Gwnewch gardiau ciw trwy dynnu sylw at eiriau na wyddoch chi. Cael hyfforddwr i’ch tywys drwy rwystrau a’ch herio i fynd ymhellach.",
    tut_explore:
      "Archwilio lleoedd rydych chi am eu gweld. Sgroliwch o gwmpas y map a chliciwch ar leoedd. Pwyswch 'Rhowch Gynnig ar Hyn' i gael sgwrs wedi’i hefelychu yn y lle hwnnw.",
    tut_speak:
      "Sganiwch gyda phawb o amgylch y byd. Siaradwch yn eich mamiaith neu ymarfer siarad mewn iaith estronol. Dangos neu guddio testun wedi'i gyfieithu Highlight words or phrases to create cue card.",
    tut_coach:
      "Gofynnwch i’r hyfforddwr am awgrymiadau ar gofio idiom anodd. Ceisiwch ychwanegu rhai cardiau ciw awgrymedig. Ceisiwch siarad rhai sgyrsiau awgrymedig.",
    tut_cuecard:
      "Defnyddio cardiau ciw i’ch helpu i gofio geiriau a brawddegau.",
    list: "rhestr",
    native_language: "Iaith Frodorol",
    foreign_language: "Iaith Dramor",
    language_code: "Cod Iaith",
    limit_reached:
      "Wedi cyrraedd y terfyn. Dewch yn ôl ar ôl 24 awr i barhau i ddysgu. E-bostiwch upgrade@youspeak.ai am fwy o wybodaeth.",
  },
};