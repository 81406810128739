import { API, graphqlOperation, I18n } from "aws-amplify";
import { chatFunction } from "../graphql/queries";
import { getOpenAIMessages } from "./GetOpenAIMessages";
import { DialectCodeArray, TextCodeArray } from "../commonfn/CountryCodes";

export async function chatInit(systemMessage) {
  return new Promise(async (resolve) => {
    const aiMessages = getOpenAIMessages([], [], systemMessage);

    const promiseDialect = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 20,
          function_call: { name: "converse" },
          functions: [
            {
              name: "converse",
              description: "converse in a language with a dialect",
              parameters: {
                type: "object",
                properties: {
                  // confidence: {
                  //   type: "number",
                  //   minimum: 0,
                  //   maximum: 100,
                  //   description: "confidence percentage in detecting dialect from text",
                  // },
                  dialectCode: {
                    type: "string",
                    enum: DialectCodeArray,
                  },
                },
              },
              required: ["dialectCode"],
            },
          ],
        }),
      })
    );
    const promiseTextCode = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 20,
          function_call: { name: "typeLanguage" },
          functions: [
            {
              name: "typeLanguage",
              description: "converse by typing in a language",
              parameters: {
                type: "object",
                properties: {
                  textCode: {
                    type: "string",
                    enum: TextCodeArray,
                  },
                },
              },
              required: ["textCode"],
            },
          ],
        }),
      })
    );
    const promiseIsMonologue = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 20,
          function_call: { name: "is_story" },
          functions: [
            {
              name: "is_story",
              description:
                "does the user want to hear a story, speech, or monologue instead of having a conversation?",
              parameters: {
                type: "object",
                properties: {
                  wants_story: {
                    type: "string",
                    enum: ["yes", "no"],
                  },
                },
              },
              required: ["wants_story"],
            },
          ],
        }),
      })
    );
    const promiseResponseLength = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 20,
          function_call: { name: "get_response_lenth" },
          functions: [
            {
              name: "get_response_lenth",
              description: "how long should the ai responses be?",
              parameters: {
                type: "object",
                properties: {
                  response_length: {
                    type: "string",
                    enum: [
                      "very short",
                      "short",
                      "medium",
                      "long",
                      "very long",
                    ],
                  },
                },
              },
              required: ["response_length"],
            },
          ],
        }),
      })
    );
    const promiseRealConversation = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 20,
          function_call: { name: "real_time_mode" },
          functions: [
            {
              name: "real_time_mode",
              description: "Did the user say he wants realtime mode?",
              parameters: {
                type: "object",
                properties: {
                  real_time_mode: {
                    type: "string",
                    enum: ["true", "false"],
                  },
                },
              },
              required: ["real_time_mode"],
            },
          ],
        }),
      })
    );
    let fnResponse = {};
    Promise.all([
      promiseDialect,
      promiseTextCode,
      promiseIsMonologue,
      promiseResponseLength,
      promiseRealConversation,
    ]).then((responses) => {
      try {
        for (let i = 0; i < responses.length; i++) {
          try {
            const chatFunction = JSON.parse(responses[i].data.chatFunction);
            const key = Object.keys(chatFunction)[0];
            if (key === "textCode") {
              let textCode = chatFunction[key];
              if (textCode === "zh-Simplified") {
                textCode = "zh";
              } else if (textCode === "zh-Traditional") {
                textCode = "zh-TW";
              } else if (textCode === "zh-HK") {
                textCode = "zh-TW";
              } else if (textCode === "zh-CN") {
                textCode = "zh";
              }
              fnResponse.textCode = textCode;
            } else if (key === "dialectCode") {
              let dialectCode = chatFunction[key];
              if (dialectCode === "zh-CN") {
                dialectCode = "cmn-CN";
              } else if (dialectCode === "zh-TW") {
                dialectCode = "cmn-CN";
              } else if (dialectCode === "zh-HK") {
                dialectCode = "cmn-CN";
              }
              fnResponse.dialectCode = dialectCode;
            } else {
              fnResponse[key] = chatFunction[key];
            }
          } catch (err) {
            console.log("err: " + err + " " + responses[i].data);
          }
        }
      } catch (error) {
        alert(I18n.get("limit_reached") + "chatFn");
        resolve({ limitReached: true });
      }
      if (!fnResponse.textCode.startsWith("zh")) {
        if (fnResponse.dialectCode.includes("-")) {
          fnResponse.textCode = fnResponse.dialectCode.split("-")[0];
        }
      }
      resolve(fnResponse);
    });
  });
}
