import { Button, Image, Text, View } from "@aws-amplify/ui-react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { I18n } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { CiPlay1 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { getLocationByPlaceId, getLocationByPrompt } from "./chat/GetLocation";
import { LanguageContext } from "./commonfn/LanguageContext";
import { SettingContext } from "./commonfn/SettingContext";
import { BigMap, StreetMap } from "./map/Map";

export function GeoView({ prompt, placeId, displayTryThis, onPlaceInfo }) {
  const navigate = useNavigate();
  const nativeCode = useContext(LanguageContext);
  const [placeLatLng, setPlaceLatLng] = useState();
  const [placeIdTemp, setPlaceIdTemp] = useState();
  const [placeName, setPlaceName] = useState();
  const [placeAddress, setPlaceAddress] = useState();
  const [placePhotos, setPlacePhotos] = useState();
  const [placeWebsite, setPlaceWebsite] = useState();
  const [metadataPlace, setMetadataPlace] = useState("");
  const [conversationPrompt, setConversationPrompt] = useState("");

  const { settings, setSettings } = useContext(SettingContext);

  useEffect(() => {
    console.log("placeLatLng " + placeLatLng);
    console.log("placeIdTemp " + placeIdTemp);
    console.log("placeId " + placeId);
    console.log("settings placeId " + JSON.stringify(settings, null, 2));
    if (placeIdTemp) {
    } else if (placeId) {
      fetchPlaceByPlaceId(placeId);
    } else if (settings.placeId) {
      if (displayTryThis) {
        fetchPlaceByPlaceId(settings.placeId);
      }
    }
  }, [placeId, settings.placeId]);

  useEffect(() => {
    console.log("prompt " + prompt);
    if (prompt) {
      fetchPlaceByPrompt();
    } 
  }, [prompt]);

  const fetchPlaceByPlaceId = async (placeIdTemp2) => {
    console.log("fetchPlaceByPlaceId: " + placeIdTemp2);
    let responsesLocation = await getLocationByPlaceId(placeIdTemp2);
    console.log(
      "fetchPlaceByPlaceId res: " + JSON.stringify(responsesLocation, null, 2)
    );
    fetchPlaceHelper(responsesLocation);
  };

  const fetchPlaceByPrompt = async () => {
    console.log("fetchPlaceByPrompt: " + prompt);
    let responsesLocation = await getLocationByPrompt(prompt);
    console.log("fetchPlaceByPrompt res: " + JSON.stringify(responsesLocation, null, 2));
    fetchPlaceHelper(responsesLocation);
  };

  const fetchPlaceHelper = (responsesLocation) => {
    if (responsesLocation) {
      // console.log(
      //   "gmap responsesLocation: " + JSON.stringify(responsesLocation, null, 2)
      // );
      if (
        responsesLocation.getPlace.result &&
        responsesLocation.getPlace.result.geometry
      ) {
        console.log(
          "responsesLocation: " +
            JSON.stringify(
              responsesLocation.getPlace.result.geometry.location,
              null,
              2
            )
        );
        setPlaceLatLng(responsesLocation.getPlace.result.geometry.location);
        setPlaceIdTemp(responsesLocation.getPlace.result.place_id);
        setSettings({
          ...settings,
          placeId: responsesLocation.getPlace.result.place_id,
        });
        setPlaceName(responsesLocation.getPlace.result.name);
        setPlaceAddress(responsesLocation.getPlace.result.formatted_address);
        setPlaceWebsite(responsesLocation.getPlace.result.website);
        







        



        setMetadataPlace(
          JSON.stringify(
            responsesLocation.getPlace.result.name +
              ", " +
              responsesLocation.getPlace.result.geometry.location,
            null,
            2
          )
        );

        setConversationPrompt(
          I18n.get("have_conversation") +
            " " +
            responsesLocation.getPlace.result.name +
            " ," + 
            responsesLocation.getPlace.result.formatted_address
        );

        //setPlaceInfo(placeData);
        if (onPlaceInfo) {
          onPlaceInfo(responsesLocation.getPlace.result);
        }
      }
    }
  };

  const handlePlaceClick = (e) => {
    console.log("place click", e);
    //setPlaceLatLng({ lat: e.lat, lng: e.lng });
    fetchPlaceByPlaceId(e.placeId);
  };

  const handleMapClick = (e) => {
    console.log("place click", e);
    setPlaceLatLng({ lat: e.lat, lng: e.lng });
    setPlacePhotos(null);
  };

  return (
    <View>
      {/* {displayTryThis ? (
            <View>
              <Text>{conversationPrompt}</Text>
              <Button
            style={styles.button}
            size="small"
            onClick={() =>
              navigate(
                "/speak?prompt=" + conversationPrompt + "&placeId=" + placeIdTemp
                , { replace: true })
            }
            <CiPlay1 size={30} /> {I18n.get("try_this")}
          </Button>
            </View>
          ) : null} */}
      {placeLatLng ? (
        <Wrapper
          apiKey={"AIzaSyAstMG9Gt6Xl2SaJsaz_d-RLdU5aBxsYA8"}
          language={nativeCode.substring(0, 2)}
        >
          {displayTryThis ? (
            <View>
              <Text>{conversationPrompt}</Text>
              <Button
                style={styles.button}
                size="small"
                onClick={() =>
                  navigate(
                    "/speak?prompt=" +
                      conversationPrompt +
                      "&placeId=" +
                      placeIdTemp
                  )
                }
              >
                <CiPlay1 size={30} /> {I18n.get("try_this")}
              </Button>
            </View>
          ) : null}
          <Text fontSize="1.5em">{placeName}</Text>
          <Text>{placeAddress}</Text>
          <BigMap
            center={placeLatLng}
            zoom={17}
            onMapClick={handleMapClick}
            onPlaceClick={handlePlaceClick}
          />
          <StreetMap center={placeLatLng} />
          {placePhotos}
        </Wrapper>
      ) : null}
    </View>
  );
}

const styles = {
  button: {
    backgroundColor: "#D3D3D3",
    width: "140px",
    margin: "3px",
  },
};
