/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const chat = /* GraphQL */ `
  query Chat($chatParams: String) {
    chat(chatParams: $chatParams)
  }
`;
export const chatFunction = /* GraphQL */ `
  query ChatFunction($fn: String) {
    chatFunction(fn: $fn)
  }
`;
export const generateImage = /* GraphQL */ `
  query GenerateImage($prompt: String, $identityId: String, $key: String) {
    generateImage(prompt: $prompt, identityId: $identityId, key: $key)
  }
`;
export const getPlace = /* GraphQL */ `
  query GetPlace($prompt: String, $placeId: String) {
    getPlace(prompt: $prompt, placeId: $placeId)
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      conversationId
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversationId
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFlag = /* GraphQL */ `
  query GetFlag($id: ID!) {
    getFlag(id: $id) {
      id
      publicConversationId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFlags = /* GraphQL */ `
  query ListFlags(
    $filter: ModelFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicConversationId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      nativeLangCode
      targetLangCode
      showNative
      showTarget
      location
      imageStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nativeLangCode
        targetLangCode
        showNative
        showTarget
        location
        imageStyle
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCueCard = /* GraphQL */ `
  query GetCueCard($id: ID!) {
    getCueCard(id: $id) {
      id
      nativeLangCode
      nativeText
      targetLangCode
      targetDialectCode
      targetText
      imageUrl
      active
      bookmark
      correctTimestamps
      incorrectTimestamps
      flippedCorrectTimestamps
      flippedIncorrectTimestamps
      score
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCueCards = /* GraphQL */ `
  query ListCueCards(
    $filter: ModelCueCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCueCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nativeLangCode
        nativeText
        targetLangCode
        targetDialectCode
        targetText
        imageUrl
        active
        bookmark
        correctTimestamps
        incorrectTimestamps
        flippedCorrectTimestamps
        flippedIncorrectTimestamps
        score
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByConversationId = /* GraphQL */ `
  query ConversationsByConversationId(
    $conversationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByConversationId(
      conversationId: $conversationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationId
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByDate = /* GraphQL */ `
  query ConversationsByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationId
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const flagsByPublicConversationId = /* GraphQL */ `
  query FlagsByPublicConversationId(
    $publicConversationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flagsByPublicConversationId(
      publicConversationId: $publicConversationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicConversationId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const flagsByDate = /* GraphQL */ `
  query FlagsByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flagsByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicConversationId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cueCardsByDate = /* GraphQL */ `
  query CueCardsByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCueCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cueCardsByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nativeLangCode
        nativeText
        targetLangCode
        targetDialectCode
        targetText
        imageUrl
        active
        bookmark
        correctTimestamps
        incorrectTimestamps
        flippedCorrectTimestamps
        flippedIncorrectTimestamps
        score
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicConversation = /* GraphQL */ `
  query GetPublicConversation($id: ID!) {
    getPublicConversation(id: $id) {
      id
      conversationId
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPublicConversations = /* GraphQL */ `
  query ListPublicConversations(
    $filter: ModelPublicConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicConversationsByConversationId = /* GraphQL */ `
  query PublicConversationsByConversationId(
    $conversationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPublicConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicConversationsByConversationId(
      conversationId: $conversationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicConversationsByDate = /* GraphQL */ `
  query PublicConversationsByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicConversationsByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicLike = /* GraphQL */ `
  query GetPublicLike($id: ID!) {
    getPublicLike(id: $id) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      publicConversationLikesId
      publicPersonaLikesId
      owner
      __typename
    }
  }
`;
export const listPublicLikes = /* GraphQL */ `
  query ListPublicLikes(
    $filter: ModelPublicLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversation {
          id
          conversationId
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          nativeLangCode
          nativeDialectCode
          targetLangCode
          targetDialectCode
          title_en
          title_fr
          title_frCA
          title_es
          title_esMX
          title_de
          title_nl
          title_it
          title_pt
          title_ptPT
          title_ja
          title_ko
          title_zh
          title_zhTW
          title_ru
          title_ar
          title_hi
          title_bn
          title_da
          title_is
          title_nb
          title_pl
          title_ro
          title_sv
          title_tr
          title_cy
          imageUrl
          placeLat
          placeLng
          placeName
          placeAddress
          placeCity
          placeState
          placeCountry
          placePostalCode
          placePhone
          placeTypes
          placeUTCOffset
          placeWebsite
          placeId
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        persona {
          id
          ownerId
          screenName
          nativeLangCode
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        active
        createdAt
        updatedAt
        publicConversationLikesId
        publicPersonaLikesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const likesByDate = /* GraphQL */ `
  query LikesByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversation {
          id
          conversationId
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          nativeLangCode
          nativeDialectCode
          targetLangCode
          targetDialectCode
          title_en
          title_fr
          title_frCA
          title_es
          title_esMX
          title_de
          title_nl
          title_it
          title_pt
          title_ptPT
          title_ja
          title_ko
          title_zh
          title_zhTW
          title_ru
          title_ar
          title_hi
          title_bn
          title_da
          title_is
          title_nb
          title_pl
          title_ro
          title_sv
          title_tr
          title_cy
          imageUrl
          placeLat
          placeLng
          placeName
          placeAddress
          placeCity
          placeState
          placeCountry
          placePostalCode
          placePhone
          placeTypes
          placeUTCOffset
          placeWebsite
          placeId
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        persona {
          id
          ownerId
          screenName
          nativeLangCode
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        active
        createdAt
        updatedAt
        publicConversationLikesId
        publicPersonaLikesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicComment = /* GraphQL */ `
  query GetPublicComment($id: ID!) {
    getPublicComment(id: $id) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nativeLangCode
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      publicConversationCommentsId
      publicPersonaCommentsId
      owner
      __typename
    }
  }
`;
export const listPublicComments = /* GraphQL */ `
  query ListPublicComments(
    $filter: ModelPublicCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversation {
          id
          conversationId
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          nativeLangCode
          nativeDialectCode
          targetLangCode
          targetDialectCode
          title_en
          title_fr
          title_frCA
          title_es
          title_esMX
          title_de
          title_nl
          title_it
          title_pt
          title_ptPT
          title_ja
          title_ko
          title_zh
          title_zhTW
          title_ru
          title_ar
          title_hi
          title_bn
          title_da
          title_is
          title_nb
          title_pl
          title_ro
          title_sv
          title_tr
          title_cy
          imageUrl
          placeLat
          placeLng
          placeName
          placeAddress
          placeCity
          placeState
          placeCountry
          placePostalCode
          placePhone
          placeTypes
          placeUTCOffset
          placeWebsite
          placeId
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        persona {
          id
          ownerId
          screenName
          nativeLangCode
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        nativeLangCode
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        publicConversationCommentsId
        publicPersonaCommentsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicCommentsByDate = /* GraphQL */ `
  query PublicCommentsByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicCommentsByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversation {
          id
          conversationId
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          nativeLangCode
          nativeDialectCode
          targetLangCode
          targetDialectCode
          title_en
          title_fr
          title_frCA
          title_es
          title_esMX
          title_de
          title_nl
          title_it
          title_pt
          title_ptPT
          title_ja
          title_ko
          title_zh
          title_zhTW
          title_ru
          title_ar
          title_hi
          title_bn
          title_da
          title_is
          title_nb
          title_pl
          title_ro
          title_sv
          title_tr
          title_cy
          imageUrl
          placeLat
          placeLng
          placeName
          placeAddress
          placeCity
          placeState
          placeCountry
          placePostalCode
          placePhone
          placeTypes
          placeUTCOffset
          placeWebsite
          placeId
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        persona {
          id
          ownerId
          screenName
          nativeLangCode
          likes {
            items {
              id
              active
              createdAt
              updatedAt
              publicConversationLikesId
              publicPersonaLikesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          comments {
            items {
              id
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              publicConversationCommentsId
              publicPersonaCommentsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        nativeLangCode
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        publicConversationCommentsId
        publicPersonaCommentsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicPersona = /* GraphQL */ `
  query GetPublicPersona($id: ID!) {
    getPublicPersona(id: $id) {
      id
      ownerId
      screenName
      nativeLangCode
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPublicPersonas = /* GraphQL */ `
  query ListPublicPersonas(
    $filter: ModelPublicPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicPersonas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicPersonasByOwnerId = /* GraphQL */ `
  query PublicPersonasByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPublicPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicPersonasByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicPersonaByDate = /* GraphQL */ `
  query PublicPersonaByDate(
    $active: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicPersonaByDate(
      active: $active
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
