import {
  Flex,
  Text,
  View
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { CiHome, CiCreditCard2, CiBookmark, CiSearch, CiChat1, CiSettings } from "react-icons/ci";
import { PiChalkboardTeacherThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

export function MenuBar({}) {
    const navigate = useNavigate();

  return (
    <Flex margin={10} marginTop={20}>
      {/* <View width="50px" textAlign="center" onClick={() => navigate("/")}>
        <CiHome size={30} />
        <Text position="relative" top={-8}>{I18n.get("home")}</Text>
      </View> */}
      <View width="50px" textAlign="center" onClick={() => navigate("/explore")}>
        <CiSearch size={30} />
        <Text position="relative" top={-8}>{I18n.get("explore")}</Text>
      </View>
      <View width="50px" textAlign="center" onClick={() => navigate("/speak")}>
        <CiChat1 size={30} />
        <Text position="relative" top={-8}>{I18n.get("speak")}</Text>
      </View>
      <View width="50px" textAlign="center" onClick={() => navigate("/cuecard")}>
        <CiCreditCard2 size={30} />
        <Text position="relative" top={-8}>{I18n.get("cue_card")}</Text>
      </View>
      <View width="50px" textAlign="center" onClick={() => navigate("/coach")}>
        <PiChalkboardTeacherThin size={30} />
        <Text position="relative" top={-8}>{I18n.get("coach")}</Text>
      </View>
      {/* <View width="50px" textAlign="center" onClick={() => navigate("/history")}>
        <CiBookmark size={30} />
        <Text position="relative" top={-8}>{I18n.get("history")}</Text>
      </View> */}
      <View width="50px" textAlign="center" onClick={() => navigate("/settings")}>
        <CiSettings size={30} />
        <Text position="relative" top={-8}>{I18n.get("settings")}</Text>
      </View>
      {/* <View width="50px" textAlign="center" onClick={() => navigate("/")}>
        <CiSearch size={30} />
        <Text position="relative" top={-8}>{I18n.get("search")}</Text>
      </View> */}
    </Flex>
  );
}

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    //padding: 20,
  },

  button: {
    backgroundColor: "#D3D3D3",
    width: "45%",
    margin: "3px",
  },
};
