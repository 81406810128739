/* src/History.js */
import {
  View,
  withAuthenticator,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState, useContext } from "react";
import { conversationsByDate } from "./graphql/queries";
import { ConversationLoad } from "./commonfn/ConversationLoad";
import { LanguageContext } from "./commonfn/LanguageContext";

const History = ({ signOut, user }) => {
  const nativeCode = useContext(LanguageContext);
  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState("");

  useEffect(() => {
    if (conversations.length === 0) {
      fetchConversations();
    }
  }, [conversations]);

  async function fetchConversations() {
    try {
      const res = await API.graphql(
        graphqlOperation(conversationsByDate, {
          active: "true",
          limit: 100,
          sortDirection: "DESC",
        })
      );
      console.log("res: " + JSON.stringify(res, null, 2));
      const conversationsTemp = res.data.conversationsByDate.items;
      if (conversationsTemp && conversationsTemp.length > 0) {
        setConversations(conversationsTemp);
      }
    } catch (err) {
      console.log("error fetching conversations", err);
    }
  }

  function formatDate(date) {
    return new Date(date).toLocaleString();
  }

  const handleBack = () => {
    setConversation(null);
    //setConversations([]);
  };

  return (
    <View style={styles.container} width="100%">
      {conversation ? (
        <View>
          <a href="#" onClick={() => {setConversation(null)}}>Back</a><p/>
          <ConversationLoad conversation={conversation} level="private" backCallback={handleBack}/>
        </View>
      ) : (
      <Table>
        <TableBody>
          {conversations.map((conversationTemp) => (
            <TableRow key={conversationTemp.id}>
              {/* <div key={conversationTemp.id ? conversationTemp.id : index}> */}
              <TableCell style={styles.cell}>
                <a href="#" onClick={() => setConversation(conversationTemp)}>
                  {conversationTemp["title_" + nativeCode.replace("-", "")]}
                </a>
              </TableCell>
              <TableCell style={styles.cell}>
                {formatDate(conversationTemp.createdAt)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      )}
    </View>
  );
};

const styles = {
  container: {
    // margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    marginLeft: 20,
  },

  card: {
    backgroundColor: "#FFF8DC",
    borderRadius: 10,
  },

  cell: {
    padding: 0,
    width: "20%",
  },
};

export default withAuthenticator(History);
