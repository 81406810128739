/* src/Transcribe.js */
import React, { useEffect, useState, useRef } from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { withAuthenticator, Button, Heading } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useWhisper } from "@chengsokdara/use-whisper";
import {
  Options,
  RecordRTCPromisesHandler,
  StereoAudioRecorder,
} from "recordrtc";

import { Storage, Predictions } from "aws-amplify";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { name: "", description: "" };

/* src/Transcribe.js */
const Transcribe = ({ signOut, user }) => {
  const [formState, setFormState] = useState(initialState);
  const [todos, setTodos] = useState([]);

  //const [recorder, setRecorder] = useRef({})
  let recorder;

  const clickRecord = async () => {
    const recorderConfig = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1, // mono
      recorderType: StereoAudioRecorder,
      sampleRate: 44100, // Sample rate = 44.1khz
      type: "audio",
    };
    let userMedia = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    recorder = new RecordRTCPromisesHandler(userMedia, recorderConfig);
    recorder.startRecording().then(successCB).catch(errorCB);
    // Note: You can access all RecordRTC API using "recorder.recordRTC" e.g.
    recorder.recordRTC.onStateChanged = function (state) {
      console.log("STATE " + state);
    };

    // setTimeout(async() => {
    //   const blob = await recorder.getBlob()
    //   console.log('BLOB ' + blob)
    // }, 7000);

    // recorder.recordRTC.setRecordingDuration(5000);
  };

  function successCB() {
    console.log("suc");
  }

  function errorCB() {
    console.log("err");
  }

  const clickStop = async () => {
    await recorder.stopRecording();
    let blob = await recorder.getBlob();
    console.log("BLOB " + blob);
    let buf = await blob.arrayBuffer();
    console.log("buf " + buf);
    let text = await blob.text();
    text = text.split('')
    console.log("text " + text);

    Predictions.convert({
      transcription: {
        source: {
          text
        }
        // language: "en-US",
      }
    })
    .then(({ transcription: { fullText } }) => console.log({ fullText }))
    .catch((err) => console.log({ err }));

    //   Predictions.convert({
    //     transcription: {
    //       source: 
    //         blob.arrayBuffer(),
    //       },
    //       language: "en-US",
    //     },
    //   })
    //     .then(({ transcription: { fullText } }) => console.log({ fullText }))
    //     .catch((err) => console.log({ err }));
    // };


    // let fileReader = new FileReader();
    // let arrayBuffer;

    // fileReader.onloadend = () => {
    //   arrayBuffer = fileReader.result;

    //   Predictions.convert({
    //     transcription: {
    //       source: {
    //         arrayBuffer,
    //       },
    //       language: "en-US",
    //     },
    //   })
    //     .then(({ transcription: { fullText } }) => console.log({ fullText }))
    //     .catch((err) => console.log({ err }));
    // };

    // fileReader.readAsArrayBuffer(blob);
  };

  // const apiKey = '';
  // const autoStart = false;
  // const autoTranscribe = true;
  // const mode = 'transcriptions';
  // const nonStop = false;
  // const removeSilence = false;
  // const stopTimeout = 10000;
  // const streaming = false;
  // const timeSlice = 1_000;
  // const onTranscribe = undefined;

  // const recorder = useRef()
  // const stream = useRef()
  // const timeout = useRef(10000)
  // const [recording, setRecording]= useRef(false)

  // /**
  //  * start speech recording and start listen for speaking event
  //  */
  // const startRecording2 = async () => {
  //   await onStartRecording()
  // }

  // /**
  //  * stop speech recording and start the transcription
  //  */
  // const stopRecording2 = async () => {
  //   await onStopRecording()
  // }

  // /**
  //  * start speech recording event
  //  * - first ask user for media stream
  //  * - create recordrtc instance and pass media stream to it
  //  * - create lamejs encoder instance
  //  * - check recorder state and start or resume recorder accordingly
  //  * - start timeout for stop timeout config
  //  * - update recording state to true
  //  */
  // const onStartRecording = async () => {
  //   try {
  //     if (!stream.current) {
  //       await onStartStreaming()
  //     }
  //     if (stream.current) {
  //       if (!recorder.current) {
  //         const {
  //           default: { RecordRTCPromisesHandler, StereoAudioRecorder },
  //         } = await import('recordrtc')
  //         const recorderConfig  = {
  //           mimeType: 'audio/wav',
  //           numberOfAudioChannels: 1, // mono
  //           recorderType: StereoAudioRecorder,
  //           sampleRate: 44100, // Sample rate = 44.1khz
  //           timeSlice: streaming ? timeSlice : undefined,
  //           type: 'audio',
  //           ondataavailable:
  //             autoTranscribe && streaming ? onDataAvailable : undefined,
  //         }
  //         recorder.current = new RecordRTCPromisesHandler(
  //           stream.current,
  //           recorderConfig
  //         )
  //       }
  //       const recordState = await recorder.current.getState()
  //       if (recordState === 'inactive' || recordState === 'stopped') {
  //         await recorder.current.startRecording()
  //       }
  //       if (nonStop) {
  //         onStartTimeout('stop')
  //       }
  //       setRecording(true)
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // /**
  //  * get user media stream event
  //  * - try to stop all previous media streams
  //  * - ask user for media stream with a system popup
  //  * - register hark speaking detection listeners
  //  */
  // const onStartStreaming = async () => {
  //   try {
  //     if (stream.current) {
  //       stream.current.getTracks().forEach((track) => track.stop())
  //     }
  //     stream.current = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //     })
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // /**
  //  * start stop timeout event
  //  */
  // const onStartTimeout = (type) => {
  //   if (!timeout.current[type]) {
  //     timeout.current[type] = setTimeout(onStopRecording, stopTimeout)
  //   }
  // }

  // /**
  //  * stop speech recording event
  //  * - flush out lamejs encoder and set it to undefined
  //  * - if recorder state is recording or paused, stop the recorder
  //  * - stop user media stream
  //  * - clear stop timeout
  //  * - set recording state to false
  //  * - start Whisper transcription event
  //  * - destroy recordrtc instance and clear it from ref
  //  */
  // const onStopRecording = async () => {
  //   try {
  //     if (recorder.current) {
  //       const recordState = await recorder.current.getState()
  //       if (recordState === 'recording' || recordState === 'paused') {
  //         await recorder.current.stopRecording()
  //       }
  //       onStopStreaming()
  //       onStopTimeout('stop')
  //       setRecording(false)
  //       const blob = await recorder.current.getBlob()
  //       console.log('BLOB ' + blob)
  //       await recorder.current.destroy()
  //       recorder.current = undefined
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // /**
  //  * stop media stream event
  //  * - remove hark speaking detection listeners
  //  * - stop all media stream tracks
  //  * - clear media stream from ref
  //  */
  // const onStopStreaming = () => {
  //   if (stream.current) {
  //     stream.current.getTracks().forEach((track) => track.stop())
  //     stream.current = undefined
  //   }
  // }

  // /**
  //  * stop timeout event
  //  * - clear stop timeout and remove it from ref
  //  */
  // const onStopTimeout = (type) => {
  //   if (timeout.current[type]) {
  //     clearTimeout(timeout.current[type])
  //     timeout.current[type] = undefined
  //   }
  // }

  // /**
  //  * Get audio data in chunk based on timeSlice
  //  * - while recording send audio chunk to Whisper
  //  * - chunks are concatenated in succession
  //  * - set transcript text with interim result
  //  */
  // const onDataAvailable = async (data) => {
  //   console.log('onDataAvailable', data)
  //   try {
  //     if (streaming && recorder.current) {
  //       //onDataAvailableCallback?.(data)
  //       const recorderState = await recorder.current.getState()
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  useEffect(() => {
    fetchTodos();
  }, []);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchTodos() {
    // try {
    //   const todoData = await API.graphql(graphqlOperation(listTodos));
    //   const todos = todoData.data.listTodos.items;
    //   setTodos(todos);
    // } catch (err) {
    //   console.log("error fetching todos");
    // }
  }

  async function addTodo() {
    // try {
    //   if (!formState.name || !formState.description) return;
    //   const todo = { ...formState };
    //   setTodos([...todos, todo]);
    //   setFormState(initialState);
    //   await API.graphql(graphqlOperation(createTodo, { input: todo }));
    // } catch (err) {
    //   console.log("error creating todo:", err);
    // }
  }

  // function clickRecord() {
  //   navigator.mediaDevices.getUserMedia({
  //     video: false,
  //     audio: true
  // }).then(async function(stream) {
  //     let recorder = RecordRTC(stream, {
  //         type: 'audio'
  //     });
  //     recorder.startRecording();

  //     const sleep = m => new Promise(r => setTimeout(r, m));
  //     await sleep(3000);

  //     recorder.stopRecording(function() {
  //         let blob = recorder.getBlob();
  //         console.log('blob ' + blob);
  //     });
  // });
  // }
  return (
    <div style={styles.container}>
      <Heading level={1}>Hello {user.username}</Heading>
      <Button onClick={signOut}>Sign out</Button>
      <h2>Amplify Transcribe</h2>

      <button onClick={() => clickRecord()}>Record</button>
      <button onClick={() => clickStop()}>Stop</button>

      {/* <div>
      <p>Recording: {recording}</p>
      <p>Speaking: {speaking}</p>
      <p>Transcripting: {transcripting}</p>
      <p>Transcribed Text: {transcript.text}</p>
      <button onClick={() => startRecording()}>Start</button>
      <button onClick={() => pauseRecording()}>Pause</button>
      <button onClick={() => stopRecording()}>Stop</button>
    </div> */}

      {/* <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name}
        placeholder="Name"
      />
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Description"
      />
      <button style={styles.button} onClick={addTodo}>Create Todo</button>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoName}>{todo.name}</p>
            <p style={styles.todoDescription}>{todo.description}</p>
          </div>
        ))
      } */}
    </div>
  );
};

const styles = {
  container: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  todo: { marginBottom: 15 },
  input: {
    border: "none",
    backgroundColor: "#ddd",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  todoName: { fontSize: 20, fontWeight: "bold" },
  todoDescription: { marginBottom: 0 },
  button: {
    backgroundColor: "black",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  },
};

export default withAuthenticator(Transcribe);
