/* src/Translate.js */
import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Storage, Predictions } from 'aws-amplify';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

const initialState = { name: '', description: '' }

/* src/Translate.js */
const Translate = ({ signOut, user }) => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    // try {
    //   const todoData = await API.graphql(graphqlOperation(listTodos))
    //   const todos = todoData.data.listTodos.items
    //   setTodos(todos)
    // } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    // try {
    //   if (!formState.name || !formState.description) return
    //   const todo = { ...formState }
    //   setTodos([...todos, todo])
    //   setFormState(initialState)
    //   await API.graphql(graphqlOperation(createTodo, {input: todo}))
    // } catch (err) {
    //   console.log('error creating todo:', err)
    // }
  }











  function TextToSpeech() {
    const [response, setResponse] = useState("...")
    const [textToGenerateSpeech, setTextToGenerateSpeech] = useState("write to speech");
  
    function generateTextToSpeech() {
      setResponse('Generating audio...');
      Predictions.convert({
        textToSpeech: {
          source: {
            text: textToGenerateSpeech,
          },
          voiceId: "Amy" // default configured on aws-exports.js 
          // list of different options are here https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
        }
      }).then(result => {
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        console.log({ AudioContext });
        const audioCtx = new AudioContext(); 
        const source = audioCtx.createBufferSource();
        audioCtx.decodeAudioData(result.audioStream, (buffer) => {
  
          source.buffer = buffer;
          source.connect(audioCtx.destination);
          source.start(0);
        }, (err) => console.log({err}));
  
        setResponse(`Generation completed, press play`);
      })
        .catch(err => setResponse(err))
    }
  
    function setText(event) {
      setTextToGenerateSpeech(event.target.value);
    }
  
    return (
      <div className="Text">
        <div>
          <h3>Text To Speech</h3>
          <input value={textToGenerateSpeech} onChange={setText}></input>
          <button onClick={generateTextToSpeech}>Text to Speech</button>
          <h3>{response}</h3>
        </div>
      </div>
    );
  }




  function TextTranslation() {
    const [response, setResponse] = useState("Input some text and click enter to test")
    const [textToTranslate, setTextToTranslate] = useState("write to translate");
  
    function translate() {
      Predictions.convert({
        translateText: {
          source: {
            text: textToTranslate,
            language : "en" // defaults configured on aws-exports.js
            // supported languages https://docs.aws.amazon.com/translate/latest/dg/how-it-works.html#how-it-works-language-codes
          },
          targetLanguage: "fr"
        }
      }).then(result => setResponse(JSON.stringify(result, null, 2)))
        .catch(err => setResponse(JSON.stringify(err, null, 2)))
    }
  
    function setText(event) {
      setTextToTranslate(event.target.value);
    }
  
    return (
      <div className="Text">
        <div>
          <h3>Text Translation</h3>
          <input value={textToTranslate} onChange={setText}></input>
          <button onClick={translate}>Translate</button>
          <p>{response}</p>
        </div>
      </div>
    );
  }
  


  return (
    <div style={styles.container}>
    <Heading level={1}>Hello {user.username}</Heading>
    <Button onClick={signOut}>Sign out</Button>
    <h2>Amplify Translate</h2>
        Translate
      <TextTranslation />
      Speech Generation
      <TextToSpeech />
      <br/>
      <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name}
        placeholder="Name"
      />
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Description"
      />
      <button style={styles.button} onClick={addTodo}>Create Todo</button>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoName}>{todo.name}</p>
            <p style={styles.todoDescription}>{todo.description}</p>
          </div>
        ))
      }
    </div>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withAuthenticator(Translate);