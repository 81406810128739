import { TranslateWithMeta } from "../commonfn/Translate";
import { createCueCard } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { CreateImage } from "./CreateImage";

export async function CreateCueCard(
  nativeLangCode,
  targetLangCode,
  dialectCode,
  targetText
) {
  return new Promise(async (resolve) => {
    const promiseTranslate = TranslateWithMeta(
      targetText,
      targetLangCode,
      nativeLangCode
    );

    try {
      //const imagePrompt = targetText + " in vector artwork style";
      const imagePrompt = "Create an image for a cuecard based on this " + targetText;
      const imageId = uuidv4();
      const imageUrl = "cuecard/" + imageId + ".png";
      const promiseGenerateImage = CreateImage(
        "dall-e-2",
        "standard",
        targetText,
        imageUrl,
        "256x256",
        "private"
      );

      Promise.all([promiseTranslate, promiseGenerateImage]).then(
        async (responses) => {
          let textTranslate = "";
          for (let i = 0; i < responses.length; i++) {
            if (responses[i].text) {
              textTranslate = responses[i].text;
            }
          }

          let result = await API.graphql(
            graphqlOperation(createCueCard, {
              input: {
                nativeLangCode: nativeLangCode,
                nativeText: textTranslate,
                targetLangCode: targetLangCode,
                targetDialectCode: dialectCode,
                targetText: targetText,
                imageUrl: imageUrl,
                active: "true",
              },
            })
          );

          resolve("");
        }
      );
    } catch (err) {
      console.log("CreateCueCard error: " + err);
      let fnResponse = {};
      Promise.all([promiseTranslate]).then(async (responses) => {
        let textTranslate = "";
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].text) {
            textTranslate = responses[i].text;
          }
        }

        let result = await API.graphql(
          graphqlOperation(createCueCard, {
            input: {
              nativeLangCode: nativeLangCode,
              nativeText: textTranslate,
              targetLangCode: targetLangCode,
              targetDialectCode: dialectCode,
              targetText: targetText,
              active: "true",
            },
          })
        );

        resolve("");
      });
    }
  });
}
