import { API, graphqlOperation, I18n } from "aws-amplify";
import { chatFunction, generateImage } from "../graphql/queries";
import { getOpenAIMessages } from "./GetOpenAIMessages";

export async function chatIter(
  chatNativeTranslate,
  chitTargetTranslate,
  systemMessage
) {
  return new Promise(async (resolve) => {
    const aiMessages = getOpenAIMessages(
      chatNativeTranslate,
      chitTargetTranslate,
      systemMessage
    );

    // const promiseTools = API.graphql(
    //   graphqlOperation(chatFunction, {
    //     fn: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: aiMessages,
    //       max_tokens: 50,
    //       tools: [
    //         {
    //           type: "function",
    //           function: {
    //             name: "get_location",
    //             description:
    //               "Where in the world does the user want to have the conversation",
    //             parameters: {
    //               type: "object",
    //               properties: {
    //                 location: {
    //                   type: "string",
    //                 },
    //               },
    //             },
    //             required: ["location"],
    //           },
    //         },
    //         {
    //           type: "function",
    //           function: {
    //             name: "get_business_name",
    //             description: "Get the store name",
    //             parameters: {
    //               type: "object",
    //               properties: {
    //                 businessName: {
    //                   type: "string",
    //                 },
    //               },
    //             },
    //             required: ["businessName"],
    //           },
    //         },
    //         {
    //           type: "function",
    //           function: {
    //             name: "get_business_type",
    //             description:
    //               "Get the type of business the user wants to have the conversation in",
    //             parameters: {
    //               type: "object",
    //               properties: {
    //                 businessType: {
    //                   type: "string",
    //                 },
    //               },
    //             },
    //             required: ["businessType"],
    //           },
    //         },
    //         {
    //           type: "function",
    //           function: {
    //             name: "generate_image",
    //             description:
    //               "Would an image be meaningful at this point in the conversation?",
    //             parameters: {
    //               type: "object",
    //               properties: {
    //                 genImageBool: {
    //                   type: "boolean",
    //                 },
    //               },
    //             },
    //             required: ["genImageBool"],
    //           },
    //         },
    //         {
    //           type: "function",
    //           function: {
    //             name: "generate_dalle_prompt",
    //             //"If this conversation has progressed to a point where an image would be meaningful, generate a DALLE prompt",
    //             description:
    //               "Generate a prompt for DALLE to generate an image from the most recent statement in conversation.",
    //             parameters: {
    //               type: "object",
    //               properties: {
    //                 genImagePrompt: {
    //                   type: "string",
    //                 },
    //               },
    //             },
    //             required: ["genImagePrompt"],
    //           },
    //         },
    //         {
    //           type: "function",
    //           function: {
    //             name: "get_art_style",
    //             description:
    //               "What artistic style would the user like to have his generated images in?",
    //             parameters: {
    //               type: "object",
    //               properties: {
    //                 artStyle: {
    //                   type: "string",
    //                 },
    //               },
    //             },
    //             required: ["artStyle"],
    //           },
    //         },
    //       ],
    //     }),
    //   })
    // );
    // Promise.all([promiseTools]).then(async (responses) => {
    //   for (let i = 0; i < responses.length; i++) {
    //     console.log(
    //       "TOOLS responses[" + i + "]: " + JSON.stringify(responses[i].data)
    //     );
    //   }
    // });

    const promiseLocation = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 50,
          function_call: { name: "get_location" },
          functions: [
            {
              name: "get_location",
              description:
                "Where in the world does the user want to have the conversation",
              parameters: {
                type: "object",
                properties: {
                  location: {
                    type: "string",
                  },
                },
              },
              required: ["location"],
            },
          ],
        }),
      })
    );
    const promiseExampleLocation = API.graphql(
      graphqlOperation(chatFunction, {
        fn: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: aiMessages,
          max_tokens: 50,
          function_call: {
            name: "generate_store_name",
          },
          functions: [
            {
              name: "generate_store_name",
              description:
                "Generate an example store name that can be searched in google maps",
              parameters: {
                type: "object",
                properties: {
                  exampleLocation: {
                    type: "string",
                  },
                },
              },
              required: ["exampleLocation"],
            },
          ],
        }),
      })
    );
    // const promiseBusinessName = API.graphql(
    //   graphqlOperation(chatFunction, {
    //     fn: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: aiMessages,
    //       max_tokens: 50,
    //       function_call: { name: "get_business_name" },
    //       functions: [
    //         {
    //           name: "get_business_name",
    //           description: "Get the store name",
    //           parameters: {
    //             type: "object",
    //             properties: {
    //               businessName: {
    //                 type: "string",
    //               },
    //             },
    //           },
    //           required: ["businessName"],
    //         },
    //       ],
    //     }),
    //   })
    // );
    // const promiseBusinessType = API.graphql(
    //   graphqlOperation(chatFunction, {
    //     fn: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: aiMessages,
    //       max_tokens: 50,
    //       function_call: { name: "get_business_type" },
    //       functions: [
    //         {
    //           name: "get_business_type",
    //           description:
    //             "Get the type of business the user wants to have the conversation in",
    //           parameters: {
    //             type: "object",
    //             properties: {
    //               businessType: {
    //                 type: "string",
    //               },
    //             },
    //           },
    //           required: ["businessType"],
    //         },
    //       ],
    //     }),
    //   })
    // );
    // const promiseGenerateImage = API.graphql(
    //   graphqlOperation(chatFunction, {
    //     fn: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: aiMessages,
    //       max_tokens: 20,
    //       function_call: { name: "generate_image" },
    //       functions: [
    //         {
    //           name: "generate_image",
    //           description:
    //             "Would an image be meaningful at this point in the conversation?",
    //           parameters: {
    //             type: "object",
    //             properties: {
    //               genImageBool: {
    //                 type: "boolean",
    //               },
    //             },
    //           },
    //           required: ["genImageBool"],
    //         },
    //       ],
    //     }),
    //   })
    // );
    // const promiseGenerateImagePrompt = API.graphql(
    //   graphqlOperation(chatFunction, {
    //     fn: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: aiMessages,
    //       max_tokens: 100,
    //       function_call: { name: "generate_dalle_prompt" },
    //       functions: [
    //         {
    //           name: "generate_dalle_prompt",
    //           //"If this conversation has progressed to a point where an image would be meaningful, generate a DALLE prompt",
    //           description:
    //             "Generate a prompt for DALLE to generate an image from the most recent statement in conversation.",
    //           parameters: {
    //             type: "object",
    //             properties: {
    //               genImagePrompt: {
    //                 type: "string",
    //               },
    //             },
    //           },
    //           required: ["genImagePrompt"],
    //         },
    //       ],
    //     }),
    //   })
    // );
    // const promiseArtStyle = API.graphql(
    //   graphqlOperation(chatFunction, {
    //     fn: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: aiMessages,
    //       max_tokens: 40,
    //       function_call: { name: "get_art_style" },
    //       functions: [
    //         {
    //           name: "get_art_style",
    //           description:
    //             "What artistic style would the user like to have his generated images in?",
    //           parameters: {
    //             type: "object",
    //             properties: {
    //               artStyle: {
    //                 type: "string",
    //               },
    //             },
    //           },
    //           required: ["artStyle"],
    //         },
    //       ],
    //     }),
    //   })
    // );
    let fnResponse = {};
    Promise.all([
      promiseLocation,
      promiseExampleLocation,
      //promiseBusinessName,
      //promiseBusinessType,
      //promiseGenerateImage,
      //promiseGenerateImagePrompt,
      //promiseArtStyle,
    ]).then(async (responses) => {
      try {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].data.chatFunction) {
            try {
              const chatFunction = JSON.parse(responses[i].data.chatFunction);
              const key = Object.keys(chatFunction)[0];
              fnResponse[key] = chatFunction[key];
            } catch (err) {
              console.log(err);
              console.log(responses[i].data.chatFunction);
            }
          }
        }
      } catch (error) {
        alert(I18n.get("limit_reached") + "chatFn");
        return;
      }
      resolve(fnResponse);
      // if (!fnResponse) {
      //   //  if (fnResponse.genImageBool) {
      //   console.log("genImagePrompt: " + fnResponse.genImagePrompt);
      //   const genImage = await API.graphql(
      //     graphqlOperation(generateImage, {
      //       prompt: JSON.stringify({
      //         prompt: fnResponse.genImagePrompt + " in vector artwork style",
      //         n: 1,
      //         model: "dall-e-3",
      //         size: "1792x1024",
      //         //size: "1024x1024",
      //         response_format: "b64_json",
      //       }),
      //     })
      //   );
      //   fnResponse.genImageUrl = genImage.data.generateImage;
      //   resolve(fnResponse);
      // } else {
      //   resolve(fnResponse);
      // }
    });
  });
}
