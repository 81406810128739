/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const speechToText = /* GraphQL */ `
  mutation SpeechToText(
    $language: String
    $soundFile: String
    $identityId: String
  ) {
    speechToText(
      language: $language
      soundFile: $soundFile
      identityId: $identityId
    )
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      conversationId
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      conversationId
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      conversationId
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPublicConversation = /* GraphQL */ `
  mutation CreatePublicConversation(
    $input: CreatePublicConversationInput!
    $condition: ModelPublicConversationConditionInput
  ) {
    createPublicConversation(input: $input, condition: $condition) {
      id
      conversationId
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePublicConversation = /* GraphQL */ `
  mutation UpdatePublicConversation(
    $input: UpdatePublicConversationInput!
    $condition: ModelPublicConversationConditionInput
  ) {
    updatePublicConversation(input: $input, condition: $condition) {
      id
      conversationId
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePublicConversation = /* GraphQL */ `
  mutation DeletePublicConversation(
    $input: DeletePublicConversationInput!
    $condition: ModelPublicConversationConditionInput
  ) {
    deletePublicConversation(input: $input, condition: $condition) {
      id
      conversationId
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      nativeLangCode
      nativeDialectCode
      targetLangCode
      targetDialectCode
      title_en
      title_fr
      title_frCA
      title_es
      title_esMX
      title_de
      title_nl
      title_it
      title_pt
      title_ptPT
      title_ja
      title_ko
      title_zh
      title_zhTW
      title_ru
      title_ar
      title_hi
      title_bn
      title_da
      title_is
      title_nb
      title_pl
      title_ro
      title_sv
      title_tr
      title_cy
      imageUrl
      placeLat
      placeLng
      placeName
      placeAddress
      placeCity
      placeState
      placeCountry
      placePostalCode
      placePhone
      placeTypes
      placeUTCOffset
      placeWebsite
      placeId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePublicLike = /* GraphQL */ `
  mutation UpdatePublicLike(
    $input: UpdatePublicLikeInput!
    $condition: ModelPublicLikeConditionInput
  ) {
    updatePublicLike(input: $input, condition: $condition) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      publicConversationLikesId
      publicPersonaLikesId
      owner
      __typename
    }
  }
`;
export const deletePublicLike = /* GraphQL */ `
  mutation DeletePublicLike(
    $input: DeletePublicLikeInput!
    $condition: ModelPublicLikeConditionInput
  ) {
    deletePublicLike(input: $input, condition: $condition) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      publicConversationLikesId
      publicPersonaLikesId
      owner
      __typename
    }
  }
`;
export const deletePublicComment = /* GraphQL */ `
  mutation DeletePublicComment(
    $input: DeletePublicCommentInput!
    $condition: ModelPublicCommentConditionInput
  ) {
    deletePublicComment(input: $input, condition: $condition) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nativeLangCode
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      publicConversationCommentsId
      publicPersonaCommentsId
      owner
      __typename
    }
  }
`;
export const createFlag = /* GraphQL */ `
  mutation CreateFlag(
    $input: CreateFlagInput!
    $condition: ModelFlagConditionInput
  ) {
    createFlag(input: $input, condition: $condition) {
      id
      publicConversationId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFlag = /* GraphQL */ `
  mutation UpdateFlag(
    $input: UpdateFlagInput!
    $condition: ModelFlagConditionInput
  ) {
    updateFlag(input: $input, condition: $condition) {
      id
      publicConversationId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFlag = /* GraphQL */ `
  mutation DeleteFlag(
    $input: DeleteFlagInput!
    $condition: ModelFlagConditionInput
  ) {
    deleteFlag(input: $input, condition: $condition) {
      id
      publicConversationId
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPublicPersona = /* GraphQL */ `
  mutation CreatePublicPersona(
    $input: CreatePublicPersonaInput!
    $condition: ModelPublicPersonaConditionInput
  ) {
    createPublicPersona(input: $input, condition: $condition) {
      id
      ownerId
      screenName
      nativeLangCode
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePublicPersona = /* GraphQL */ `
  mutation UpdatePublicPersona(
    $input: UpdatePublicPersonaInput!
    $condition: ModelPublicPersonaConditionInput
  ) {
    updatePublicPersona(input: $input, condition: $condition) {
      id
      ownerId
      screenName
      nativeLangCode
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePublicPersona = /* GraphQL */ `
  mutation DeletePublicPersona(
    $input: DeletePublicPersonaInput!
    $condition: ModelPublicPersonaConditionInput
  ) {
    deletePublicPersona(input: $input, condition: $condition) {
      id
      ownerId
      screenName
      nativeLangCode
      likes {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          active
          createdAt
          updatedAt
          publicConversationLikesId
          publicPersonaLikesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          conversation {
            id
            conversationId
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            nativeLangCode
            nativeDialectCode
            targetLangCode
            targetDialectCode
            title_en
            title_fr
            title_frCA
            title_es
            title_esMX
            title_de
            title_nl
            title_it
            title_pt
            title_ptPT
            title_ja
            title_ko
            title_zh
            title_zhTW
            title_ru
            title_ar
            title_hi
            title_bn
            title_da
            title_is
            title_nb
            title_pl
            title_ro
            title_sv
            title_tr
            title_cy
            imageUrl
            placeLat
            placeLng
            placeName
            placeAddress
            placeCity
            placeState
            placeCountry
            placePostalCode
            placePhone
            placeTypes
            placeUTCOffset
            placeWebsite
            placeId
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          persona {
            id
            ownerId
            screenName
            nativeLangCode
            likes {
              nextToken
              __typename
            }
            comments {
              nextToken
              __typename
            }
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            owner
            __typename
          }
          nativeLangCode
          message_en
          message_fr
          message_frCA
          message_es
          message_esMX
          message_de
          message_nl
          message_it
          message_pt
          message_ptPT
          message_ja
          message_ko
          message_zh
          message_zhTW
          message_ru
          message_ar
          message_hi
          message_bn
          message_da
          message_is
          message_nb
          message_pl
          message_ro
          message_sv
          message_tr
          message_cy
          active
          createdAt
          updatedAt
          publicConversationCommentsId
          publicPersonaCommentsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      nativeLangCode
      targetLangCode
      showNative
      showTarget
      location
      imageStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      nativeLangCode
      targetLangCode
      showNative
      showTarget
      location
      imageStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      nativeLangCode
      targetLangCode
      showNative
      showTarget
      location
      imageStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCueCard = /* GraphQL */ `
  mutation CreateCueCard(
    $input: CreateCueCardInput!
    $condition: ModelCueCardConditionInput
  ) {
    createCueCard(input: $input, condition: $condition) {
      id
      nativeLangCode
      nativeText
      targetLangCode
      targetDialectCode
      targetText
      imageUrl
      active
      bookmark
      correctTimestamps
      incorrectTimestamps
      flippedCorrectTimestamps
      flippedIncorrectTimestamps
      score
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCueCard = /* GraphQL */ `
  mutation UpdateCueCard(
    $input: UpdateCueCardInput!
    $condition: ModelCueCardConditionInput
  ) {
    updateCueCard(input: $input, condition: $condition) {
      id
      nativeLangCode
      nativeText
      targetLangCode
      targetDialectCode
      targetText
      imageUrl
      active
      bookmark
      correctTimestamps
      incorrectTimestamps
      flippedCorrectTimestamps
      flippedIncorrectTimestamps
      score
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCueCard = /* GraphQL */ `
  mutation DeleteCueCard(
    $input: DeleteCueCardInput!
    $condition: ModelCueCardConditionInput
  ) {
    deleteCueCard(input: $input, condition: $condition) {
      id
      nativeLangCode
      nativeText
      targetLangCode
      targetDialectCode
      targetText
      imageUrl
      active
      bookmark
      correctTimestamps
      incorrectTimestamps
      flippedCorrectTimestamps
      flippedIncorrectTimestamps
      score
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPublicLike = /* GraphQL */ `
  mutation CreatePublicLike(
    $input: CreatePublicLikeInput!
    $condition: ModelPublicLikeConditionInput
  ) {
    createPublicLike(input: $input, condition: $condition) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      active
      createdAt
      updatedAt
      publicConversationLikesId
      publicPersonaLikesId
      owner
      __typename
    }
  }
`;
export const createPublicComment = /* GraphQL */ `
  mutation CreatePublicComment(
    $input: CreatePublicCommentInput!
    $condition: ModelPublicCommentConditionInput
  ) {
    createPublicComment(input: $input, condition: $condition) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nativeLangCode
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      publicConversationCommentsId
      publicPersonaCommentsId
      owner
      __typename
    }
  }
`;
export const updatePublicComment = /* GraphQL */ `
  mutation UpdatePublicComment(
    $input: UpdatePublicCommentInput!
    $condition: ModelPublicCommentConditionInput
  ) {
    updatePublicComment(input: $input, condition: $condition) {
      id
      conversation {
        id
        conversationId
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        nativeLangCode
        nativeDialectCode
        targetLangCode
        targetDialectCode
        title_en
        title_fr
        title_frCA
        title_es
        title_esMX
        title_de
        title_nl
        title_it
        title_pt
        title_ptPT
        title_ja
        title_ko
        title_zh
        title_zhTW
        title_ru
        title_ar
        title_hi
        title_bn
        title_da
        title_is
        title_nb
        title_pl
        title_ro
        title_sv
        title_tr
        title_cy
        imageUrl
        placeLat
        placeLng
        placeName
        placeAddress
        placeCity
        placeState
        placeCountry
        placePostalCode
        placePhone
        placeTypes
        placeUTCOffset
        placeWebsite
        placeId
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      persona {
        id
        ownerId
        screenName
        nativeLangCode
        likes {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            active
            createdAt
            updatedAt
            publicConversationLikesId
            publicPersonaLikesId
            owner
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            conversation {
              id
              conversationId
              nativeLangCode
              nativeDialectCode
              targetLangCode
              targetDialectCode
              title_en
              title_fr
              title_frCA
              title_es
              title_esMX
              title_de
              title_nl
              title_it
              title_pt
              title_ptPT
              title_ja
              title_ko
              title_zh
              title_zhTW
              title_ru
              title_ar
              title_hi
              title_bn
              title_da
              title_is
              title_nb
              title_pl
              title_ro
              title_sv
              title_tr
              title_cy
              imageUrl
              placeLat
              placeLng
              placeName
              placeAddress
              placeCity
              placeState
              placeCountry
              placePostalCode
              placePhone
              placeTypes
              placeUTCOffset
              placeWebsite
              placeId
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            persona {
              id
              ownerId
              screenName
              nativeLangCode
              message_en
              message_fr
              message_frCA
              message_es
              message_esMX
              message_de
              message_nl
              message_it
              message_pt
              message_ptPT
              message_ja
              message_ko
              message_zh
              message_zhTW
              message_ru
              message_ar
              message_hi
              message_bn
              message_da
              message_is
              message_nb
              message_pl
              message_ro
              message_sv
              message_tr
              message_cy
              active
              createdAt
              updatedAt
              owner
              __typename
            }
            nativeLangCode
            message_en
            message_fr
            message_frCA
            message_es
            message_esMX
            message_de
            message_nl
            message_it
            message_pt
            message_ptPT
            message_ja
            message_ko
            message_zh
            message_zhTW
            message_ru
            message_ar
            message_hi
            message_bn
            message_da
            message_is
            message_nb
            message_pl
            message_ro
            message_sv
            message_tr
            message_cy
            active
            createdAt
            updatedAt
            publicConversationCommentsId
            publicPersonaCommentsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        message_en
        message_fr
        message_frCA
        message_es
        message_esMX
        message_de
        message_nl
        message_it
        message_pt
        message_ptPT
        message_ja
        message_ko
        message_zh
        message_zhTW
        message_ru
        message_ar
        message_hi
        message_bn
        message_da
        message_is
        message_nb
        message_pl
        message_ro
        message_sv
        message_tr
        message_cy
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nativeLangCode
      message_en
      message_fr
      message_frCA
      message_es
      message_esMX
      message_de
      message_nl
      message_it
      message_pt
      message_ptPT
      message_ja
      message_ko
      message_zh
      message_zhTW
      message_ru
      message_ar
      message_hi
      message_bn
      message_da
      message_is
      message_nb
      message_pl
      message_ro
      message_sv
      message_tr
      message_cy
      active
      createdAt
      updatedAt
      publicConversationCommentsId
      publicPersonaCommentsId
      owner
      __typename
    }
  }
`;
